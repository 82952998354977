import React, { useContext, useRef, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { PROFILE } from "../../constants/RoutesUrl";
import { Link, useNavigate } from "react-router-dom";
import { FaCalendarAlt, FaCopy, FaShareAlt } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";
import { BaseURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Language } from "../../App";

const Details = (props) => {
  const { lang } = useContext(Language);
  const { data, user_id, load } = props;
  const [shareModal, setShareModal] = useState(false);

  const navigate = useNavigate();

  return (
    <Row>
      <Col className="my-2">
        {load ? (
          <div className="mt-3 text-center">
            <Spinner />
          </div>
        ) : (
          data.user && (
            <div
              className="p-3 br-14 mt-4"
              style={{ backgroundColor: "rgba(0, 198, 224,0.08)" }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <Link
                    to={PROFILE + "/" + data?.user?.id}
                    className="bg-white dropdown-avatar-shadow align-self-center rounded"
                    style={{
                      maxWidth: "3rem",
                      maxHeight: "3rem",
                      padding: "1.8px",
                    }}
                  >
                    <img
                      src={
                        data?.user.image ? BaseURL + data.user.image : Avatar
                      }
                      alt="picture"
                      className="img-fluid rounded"
                    />
                  </Link>
                  <div className="align-self-center">
                    <Link
                      to={PROFILE + "/" + data?.user.id}
                      className="fs-14 roboto-bold text-dark"
                    >
                      {data?.user.name}
                    </Link>
                    <p className="mb-0 fs-12 roboto-regular">
                      @{data?.user.user_name}
                    </p>
                  </div>
                </div>
                {user_id === data.user.id && (
                  <div
                    className="mt-2 text-center cursor"
                    onClick={() => {
                      setShareModal(true);
                    }}
                  >
                    <FaShareAlt size={23} />
                    <div>
                      <small>{lang.share}</small>
                    </div>
                  </div>
                )}
              </div>

              <hr style={{ opacity: "0.1" }} />

              <div>
                <h5 className="fs-20 fs-md-16 roboto-bold">
                  {
                  // data?.title.length > 25
                    // ? `${data?.title.slice(0, 25)}...`
                    // : 
                    data?.title}
                </h5>
                <p className="fs-14 roboto-regular">
                  {data?.caption} 
                </p>
                <p className="fs-14 roboto-regular">
                  {data?.visibility} - {data?.category}
                </p>

                <div className="d-flex gap-3">
                  <div>
                    <p className="mb-0 text-secondary fs-14 roboto-medium">
                      <FaCalendarAlt className="mb-1" />{" "}
                      <UnixToDate timeStamp={data?.start_timestamp} />
                    </p>
                  </div>
                  {/* {data?.schedule_time && (
                    <div>
                      <p className="mb-0 text-secondary fs-14 roboto-medium">
                        <AiFillClockCircle className="mb-1" />{" "}
                        {data?.schedule_time}
                      </p>
                    </div>
                  )} */}
                </div>
              </div>

              {data.status === 1 && (
                <div className="text-center mt-3">
                  <button
                    className="btn-blue w-50 border-0 rounded py-2"
                    onClick={() => {
                      if (user_id === data.user.id) {
                        window.open(data.meeting_host_url, "_blank");
                      } else {
                        window.open(data.meeting_url, "_blank");
                      }
                    }}
                  >
                    {user_id === data.user.id
                      ? lang?.start_session
                      : lang?.join_session}
                  </button>
                </div>
              )}

              <Modal isOpen={shareModal} centered>
                <ModalHeader
                  toggle={() => {
                    setShareModal(false);
                  }}
                >
                  {lang.share_clinical_session}
                </ModalHeader>
                <ModalBody>
                  <div className="d-flex gap-2">
                    <h6 className="align-self-center mb-0">
                      {lang.share_host_link}:
                    </h6>
                    <CopyText text={data.meeting_host_url} />
                  </div>
                  <div className="d-flex gap-2">
                    <h6 className="align-self-center mb-0">
                      {lang.share_joining_link}:
                    </h6>
                    <CopyText text={data.meeting_url} />
                  </div>
                </ModalBody>
              </Modal>
            </div>
          )
        )}
      </Col>
    </Row>
  );
};

export default Details;

const UnixToDate = ({ timeStamp }) => {
   const date = new Date(timeStamp * 1000);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }

        // Format the date using options for day, month, year, time, etc.
        return new Intl.DateTimeFormat('en-US', {
            weekday: 'short',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).format(date);
  // const unixTimestamp = timeStamp * 1000;
  // const dateObject = new Date(unixTimestamp);

  // // Define options for formatting the date
  // const options = {
  //   weekday: "short",
  //   day: "2-digit",
  //   month: "short",
  //   year: "numeric",
  // };

  // // Convert the date to the desired format
  // const formattedDateString = dateObject.toLocaleString("en-US", options);

  // return formattedDateString;
};

const CopyText = ({ text }) => {
  const textRef = useRef(null);

  const copyToClipboard = () => {
    textRef.current.select();
    document.execCommand("copy");
  };

  return (
    <div className="d-flex gap-2 my-3">
      <input
        type="text"
        value={text}
        ref={textRef}
        readOnly
        className="form-control"
      />
      <button onClick={copyToClipboard} className="bg-transparent border-0">
        <FaCopy />
      </button>
    </div>
  );
};
