import { Link, useNavigate } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { HiDotsHorizontal } from "react-icons/hi";
import { useContext, useState } from "react";
import { selectedChatContext } from "../../pages/chat/ChatModule";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { BaseURL } from "../../constants/API";
import GroupInfoModal from "./GroupInfoModal";

export default function ChatHeader() {
  const navigate = useNavigate();
  const { selectedChat } = useContext(selectedChatContext);

  const [groupInfoModal, setGroupInfoModal] = useState(false);

  const handleProfileNavigation = () => {
    if (selectedChat?.group_id === 0) {
      navigate(PROFILE + "/" + selectedChat?.user.id);
    }
  };
  return (
    <div className="p-2 border-bottom d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-white rounded-circle align-self-center"
          style={{
            padding: "2px",
            width: "56px",
            height: "56px",
            border: "1px solid rgba(29, 193, 221, 1)",
          }}
        >
          <ImageComponent
            src={
              selectedChat?.group_id !== 0 && selectedChat?.image !== ""
                ? BaseURL + selectedChat?.image
                : selectedChat?.group_id !== 0 && selectedChat?.image === ""
                ? Avatar
                : selectedChat.user.image !== ""
                ? BaseURL + selectedChat?.user.image
                : Avatar
            }
            img_height="100%"
            img_width="100%"
            loader_height="100%"
            loader_width="100%"
            compClassName="rounded-circle"
            roundShimmerSize={49}
          />
        </div>
        <div className="ms-3">
          <h5
            className="mb-0 roboto-bold fs-16"
            onClick={handleProfileNavigation}
          >
            {selectedChat?.group_id !== 0
              ? selectedChat?.group_name
              : selectedChat?.user.name}
          </h5>
        </div>
      </div>
      {selectedChat?.group_id !== 0 && (
        <div className="me-3 cursor">
          <HiDotsHorizontal onClick={() => setGroupInfoModal(true)} size={20} />
          <GroupInfoModal
            isOpen={groupInfoModal}
            setIsOpen={setGroupInfoModal}
          />
        </div>
      )}
    </div>
  );
}
