import { MdOutlinePeopleAlt } from "react-icons/md";
import ImageComponent from "../common/ImageComponent";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import { useContext, useEffect, useRef, useState } from "react";
import { LoginUserData } from "../../App";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { PROFILE } from "../../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../constants/API";

export default function ProfileSuggestionSlider() {
  const navigate = useNavigate();

  const { splashData } = useContext(LoginUserData);

  const [suggestionsData, setSuggestionsData] = useState([]);

  useEffect(() => {
    if (splashData?.user && splashData.home) {
      setSuggestionsData(splashData.home.suggestion);
    }
  }, [splashData]);

  const carouselRef = useRef(null);

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.decrement();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.increment();
    }
  };

  const chunkArray = (array, size) => {
    const remainder = array.length % size;
    if (remainder > 0) {
      array = array.slice(0, array.length - remainder);
    }
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  return (
    <div className="p-3 bg-white rounded border border-inherit">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-2">
          <MdOutlinePeopleAlt size={20} />
          <h6 className="mb-0 roboto-medium fs-14">Suggested Users</h6>
        </div>
        <div>
          <p className="mb-0 roboto-regular text-secondary fs-14">View More</p>
        </div>
      </div>
      <div className="d-flex gap-2 justify-content-between p-3">
        <button
          className="rounded bg-white p-0 border-0 align-self-center"
          style={{
            width: "20px",
            boxShadow: "0 2px 2px rgba(0, 0, 0, 0.15)",
          }}
          onClick={handlePrev}
        >
          <IoIosArrowBack size={18} />
        </button>
        <div style={{ minWidth: "462px" }}>
          <Carousel
            ref={carouselRef}
            autoPlay={false}
            infiniteLoop={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            {chunkArray(suggestionsData, 3).map((chunk, index) => (
              <div key={index} className="d-flex justify-content-between">
                {chunk.map((profile) => (
                  <div
                    key={profile.id}
                    className="border rounded p-2"
                    style={{ backgroundColor: "#E2E8ED", width: "144px" }}
                  >
                    <div
                      className="bg-white rounded-circle align-self-center mx-auto"
                      style={{
                        padding: "2px",
                        width: "84px",
                        height: "84px",
                        border: "1px solid rgba(29, 193, 221, 1)",
                      }}
                    >
                      <ImageComponent
                        src={
                          profile.image === ""
                            ? Avatar
                            : BaseURL + profile.image
                        }
                        img_height="100%"
                        img_width="100%"
                        loader_height="100%"
                        loader_width="100%"
                        compClassName="rounded-circle"
                        roundShimmerSize={77}
                      />
                    </div>
                    <div className="p-2 text-center">
                      <h6 className="roboto-medium fs-14">
                        {profile.name && profile.name.length > 12
                          ? profile.name.substring(0, 12) + "..."
                          : profile.name}
                      </h6>
                      <p className="roboto-regular text-secondary fs-14 mb-0">
                        {profile.user_name && profile.user_name.length > 12
                          ? profile.user_name.substring(0, 12) + "..."
                          : profile.user_name}
                      </p>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn-blue border-0 rounded px-3 py-1 roboto-medium"
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          navigate(PROFILE);
                        }}
                      >
                        View Profile
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
        <button
          className="rounded bg-white p-0 border-0 align-self-center"
          style={{
            width: "20px",
            boxShadow: "0 2px 2px rgba(0, 0, 0, 0.15)",
          }}
          onClick={handleNext}
        >
          <IoIosArrowForward size={18} />
        </button>
      </div>
    </div>
  );
}
