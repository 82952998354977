import { createContext, useContext, useEffect, useState } from "react";
import ChatUserCard from "./ChatUserCard";
import { Language, LoginUserData } from "../../App";
import axios from "axios";
import { toast } from "react-toastify";
import { BiMessageAdd } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import CreateGroupModal from "./CreateGroupModal";
import { ChatsContext } from "./ChatsProvider";

// export const ChatsContext = createContext();

export default function ChatsSideBar() {
  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);
  // const [chats, setChats] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const { chats, fetchChats, loading ,updateChatMessage} = useContext(ChatsContext); 

  console.log("chat first",chats[0])
  // const fetchChats = async () => {
  //   setLoading(true);
  //   const response = await axios.get(
  //     "https://medicalradar.es/api/conversation/" + splashData.user.id
  //   );
  //   if (response.data.status === true) {
  //     setChats(response.data.data);
  //   } else {
  //     toast.error(response.data.message);
  //   }
  //   setLoading(false);
  // };
  // const updateChatMessage = (newMessage, chatId) => {
  //   setChats((prevChats) => 
  //     prevChats.map((chat) => 
  //       chat.id === chatId ? { ...chat, message: newMessage } : chat
  //     )
  //   );
  // };
  // useEffect(() => {
  //   fetchChats();
  // }, []);

  return (
    <ChatsContext.Provider value={{ fetchChats}}>
    <>
      <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        toggle={() => setIsCreateGroupModalOpen(!isCreateGroupModalOpen)}
      />
      <div className="border border-inherit vh-100 overflow-y-auto scroll-hide">
        <div
          className="p-3 border-bottom border-inherit d-flex align-items-center justify-content-between bg-white"
          style={{ zIndex: "2" }}
        >
          <h4 className="fs-20 roboto-bold mb-0"> {lang?.all_messages}</h4>
          <div className="d-flex align-items-center gap-3">
            <CiSearch size={25} className="cursor" title="New chat" />
            <BiMessageAdd
              size={25}
              onClick={() => setIsCreateGroupModalOpen(true)}
              color="#1Dc1dd"
              className="cursor"
              title="Add new group chat"
            />
          </div>
        </div>
        <div style={{ height: "100%" }}>
          {loading ? (
            <div className="p-3">
              <ShimmerCategoryItem
                hasImage
                imageType="circular"
                imageWidth={56}
                imageHeight={56}
                title
              />
            </div>
          ) : (
            chats.map((chat, index) => <ChatUserCard key={index} chat={chat} />)
          )}
        </div>
      </div>
      </>
     </ChatsContext.Provider>
  );
}
