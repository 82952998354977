import { useState, useContext, useCallback, useEffect, useRef } from "react";
import { Col, Row } from "reactstrap";
import { Language, FeedType } from "../../App";

const TypesData = [
  { text: "see_all", type: "all" },
  { text: "posts", type: "posts" },
  { text: "poll", type: "poll" },
  { text: "webinars", type: "webinar" },
  { text: "clinical_studies", type: "clinical-image" },
];

export default function FeedPostFilter() {
  const { lang } = useContext(Language);
  const { updateFeedType } = useContext(FeedType);
  const [type, setType] = useState(
    () => sessionStorage.getItem("FeedType") || "all"
  );
  const [isFilterBarVisible, setIsFilterBarVisible] = useState(false);
  const filterBarRef = useRef(null);

  const handleType = useCallback((selectedType) => {
    sessionStorage.removeItem("prevFeedData");
    sessionStorage.setItem("FeedType", selectedType);
    setType(selectedType);
    updateFeedType(selectedType);
    window.scrollTo({ top: 609, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (filterBarRef.current) {
        const rect = filterBarRef.current.getBoundingClientRect();
        const threshold = window.innerHeight * 0.658;

        if (!isFilterBarVisible && rect.top <= threshold) {
          setIsFilterBarVisible(true);
        }
        if (window.scrollY <= threshold) {
          setIsFilterBarVisible(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={filterBarRef}
      className={`bg-white rounded p-3 border border-inherit ${
        isFilterBarVisible ? "position-fixed" : "position-sticky"
      }`}
      style={{
        width: "100%",
        top: "3.2rem",
        zIndex: "1",
        left: "0",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <Row className="justify-content-center align-items-center">
        {TypesData.map(({ text, type: itemType }) => (
          <Col
            style={{ maxWidth: isFilterBarVisible ? "6.7%" : "25%" }}
            key={itemType}
            className={`position-relative text-center cursor ${
              itemType === type ? "filter-active" : ""
            }`}
            onClick={() => handleType(itemType)}
          >
            <p className="mb-0 roboto-regular text-nowrap">{lang?.[text]}</p>
            {itemType === type && (
              <div
                className="position-absolute start-0 end-0 bottom-0 rounded-pill"
                style={{
                  marginBottom: "-16px",
                  backgroundColor: "#1DC1DD",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
}
