import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { BaseURL } from "../../constants/API";
import { PROFILE } from "../../constants/RoutesUrl";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";
import { ShimmerTitle } from "react-shimmer-effects";
import { BsCalendar4Week } from "react-icons/bs";

const ProfileInfoCard = ({ profileInfo }) => {
  const { lang } = useContext(Language);

  return (
    <div
      className="d-xl-block d-none my-3"
      style={{ maxWidth: "335px", width: "100%" }}
    >
      <div
        style={{ border: "0.5px solid rgba(0,0,0,0.25)", borderRadius: "8px" }}
        className="bg-white position-relative"
      >
        <div
          style={{
            background: "#031316",
            minHeight: "89px",
            borderRadius: "8px 8px 0px 0px",
          }}
        ></div>
        <div
          className="bg-white position-absolute top-0 rounded-circle"
          style={{
            border: "1px solid #1DC1DD",
            padding: "2px",
            marginLeft: "7.2rem",
            marginTop: "2rem",
            maxHeight: "100px",
            maxWidth: "100px",
          }}
        >
          <Link to={`${PROFILE}/${profileInfo.id}`}>
            <ImageComponent
              src={
                profileInfo.image ? `${BaseURL}${profileInfo.image}` : Avatar
              }
              loader_height="92px"
              loader_width="92px"
              roundShimmerSize={92}
              compClassName="rounded-circle"
              img_width={92}
              img_height={92}
            />
          </Link>
        </div>
        <div className="text-center" style={{ marginTop: "3.2rem" }}>
          <Link
            to={`${PROFILE}/${profileInfo.id}`}
            className="fs-16 roboto-bold hover-blue"
          >
            {profileInfo.name || (
              <ShimmerTitle
                line={2}
                gap={10}
                variant="secondary"
                className="w-50 mx-auto shimmer-center"
              />
            )}
          </Link>
          <div>
            <p className="fs-12 roboto-medium" style={{ color: "#8A93A1" }}>
              {profileInfo?.location}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div
              className="bg-white rounded px-2"
              style={{ boxShadow: "0px 1px 5px rgba(0,0,0,0.05)" }}
            >
              <BsCalendar4Week size={14} />
            </div>
            <div>
              <p
                className="mb-0 fs-12 roboto-regular"
                style={{ color: "#8A93A1" }}
              >
                Joined {profileInfo?.created_at}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4 my-4">
            <div className="d-flex flex-column align-items-center">
              <h5 className="fs-16 roboto-bold">
                {profileInfo?.followers_count}
              </h5>
              <p
                className="mb-0 fs-12 roboto-regular"
                style={{ color: "#8A93A1" }}
              >
                Followers
              </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <h5 className="fs-16 roboto-bold">
                {profileInfo?.following_count}
              </h5>
              <p
                className="mb-0 fs-12 roboto-regular"
                style={{ color: "#8A93A1" }}
              >
                Following
              </p>
            </div>
          </div>
        </div>

        <div className="text-center my-5">
          <Link
            to={`${PROFILE}/${profileInfo.id}`}
            className="fs-14 roboto-bold hover-blue text-white rounded py-3 px-5 follow-btn"
          >
            {lang?.see_complete_profile}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
