import React, { useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import axios from "axios";
// Components
import NavBar from "../NavBar";
import { BaseURL } from "../../constants/API";
// Images
import UploadIcon from "../../assets/images/icon/upload.png";
// css
import "pdfjs-dist/web/pdf_viewer.css";
// js
import "pdfjs-dist/build/pdf.worker.min";
import ImageComponent from "../common/ImageComponent";
import { Language } from "../../App";

// window.pdfjsLib.GlobalWorkerOptions.workerSrc = null;

const ContestDetail = () => {
  // Page Type
  const { type } = useParams();

  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = SplashData?.user?.id;

  // States
  const fileInputRef = useRef(null);
  const [contestModal, setContestModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    file: "",
    screenshot: "",
  });

  const [pdfSs, setPdfSs] = useState("");
  const [pdfFile, setPdfFile] = useState({});
  const [docList, setDocList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { lang } = useContext(Language);

  // Functions
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setPdfFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const arrayBuffer = e.target.result;
        convertPdfToImages(arrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const convertPdfToImages = (arrayBuffer) => {
    const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
    loadingTask.promise.then(function (pdf) {
      const numPages = pdf.numPages;

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        pdf.getPage(pageNum).then(function (page) {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const viewport = page.getViewport({ scale: 1.5 });

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          const renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            const imageDataUrl = canvas.toDataURL("image/png");
            setPdfSs(imageDataUrl);
          });
        });
      }
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);

    axios
      .post(
        BaseURL + "api/user/contest/uploads/create",
        {
          user_id,
          contest_type: "4",
          type,
          pdf: pdfFile,
          pdf_screenshot_base64: pdfSs,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          res.data.action.map((value) => {
            toast.success(value);
          });
          window.history.back();
        } else {
          setIsLoading(false);
          {
            res.data.action.map((value) => {
              toast.error(value);
            });
          }
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDocumentsList = () => {
    const contestType = "/4/" + type;

    fetch(BaseURL + `api/user/contest/uploads/list/${user_id + contestType}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setDocList(data.data);
        } else {
          toast.error(data.action);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleDeleteDoc = () => {
    fetch(BaseURL + `api/user/contest/uploads/delete/${deleteId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          setDeleteModal(false);
          handleDocumentsList();
          toast.success(data.action);
          setContestModal(true);
        } else {
          toast.error(data.action);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "3rem" }}>
          <Col lg={6} className="bg-white br-16 p-3 my-3">
            <Row>
              <Col className="text-center">
                <h5 className="mb-0">{type}</h5>
              </Col>
            </Row>
            <div>
              {pdfSs || data.screenshot ? (
                <div
                  className="cursor position-relative my-3"
                  style={{
                    border: `1px dashed ${type === "CONCURSO UVEÍTIS SEIOC" ? "#2D3155" : "#1DC1DD"
                      }`,
                    backgroundColor: "#F2FCFE",
                    height: "12rem",
                    borderRadius: "6px",
                  }}
                >
                  <div>
                    <button
                      className="rounded mt-1 me-1 border-0 bg-inherit fw-bold end-0 position-absolute"
                      onClick={() => {
                        setData({ ...data, screenshot: "", file: "" });
                        setPdfFile({});
                        setPdfSs("");
                      }}
                    >
                      x
                    </button>
                  </div>
                  <img
                    src={data.screenshot ? BaseURL + data.screenshot : pdfSs}
                    style={{ objectFit: "cover", objectPosition: "top" }}
                    className="h-100"
                    alt="picture"
                    width="100%"
                  />
                </div>
              ) : (
                <>
                  <div
                    className="cursor my-3"
                    onClick={() => {
                      document.getElementById("pdf-input").click();
                    }}
                    style={{
                      border: `1px dashed ${type === "CONCURSO UVEÍTIS SEIOC"
                          ? "#2D3155"
                          : "#1DC1DD"
                        }`,
                      backgroundColor: "#F2FCFE",
                      minHeight: "12rem",
                      borderRadius: "6px",
                    }}
                  >
                    <div className="text-center mt-5">
                      <img
                        src={UploadIcon}
                        width={40}
                        height={40}
                        alt="picture"
                      />
                      <h4
                        className="fs-14 roboto-medium pt-3"
                        style={{
                          color:
                            type === "CONCURSO UVEÍTIS SEIOC"
                              ? "#2D3155"
                              : "#1DC1DD",
                        }}
                      >
                        {lang?.upload_pdf}
                      </h4>
                    </div>
                  </div>
                  <input
                    id="pdf-input"
                    type="file"
                    ref={fileInputRef}
                    accept=".pdf"
                    onChange={handleFileSelect}
                    className="d-none"
                  />
                </>
              )}
              <div id="output"></div>

              <Row className="mt-2">
                <Col>
                  <button
                    className="btn-blue w-100 border-0 rounded py-2"
                    style={{
                      backgroundColor:
                        type === "CONCURSO UVEÍTIS SEIOC"
                          ? "#2D3155"
                          : "#1DC1DD",
                    }}
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner size={"sm"} /> : lang?.participate}
                  </button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-center">
                  <p
                    className="mb-0 roboto-medium cursor"
                    style={{
                      color:
                        type === "CONCURSO UVEÍTIS SEIOC"
                          ? "#2D3155"
                          : "#1DC1DD",
                    }}
                    onClick={() => {
                      handleDocumentsList();
                      setContestModal(true);
                    }}
                  >
                    {lang?.view_docu_prosented}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={contestModal} centered>
        <ModalHeader
          toggle={() => {
            setContestModal(false);
          }}
        >
          {lang?.docu_subm}
        </ModalHeader>
        <ModalBody>
          {docList.map((data, id) => (
            <div
              className="box-shadow1 d-flex justify-content-between align-items-center p-16 br-10 ps-4 pe-4"
              key={id}
            >
              <div
                className="bg-white dropdown-avatar-shadow br-22 align-self-center cursor"
                onClick={() => {
                  window.open(BaseURL + data?.pdf, "_blank");
                }}
                style={{
                  padding: "2px",
                  height: "70px",
                  width: "70px",
                }}
              >
                <ImageComponent
                  src={BaseURL + data?.pdf_screenshot}
                  img_height="100%"
                  img_width="100%"
                  loader_height="100%"
                  loader_width="100%"
                  compClassName="br-22"
                />
              </div>
              <button
                className="btn btn-sm btn-danger d-flex align-self-center"
                onClick={() => {
                  setContestModal(false);
                  setDeleteId(data.id);
                  setDeleteModal(true);
                }}
              >
                <FaRegTrashAlt size={15} />
              </button>
            </div>
          ))}
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div>
            <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
              {lang?.do_want_delete_account}
            </h2>
            <p className="fs-18 fs-md-16 roboto-regular">
              {lang?.delete_account_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="bg-white text-dark border-1 border rounded px-md-3 w-lg-100 py-2"
              onClick={() => {
                setDeleteModal(false);
                setContestModal(true);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded px-md-3 w-lg-100 py-2"
              onClick={handleDeleteDoc}
            >
              {lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ContestDetail;
