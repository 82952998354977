import { useNavigate } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import { COURSE_DETAILS } from "../../constants/RoutesUrl";
import { BaseURL } from "../../constants/API";

export default function CourseCard({ course }) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white p-1 br-8 cursor"
      onClick={() => {
        sessionStorage.setItem("CourseId", course.id);
        navigate(COURSE_DETAILS);
      }}
      style={{ width: "280px" }}
    >
      <ImageComponent
        src={BaseURL + course.cover}
        img_height="303px"
        loader_height="303px"
        img_width="100%"
        loader_width="100%"
        compClassName="brt-7 object-cover"
      />
      <div className="my-2">
        <h5 className="fs-12 roboto-bold mb-1">
          {course.title.length > 40
            ? course.title.substring(0, 40) + "..."
            : course.title}
        </h5>
        <div className="d-flex align-items-center justify-content-between">
          <p
            className="fs-12 roboto-medium mb-0"
            style={{ color: course.price === "0" ? "#FF9500" : "#007AFF" }}
          >
            {course.price === "0" ? "Free" : "€" + course.price}
          </p>
          <p className="fs-12 roboto-medium text-gray mb-0 line-through">
            <s>€165.00</s>
          </p>
        </div>
      </div>
    </div>
  );
}
