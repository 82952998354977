
import { LOGIN } from "../constants/RoutesUrl";
import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';


export const fetchFeedData = async () => {
  
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
const {data} = JSON.parse(localStorage.getItem("SignupData"));

    try {
        const response = await apiAdmin.post(
            `user/web/home`,
            { user_id,type:"all" }, {
                headers: {
                  Authorization: `Bearer ${data.token}`, // Ensure token is passed correctly
                },
              });
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }


};

export const likePostApi = async (post_id) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/like/${post_id}/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }
};

export const savePostApi = async (post_id) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/post/save/${post_id}/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }
};

export const repostPostApi = async (post_id) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/post/repost/${post_id}/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching feed data:', error);
        throw error;
    }
}

export const usersLikedPostApi = async (post_id, page) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/post/likes/${post_id}/${user_id}?page=${page}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching liked users data:', error);
        throw error;
    }
}

export const reserveWebinarApi = async (post_id) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/reserve/${post_id}/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching reserve webinar data:', error);
        throw error;
    }
}

export const followUserApi = async (id) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/user/${id}/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching reserve webinar data:', error);
        throw error;
    }
}

