import React from "react";
import CourseCard from "./CourseCard";
import { fetchPurchasedCourses } from "../../services/coursesApi";
import { toast } from "react-toastify";
import { ShimmerPostItem } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "react-query";

const PurchasedCourses = ({ count }) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["purchasedCourses"],
      queryFn: ({ pageParam = 1 }) =>
        fetchPurchasedCourses({ page: pageParam }),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });
  const courses = data?.pages?.flatMap((page) => page.data.data);
  return (
    <>
      {isLoading ? (
        <div className="d-flex gap-3 flex-wrap">
          {new Array(count).fill(0).map((_, index) => (
            <div style={{ width: "280px" }} key={index}>
              <ShimmerPostItem imageHeight={200} card title />
            </div>
          ))}
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!isFetchingNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={
            <div style={{ width: "280px" }}>
              <ShimmerPostItem imageHeight={200} card title />
            </div>
          }
        >
          <div className="d-flex gap-3 flex-wrap">
            {courses?.flatMap((course, index) => (
              <CourseCard key={index} course={course} />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default PurchasedCourses;
