import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import {
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import Lottie from "lottie-react";
import Logo from "../assets/images/icon/mr-logo.webp";
import { IoSearch } from "react-icons/io5";
import { IoReorderThree } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";

import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import NotificationIcon from "../assets/images/icon/notification.webp";
import MessageIcon from "../assets/images/icon/message.webp";

import HomeIcon from "../assets/images/icon/home.png";
import HomeActive from "../assets/images/icon/home-active.webp";
import JobIcon from "../assets/images/icon/job.png";
import JobActive from "../assets/images/icon/job-active.png";
import ClinicalActive from "../assets/images/icon/clinical-active.png";
import ClinicalIcon from "../assets/images/icon/clinical.png";
import EventsIcon from "../assets/images/icon/calendar.png";
import EventActive from "../assets/images/icon/calendar-active.png";
import CourseIcon from "../assets/images/icon/course.png";
import CourseActive from "../assets/images/icon/course-active.png";
import {
  ADDPOST,
  CHAT,
  CLINICAL_SESSIONS,
  COURSES,
  EVENTS,
  FEED,
  JOBS,
  LIVE_WEBINARS,
  LOGIN,
  NOTIFICATIONS,
  PROFILE,
  SEARCH,
  SETTINGMODULE,
} from "../constants/RoutesUrl";
import { Link, useNavigate } from "react-router-dom";

import AnimatedImage from "../assets/images/live_webinar_lottie_file.json";
import WalletIcon from "../assets/images/icon/wallet.png";
import SettingsIcon from "../assets/images/icon/settings.png";
import FilesIcon from "../assets/images/icon/files.png";
import HelpIcon from "../assets/images/icon/headphones.png";
import LogoutIcon from "../assets/images/icon/logout.png";
import { BaseURL, CountApi, LogoutApi } from "../constants/API";
import axios from "axios";
import { toast } from "react-toastify";
import CustomTooltip from "./common/CustomTooltip";
import ImageComponent from "./common/ImageComponent";
import { FeedDataRefetch, Language, LoginUserData } from "../App";
import { IoIosArrowForward } from "react-icons/io";
import { CountContext } from "../constants/ProtectedRoute";
import { getSplashData } from "../services/api";
import PlusPng from "../assets/images/icons/plus.png";
import SearchInput from "./search/SearchInput";
import { useQuery } from "react-query";

const NavBar = () => {
  const { updateCount } = useContext(CountContext);
  const { splashData, updateSplash } = useContext(LoginUserData);
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  const {data} = JSON.parse(localStorage.getItem("SignupData"));
// console.log("userToken",userToken)
  const [state, setState] = useState({
    isLoading: false,
    ModalStatus: false,
    counts: {},
    naveOpen: false,
    profileModal: false,
    profileDropdown: false,
    closingProfile: false,
    typeDropdown: false,
    closingFilter: false,
  });

  const [showCreatePost, setShowCreatePost] = useState(true);

  const constantPagesData = useMemo(
    () => [
      {
        id: "hometab",
        image: HomeIcon,
        image_active: HomeActive,
        active: true,
        tooltip: lang?.home,
        link: FEED,
        width: 23,
        height: 26,
        find: "",
      },
      {
        id: "eventtab",
        image: EventsIcon,
        image_active: EventActive,
        active: false,
        tooltip: lang?.events,
        link: EVENTS + "/1",
        width: 23,
        height: 26,
        find: "event",
      },
      {
        id: "coursetab",
        image: CourseIcon,
        image_active: CourseActive,
        active: false,
        tooltip: lang?.courses,
        link: COURSES + "/1",
        width: 21,
        height: 24,
        find: "course",
      },
      {
        id: "clinicaltab",
        image: ClinicalIcon,
        image_active: ClinicalActive,
        active: false,
        tooltip: lang?.clinical_sessions,
        link: CLINICAL_SESSIONS,
        width: 24,
        height: 24,
        find: "clinical",
      },
      // {
      //   id: "jobtab",
      //   image: JobIcon,
      //   image_active: JobActive,
      //   active: false,
      //   tooltip: lang?.jobs,
      //   link: JOBS + "/1",
      //   width: 26,
      //   height: 24,
      //   find: "job",
      // },
    ],
    [lang]
  );

  const constantData = useMemo(
    () =>
      [
        showCreatePost && {
          image: PlusPng,
          toolTip: "Create Post",
          link: ADDPOST + "/1",
        },
        { image: MessageIcon, toolTip: lang?.messages, link: CHAT },
        {
          image: NotificationIcon,
          toolTip: lang?.notifications,
          link: NOTIFICATIONS,
        },
      ].filter(Boolean),
    [lang, showCreatePost]
  );

  const handleStateUpdate = (updates) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  const handleDropdown = (e, ref, setStateKey) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handleStateUpdate({ [setStateKey]: true });
      setTimeout(() => handleStateUpdate({ [setStateKey]: false }), 300);
    }
  };

  const handleSubmit = () => {
    handleStateUpdate({ isLoading: true });
    axios
      .post(LogoutApi, {
        // user_id: "",
        deviceId:"MedicalRadar Website Device"
        // token:data.token
      }, {
        headers: {
          Authorization: `Bearer ${data.token}`, // Set the Authorization header
        },
      })
      .then((response) => {
        if (response.data.status) {
          sessionStorage.clear();
          localStorage.clear();
          handleStateUpdate({ ModalStatus: false, isLoading: false });
          Navigate(LOGIN);
        } else {
          handleStateUpdate({ isLoading: false });
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCount = (user_id) => {
    fetch(`${CountApi}${user_id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          updateCount(data);
          handleStateUpdate({ counts: data });
        } else {
          toast.error(data.error);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  useQuery({
    queryKey: ["splashData"],
    queryFn: getSplashData,
    onSuccess: (data) => {
      if (data.data.status) {
        updateSplash(data.data);
        localStorage.setItem("SplashData", JSON.stringify(data.data));
        handleCount(data.data.user.id);
      } else {
        toast.error("Error while fetching splash data");
      }
    },
  });

  const collapseProfileDropdown = useRef(null);

  useEffect(() => {
    const handleProfileDropdown = (e) => {
      handleDropdown(e, collapseProfileDropdown, "closingProfile");
    };

    if (state.profileDropdown)
      document.addEventListener("mousedown", handleProfileDropdown);

    return () => {
      document.removeEventListener("mousedown", handleProfileDropdown);
    };
  }, [state.profileDropdown]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        collapseProfileDropdown.current &&
        !collapseProfileDropdown.current.contains(e.target)
      ) {
        handleStateUpdate({ profileDropdown: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setShowCreatePost(window.scrollY > 136);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container
        fluid
        className="bg-white border-bottom border-1 border-inherit position-fixed"
        style={{
          zIndex: "2",
          boxShadow: "rgba(0,0,0,0.3 ) 0px 0px 2px 0px",
          height: "56px",
        }}
      >
        <Row className="justify-content-between position-relative h-100 align-items-center">
          <Col
            xl={3}
            lg={4}
            md={12}
            sm={12}
            className="d-flex gap-3 justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center"
          >
            <div className="logo-991">
              <img
                src={Logo}
                alt="Medical Radar"
                style={{ width: "40px", height: "40px" }}
                className="cursor"
                onClick={() => {
                  Navigate(FEED);
                }}
              />
            </div>
            <SearchInput />

            {/* show again third column in tab and mobile screen */}
            <Row className="justify-content-end d-lg-none filter-991">
              {state.counts?.active_webinar ? (
                <CustomTooltip
                  text="Live Webinars"
                  compClassName={`w_fit_content mt-1  ${
                    state.feed_url === window.location.href ? "right_15px" : ""
                  }`}
                  account_class="mt-2"
                  style={{ right: "-15px", top: "-2px" }}
                >
                  <Col
                    md={2}
                    sm={12}
                    className="cursor"
                    onClick={() => {
                      Navigate(LIVE_WEBINARS);
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Lottie
                        animationData={AnimatedImage}
                        loop
                        autoplay
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Col>
                </CustomTooltip>
              ) : (
                <></>
              )}
              {constantData.map((value, index) => (
                <IconsComponent
                  image={value.image}
                  toolTip={value.toolTip}
                  key={index}
                  link={value?.link}
                  updateState={(newValue) => {
                    handleStateUpdate({ typeDropdown: newValue });
                  }}
                  counts={state.counts}
                />
              ))}
              <CustomTooltip
                text={lang?.account}
                compClassName="w_fit_content"
                topMargin="0.8rem"
                account_class="account-tooltip-768"
              >
                <Col className="profile-991">
                  <div
                    className="rounded-circle cursor"
                    onClick={() => handleStateUpdate({ profileModal: true })}
                    style={{
                      border: "2px solid #00c5de",
                      width: "40px",
                      padding: "1px",
                      height: "40px",
                      filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.16 ))",
                    }}
                  >
                    <ImageComponent
                      src={
                        splashData?.user?.image
                          ? BaseURL + splashData?.user?.image
                          : Avatar
                      }
                      compClassName="rounded-circle h-100 w-100"
                    />
                  </div>
                </Col>
              </CustomTooltip>
            </Row>
            <div
              className="position-absolute cursor d-lg-none"
              style={{ top: "15px", right: "7px" }}
            >
              <span
                onClick={() => {
                  Navigate(SEARCH);
                }}
                className="me-1"
              >
                <IoSearch size={24} />
              </span>
              <span
                onClick={() => handleStateUpdate({ naveOpen: !state.naveOpen })}
              >
                {state.naveOpen ? (
                  <CiCircleRemove size={24} />
                ) : (
                  <IoReorderThree size={25} />
                )}
              </span>
            </div>
          </Col>
          <Col
            xl={4}
            lg={6}
            md={12}
            sm={12}
            className={`naveShow text-center align-self-center ${
              state.naveOpen ? "d-block" : ""
            }`}
          >
            <Row className="d-flex justify-content-center my-0 my-lg-0 flex-nowrap">
              {constantPagesData.map((data) => (
                <PagesTabsComponent
                  key={data.id}
                  counts={state.counts}
                  image={
                    data.link === window.location.pathname ||
                    window.location.pathname.search(data.find) > 0
                      ? data.image_active
                      : data.image
                  }
                  status={
                    data.link === window.location.pathname ||
                    window.location.pathname.search(data.find) > 0
                  }
                  toolTip={data.tooltip}
                  link={data.link}
                  height={data.height}
                  width={data.width}
                />
              ))}
            </Row>
          </Col>
          <Col xl={3} lg={3} md={12} sm={12} className={`d-none-991`}>
            <Row className="justify-content-end me-1" style={{ gap: "0px" }}>
              {state.counts?.active_webinar ? (
                <CustomTooltip
                  text="Live Webinars"
                  compClassName="w_fit_content"
                  account_class="mt-3"
                >
                  <Col
                    md={2}
                    sm={12}
                    className="cursor col-12"
                    onClick={() => {
                      Navigate(LIVE_WEBINARS);
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <Lottie
                        animationData={AnimatedImage}
                        loop
                        autoplay
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Col>
                </CustomTooltip>
              ) : (
                <></>
              )}

              {constantData.map((value, index) => (
                <IconsComponent
                  image={value.image}
                  toolTip={value.toolTip}
                  key={index}
                  link={value?.link}
                  updateState={(newValue) => {
                    handleStateUpdate({ typeDropdown: newValue });
                  }}
                  counts={state.counts}
                />
              ))}

              <CustomTooltip
                text={lang?.account}
                compClassName="w_fit_content"
                topMargin="0.8rem"
                account_class="m--7px"
              >
                <Col>
                  <div
                    className="rounded-circle cursor"
                    onClick={() =>
                      handleStateUpdate({
                        profileDropdown: !state.profileDropdown,
                      })
                    }
                    style={{
                      border: "2px solid #00c5de",
                      width: "40px",
                      padding: "1px",
                      height: "40px",
                      filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.16 ))",
                    }}
                  >
                    <ImageComponent
                      src={
                        state.counts?.user?.image
                          ? BaseURL + state.counts?.user?.image
                          : Avatar
                      }
                      compClassName="rounded-circle h-100 w-100"
                    />
                  </div>
                </Col>
              </CustomTooltip>
              {/* profile modal */}
              <Modal
                centered
                isOpen={state.profileModal}
                toggle={() =>
                  handleStateUpdate({ profileModal: !state.profileModal })
                }
                data-bs-keyboard="false"
                backdrop={true}
              >
                <ModalBody className="p-2">
                  <div className="bg-white rounded p-2 d-flex flex-column gap-2">
                    <Link
                      to={PROFILE + "/" + state.counts?.user?.id}
                      style={{
                        zIndex: "9",
                      }}
                      className="px-2 py-2 mt-1 manue-hover hover-blue rounded d-flex justify-content-between align-items-center shadow-sid"
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          className="rounded-circle"
                          src={
                            state.counts?.user?.image
                              ? BaseURL + state.counts?.user?.image
                              : Avatar
                          }
                          alt="..."
                          width={45}
                          height={45}
                        />
                        <div>
                          <div className="fs-14 roboto-bold">
                            {state.counts?.user?.name}
                          </div>
                          <p className="fs-12 roboto-medium m-0">
                            @{state.counts?.user?.user_name}
                          </p>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>

                    <div className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue cursor">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img src={WalletIcon} alt="..." className="w-100" />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.wallet}: 0.0$
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </div>

                    <Link
                      to={SETTINGMODULE + "/1"}
                      className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img src={SettingsIcon} alt="..." className="w-100" />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.manage_account}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>

                    <Link
                      to={SETTINGMODULE + "/6"}
                      className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img src={FilesIcon} alt="..." className="w-100" />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.terms_privacy}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>

                    <Link
                      to={SETTINGMODULE + "/5"}
                      className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                          <img src={HelpIcon} alt="..." className="w-100" />
                        </div>
                        <div>
                          <h5 className="fs-14 roboto-bold m-0">
                            {lang?.help}
                          </h5>
                        </div>
                      </div>
                      <div>
                        <IoIosArrowForward size={25} />
                      </div>
                    </Link>

                    <div
                      className="px-2 rounded py-2 d-flex gap-3 align-items-center manue-hover hover-blue cursor"
                      onClick={() => {
                        handleStateUpdate({
                          profileDropdown: false,
                          ModalStatus: true,
                        });
                      }}
                    >
                      <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                        <img src={LogoutIcon} alt="..." className="w-100" />
                      </div>
                      <div>
                        <h5 className="fs-14 roboto-bold m-0">
                          {lang?.sign_out}
                        </h5>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Row>
          </Col>

          <Collapse
            isOpen={state.profileDropdown && !state.closingProfile}
            innerRef={collapseProfileDropdown}
            className="position-absolute end-0 top-0"
            style={{ zIndex: "99", marginTop: "56px", maxWidth: "360px" }}
          >
            <div
              className="bg-white rounded p-2 d-flex flex-column gap-2"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 3px 15px",
              }}
            >
              <Link
                to={PROFILE + "/" + state.counts?.user?.id}
                style={{
                  zIndex: "9",
                }}
                className="px-2 py-2 mt-1 hover-blue manue-hover rounded d-flex justify-content-between align-items-center shadow-sid"
              >
                <div className="d-flex gap-2 align-items-center">
                  <img
                    className="rounded-circle"
                    src={
                      state.counts?.user?.image
                        ? BaseURL + state.counts?.user?.image
                        : Avatar
                    }
                    alt="..."
                    width={45}
                    height={45}
                  />
                  <div>
                    <div className="fs-14 roboto-bold">
                      {state.counts?.user?.name}
                    </div>
                    <p className="fs-12 roboto-medium m-0">
                      @{state.counts?.user?.user_name}
                    </p>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <div className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue cursor">
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={WalletIcon} alt="..." className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">
                      {lang?.wallet}: 0.0$
                    </h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </div>
              {/* <hr className="my-1" /> */}
              <Link
                to={SETTINGMODULE + "/1"}
                className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={SettingsIcon} alt="..." className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">
                      {lang?.manage_account}
                    </h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <Link
                to={SETTINGMODULE + "/6"}
                className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={FilesIcon} alt="..." className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">
                      {lang?.terms_privacy}
                    </h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <Link
                to={SETTINGMODULE + "/5"}
                className="d-flex px-2 rounded py-2 justify-content-between align-items-center manue-hover hover-blue"
              >
                <div className="d-flex gap-3 align-items-center">
                  <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                    <img src={HelpIcon} alt="..." className="w-100" />
                  </div>
                  <div>
                    <h5 className="fs-14 roboto-bold m-0">{lang?.help}</h5>
                  </div>
                </div>
                <div>
                  <IoIosArrowForward size={25} />
                </div>
              </Link>
              {/* <hr className="my-1" /> */}
              <div
                className="px-2 rounded py-2 d-flex gap-3 align-items-center manue-hover hover-blue cursor"
                onClick={() => {
                  handleStateUpdate({
                    profileDropdown: false,
                    ModalStatus: true,
                  });
                }}
              >
                <div className="rounded-circle d-flex justify-content-center align-items-center p-2 icon-style">
                  <img src={LogoutIcon} alt="..." className="w-100" />
                </div>
                <div>
                  <h5 className="fs-14 roboto-bold m-0">{lang?.sign_out}</h5>
                </div>
              </div>
            </div>
          </Collapse>
        </Row>
      </Container>

      <Modal
        isOpen={state.ModalStatus}
        toggle={() => handleStateUpdate({ ModalStatus: !state.ModalStatus })}
        centered
        zIndex={9999}
      >
        <ModalBody className="bg-white rounded p-4">
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_sign_out_account}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.signout_modal_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                handleStateUpdate({ ModalStatus: false });
              }}
            >
              {lang?.cancel}
            </button>

            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => {
                handleSubmit();
              }}
            >
              {state.isLoading ? <Spinner size="sm" /> : lang?.yes_signout}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default NavBar;

const PagesTabsComponent = ({
  image,
  status,
  toolTip,
  link,
  height,
  width,
  counts,
}) => {
  const Navigate = useNavigate();
  const { lang } = useContext(Language);
  const { updateRefetch, refetch } = useContext(FeedDataRefetch);
  return (
    <CustomTooltip text={toolTip} compClassName="col" topMargin="0.7rem">
      <Col
        className={`position-relative cursor p-2 rounded ${
          status ? "bg-white" : "tab-bg"
        }`}
        onClick={() => {
          if (link === window.location.pathname) {
            if (toolTip === lang?.home) {
              sessionStorage.removeItem("prevFeedData");
            } else if (toolTip === lang?.events) {
              sessionStorage.removeItem("eventsPageData");
            } else if (toolTip === lang?.courses) {
              sessionStorage.removeItem("coursesPageData");
            } else if (toolTip === lang?.jobs) {
              sessionStorage.removeItem("prevJobsData");
            } else if (toolTip === lang?.clinical_sessions) {
              sessionStorage.removeItem("prevClinicalSessionsData");
              sessionStorage.removeItem("prevStartedClinicalSessionsData");
            }
            updateRefetch(!refetch);
            document.documentElement.scrollTop = 0;
          } else {
            Navigate(link);
          }
        }}
      >
        {toolTip === lang?.clinical_sessions ? (
          <div
            className="position-relative d-flex justify-content-center"
            style={{ marginTop: "2.5px" }}
          >
            <img src={image} alt="..." height={height} width={width} />
            {counts && counts.active_clinical_session > 0 ? (
              <div
                className="bg-danger rounded-circle position-absolute top-0"
                style={{
                  zIndex: "2",
                  width: "15px",
                  height: "15px",
                  margin: "-3px -7px 0px 10px",
                }}
              >
                <p
                  className="fw-bold text-white m-0"
                  style={{ fontSize: "10px" }}
                >
                  {counts.active_clinical_session}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <img src={image} alt="..." height={height} width={width} />
        )}
        {status && (
          <div
            className="rounded-pill bg-blue position-absolute w-100 end-0 mx-0"
            style={{
              height: "6px",
              marginTop: "0.6rem",
            }}
          ></div>
        )}
      </Col>
    </CustomTooltip>
  );
};

const IconsComponent = ({ image, toolTip, link, updateState, counts }) => {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { lang } = useContext(Language);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {toolTip === "Create Post" && window.location.pathname !== "/feed" ? (
        <></>
      ) : (
        <>
          <div className="w_fit_content">
            <div>
              <div
                className="rounded-circle border border-2 border-inherit text-center bg-blue-opacity1 cursor position-relative d-flex justify-content-center align-items-center"
                onClick={() => {
                  if (link === null) {
                    if (screenWidth < 992) {
                      setFilterModal(true);
                    } else {
                      updateState(!open);
                    }
                  } else {
                    Navigate(link);
                  }
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              >
                {toolTip === lang?.messages && counts.unread > 0 ? (
                  <div
                    className="bg-danger rounded-circle position-absolute top-0 end-0 mt-1"
                    style={{
                      marginRight: "3px",
                      zIndex: "2",
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <p
                      className="fw-bold text-white mb-0"
                      style={{ fontSize: "10px" }}
                    >
                      {counts.unread}
                    </p>
                  </div>
                ) : toolTip === lang?.notifications &&
                  counts?.clinical +
                    counts?.clinical_session +
                    counts?.event +
                    counts?.course +
                    counts?.social +
                    counts?.webinar >
                    0 ? (
                  <div
                    className="bg-danger rounded-circle position-absolute top-0 end-0 mt-1"
                    style={{
                      marginRight: "3px",
                      zIndex: "2",
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <p
                      className="fw-bold text-white mb-0"
                      style={{ fontSize: "10px" }}
                    >
                      {counts &&
                        counts?.clinical +
                          counts?.clinical_session +
                          counts?.event +
                          counts?.course +
                          counts?.social +
                          counts?.webinar}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <CustomTooltip text={toolTip}>
                  {toolTip === "Create Post" ? (
                    <img
                      src={image}
                      alt="Medical Radar"
                      className="text-center align-self-center"
                      height={16}
                      style={{ marginBottom: "2px" }}
                      width={16}
                    />
                  ) : toolTip === lang?.notifications ? (
                    <img
                      src={image}
                      alt="Medical Radar"
                      height={20}
                      style={{ marginBottom: "2px" }}
                      width={16}
                    />
                  ) : toolTip === lang?.messages ? (
                    <img
                      src={image}
                      alt="Medical Radar"
                      style={{ marginBottom: "3px" }}
                      height={18}
                      width={21}
                    />
                  ) : (
                    ""
                  )}
                </CustomTooltip>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

IconsComponent.args = {
  autohide: true,
  flip: true,
};

IconsComponent.argTypes = {
  placement: {
    control: { type: "select" },
    options: ["bottom"],
  },
};
PagesTabsComponent.args = {
  autohide: true,
  flip: true,
};

PagesTabsComponent.argTypes = {
  placement: {
    control: { type: "select" },
    options: ["bottom"],
  },
};
