import React, { useCallback, useContext, useState } from "react";
import { HiPlus } from "react-icons/hi";
import NavBar from "../../components/NavBar";
import axios from "axios";
import { EditProfileInfoApi } from "../../constants/API";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { BiSolidUser } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";
import { MdLocationOn } from "react-icons/md";
import {
  Col,
  Container,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../hooks/useCropImage";
import { Language } from "../../App";

const EditProfileInfo = () => {
  const { lang } = useContext(Language);
  const navigate = useNavigate(""); // Used for navigation

  // sessionStorage
  const localData = JSON.parse(localStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  const location = useLocation(); // Used for params
  const searchParams = new URLSearchParams(location.search);

  // Params
  const user_name = searchParams.get("user_name");
  const name = searchParams.get("name");
  const bio = searchParams.get("bio");
  const link = searchParams.get("link");
  const locations = searchParams.get("location");
  const profileImage = searchParams.get("image");
  const email = searchParams.get("email");

  // States
  const [editData, setEditData] = useState({
    name,
    user_name,
    bio,
    link,
    locations,
    profileImage,
    email,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cropperModal, setCropperModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Functions
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropper = async () => {
    try {
      const croppedImage = await getCroppedImg(
        editData.profileImage,
        croppedAreaPixels
      );
      // Replace the Blob URL with your actual Blob URL
      const blobUrl = croppedImage;
      // Fetch the Blob content
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            setEditData({ ...editData, profileImage: base64Data });
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error(e);
    }
  };

  const handleSave = () => {
    setIsLoading(true);

    const { name, user_name, bio, link, locations, profileImage, email } =
      editData;

    const data = {
      name,
      user_name,
      location: locations,
      email,
      link,
      bio,
      image: profileImage,
    };

    axios
      .post(EditProfileInfoApi + login_id, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status) {
          navigate("/profile");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row className="justify-content-center editscroll scroll-hide"> 
        <Col md={5}>
          <div className="editprofileinfo">
            <div className="edit-profile br-8 bg-white box-shadow1 m-xl-0 m-lg-auto m-md-auto">
              <div style={{ backgroundColor: "#00C5DE" }}>
                <div className="d-flex p-1">
                  <div className=" editprofilearrow">
                    <FaArrowLeft
                      onClick={() => window.history.back()}
                      color="white"
                    />
                  </div>
                  <h3 className="editbtn mt--12 fs-20 fs-md-16 fw-bold p-3 text-white">
                    {lang?.edit_profile}
                  </h3>
                </div>
              </div>
              <form className="w-75 m-auto">
                <div className="d-flex justify-content-center mt-4">
                  <div className="profile_img position-relative">
                    <img
                      src={editData.profileImage}
                      alt="picture"
                      id="profileimage"
                    />
                    <input
                      type="file"
                      id="media"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        // Preview the image
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setEditData({
                            ...editData,
                            profileImage: reader.result,
                          });
                          setCropperModal(true);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    <label className="plus bg-white br-8" htmlFor="media">
                      <HiPlus color="black" size={30} />
                    </label>
                  </div>
                </div>

                <div className="d-flex flex-column gap-4">
                  <div>
                    <h6 className="fs-16 ">
                      {" "}
                      <BiSolidUser color="#00C5DE" />
                      {lang?.full_name}
                    </h6>
                    <input
                      type="text"
                      defaultValue={editData.name}
                      onChange={(e) =>
                        setEditData({ ...editData, name: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <h6 className="fs-16 ">
                      <BiSolidUser color="#00C5DE" /> {lang?.user_name}
                    </h6>
                    <input
                      type="text"
                      defaultValue={editData.user_name}
                      onChange={(e) =>
                        setEditData({ ...editData, user_name: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <h6 className="fs-16 ">
                      <MdLocationOn color="#00C5DE" /> {lang?.location}
                    </h6>
                    <input
                      type="text"
                      defaultValue={editData.locations}
                      onChange={(e) =>
                        setEditData({ ...editData, locations: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <h6 className="fs-16 ">
                      <MdEmail color="#00C5DE" /> {lang?.email_address}
                    </h6>
                    <input
                      type="text"
                      defaultValue={editData.email}
                      onChange={(e) =>
                        setEditData({ ...editData, email: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <h6 className="fs-16 ">
                      <TfiWorld color="#00C5DE" /> {lang?.website}
                    </h6>
                    <input
                      type="text"
                      defaultValue={editData.link}
                      onChange={(e) =>
                        setEditData({ ...editData, link: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <h6 className="fs-16 ">
                      <TfiWorld color="#00C5DE" /> {lang?.bio}
                    </h6>
                    <textarea
                      cols="30"
                      rows="10"
                      defaultValue={editData.bio}
                      onChange={(e) =>
                        setEditData({ ...editData, bio: e.target.value })
                      }
                    ></textarea>
                  </div>

                  <button
                    type="button"
                    disabled={isLoading}
                    className="bg-blue border-0 p-3 br-10 fs-16 fw-bold text-white mb-4"
                    onClick={handleSave}
                  >
                    {isLoading ? <Spinner /> : lang?.update}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>

      {/* image cropping  */}
      <Modal isOpen={cropperModal} centered zIndex={9}>
        <div className="bg-white px-3 br-16">
          <ModalHeader toggle={() => setCropperModal(false)}>
            {lang?.image_croper}
          </ModalHeader>
          <div className="position-relative" style={{ minHeight: "40rem" }}>
            <Cropper
              image={editData.profileImage}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="d-none">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
          <ModalFooter className="gap-3">
            <button
              className="btn btn-secondary border-0 px-4 py-2"
              onClick={() => {
                setCropperModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue border-0 px-4 py-2"
              onClick={() => {
                handleCropper();
                setCropperModal(false);
              }}
            >
              {lang?.save}
            </button>
          </ModalFooter>
        </div>
      </Modal>
    </Container>
  );
};

export default EditProfileInfo;
