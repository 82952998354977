import React, { createContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import ChatHeader from "./ChatHeader";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";

import ChatInput from "./ChatInput";
import { BaseURL } from "../../constants/API";
import { useContext, useRef } from "react";
import {  selectedChatContext } from "../../pages/chat/ChatModule";
import { LoginUserData } from "../../App";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { ChatsContext } from "./ChatsProvider";

const Message = ({ text, time, isSender, attachment,showAvatar, showTime, avatar, message, isGroup, fullname }) => {

  return (
    <>
      <div
        className={`d-flex align-self-${isSender ? "end" : "start"}`}
        style={{ maxWidth: "50%" }}
      >
        {/* Conditionally render the avatar only if it's a group chat and the user is not the sender */}
        {isGroup && !isSender && showAvatar && (
          <img
            src={avatar? `${BaseURL}${avatar}` : Avatar}
            alt="Avatar"
            style={{
              maxWidth: "25px",
              maxHeight: "25px",
              borderRadius: "8px",
              marginRight: "5px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)"
            }}
          />
        )}
        <div
          className={`${attachment ? "p-0 " : " py-1"} border`}
          style={{
            backgroundColor: isSender ? "#1DC1DD" : "#F5F5F5",
            borderRadius: `10px 10px ${isSender ? "0px 10px" : "10px 0px"}`,
          }}
        >
          {attachment && (
            <div
              onClick={() => {
                window.open(`${BaseURL}${attachment}`, "_blank");
              }}
            >
              <ImageComponent
                src={`${BaseURL}${attachment}`}
                compClassName="cursor object-cover rounded"
                img_width="300px"
                img_height="300px"
                loader_height="300px"
                loader_width="300px"
              />
            </div>
          )}
          <p
            className={`mb-0 mx-1 roboto-medium fs-14 ${
              isSender ? "text-white" : ""
            }`}
          >
            <span style={{ whiteSpace: "pre-wrap" }}>{text}</span>
          </p>
        </div>
      </div>
      {showTime  && (
        <p
          className={`mb-0 roboto-regular fs-12 text-secondary ${
            isSender ? "text-end" : ""
          }`}
        >
          {/* Render the fullname and time if the user is not the sender */}
          {isGroup && !isSender && <span>{fullname} - </span>}
          {new Date(time * 1000).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      )}
    </>
  );
};



const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const groupMessagesByDate = (messages) => {

  return messages.reduce((acc, message) => {
    const date = formatDate(message.time);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});
};

export const RefetchMessagesContext = createContext();

export default function Chats() {
  const { selectedChat } = useContext(selectedChatContext);
  const { splashData } = useContext(LoginUserData);
const { updateChatMessage } = useContext(ChatsContext);
  const [messages, setMessages] = useState([]);  // Current chat messages
  const [loading, setLoading] = useState(false); // Loading state
  const [tempMessages, setTempMessages] = useState([]); // Temp message state
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const fetchUsersMessages = async () => {
    try {
      const response = await axios.post(`${BaseURL}api/all/messages`, {
        from: splashData?.user.id,
        to: selectedChat?.user.id,
      });
      if (response.data.status === true) {
        setMessages(response.data.data.data.reverse())
        // setTempMessages(response.data.data.data.reverse()); // Store in temp state
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch messages. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchGroupMessages = async () => {
    try {

      const response = await axios.post(`${BaseURL}api/group/all/messages`, {
        user: String(splashData?.user.id),
        group: String(selectedChat?.group_id),
      });

      if (response.data.status === true) {
        
        setMessages(response.data.data.messages.data.reverse())

      
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch group messages. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Pusher subscription and real-time updates
  useEffect(() => {
    setLoading(true)
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
    });
    let channel;

if(selectedChat?.group_id === 0){
 
   channel =
   pusher.subscribe(String(selectedChat.from_to))
}else{
   channel =
  pusher.subscribe(selectedChat?.group_id === 0 
   ? String(selectedChat?.user.id) 
   : String(selectedChat?.group_id))
  
}
if(selectedChat.group_id!==0){

 
  channel.bind("group-event", function (data) {

    setMessages((prevMessages) => [...prevMessages,data]);
    updateChatMessage(data.message, selectedChat.group_id);
    scrollToBottom();
  });
}else{
      channel.bind("message-event", function (data) {


        setMessages((prevMessages) => [...prevMessages,data]);
        updateChatMessage(data.message, selectedChat.user.id);
        scrollToBottom();
      });
    }

    return () => {
      if (selectedChat?.group_id === 0) {
        pusher.unsubscribe(String(selectedChat?.user.id));
      } else {
        pusher.unsubscribe(String(selectedChat?.group_id));
      }
      pusher.unsubscribe(String(selectedChat?.from_to)); // Ensure all channels are unsubscribed
    };
        // pusher.unsubscribe(
        //   selectedChat?.group_id === 0 
        //   ? String(selectedChat?.user.id) 
        //   : String(selectedChat?.group_id)
        // );
    // };
  }, [selectedChat]);

  // Fetch messages whenever `selectedChat` changes
  useEffect(() => {
    // if (selectedChat) {
      // setMessages([]); // Clear messages visually
      // setLoading(true); // Show loading state
      if (selectedChat?.group_id === 0) {
        fetchUsersMessages();
      } else {
        fetchGroupMessages();
      }
    // }
  }, [selectedChat]);

  // Update `messages` when new messages are fetched
  // useEffect(() => {
  //   if (tempMessages.length > 0) {
  //     setMessages(tempMessages); // Update messages state
    
  //     // setTempMessages([]); // Clear temporary storage
  //     }
  // }, [tempMessages]);

  // Scroll to bottom when `messages` state changes
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);
  useEffect(() => {
    
      scrollToBottom();  // Scroll to the bottom when messages update
    
  }, [messages]);

  const shouldShowTime = (currentMessage, nextMessage) => {

    if (!nextMessage) return true;
    return (nextMessage.time - currentMessage.time) > 60;
  };


  
  
  
  // const shouldShowAvatar = (currentMessage, index, array) => {
  //   // If this is the first message in the array, show the avatar
  //   if (index === 0) {
  //     return true;
  //   }
  
  //   // Get the previous message
  //   const previousMessage = array[index - 1];
  
  //   // If the previous message has a different timestamp, show the avatar
  //   if (previousMessage.time !== currentMessage.time) {
  //     return true;
  //   }
  
  //   // If the timestamps are the same, do not show the avatar
  //   return false;
  // };
  
  const shouldShowAvatar = (currentMessage, previousMessage) => {
    
    if (!previousMessage) return true;
    if (previousMessage.time-currentMessage.time >60) {
      return true;
    }
  
    // If the timestamps are the same, do not show the avatar
    return false;
  
    // return (previousMessage.time - currentMessage.time) > 60;
  };
  
  
  
  

  const groupedMessages = groupMessagesByDate(messages);

const formatMessage = (message) => {
  if (message.includes("\r\n") || message.includes("\n")) {
    // Split by line breaks and render each line separately
    return message.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  }
  return message;
};

  return (
    <RefetchMessagesContext.Provider
      value={{ fetchUsersMessages, fetchGroupMessages }}
    >
      <ChatHeader />
      <div
        className=" overflow-auto"
        style={{ height: "calc(100vh - 210px)" }}
        ref={chatContainerRef}
      >
        {loading ? (
          <div className="spinner-container my-5">
            <Spinner />
          </div>
        ) : (
          <div>
            {Object.keys(groupedMessages).map((date) => (
              <div
                className="p-2 d-flex flex-column justify-content-end gap-2"
                key={date}
              >
                <div className="text-center text-secondary my-2">{date}</div>
                {groupedMessages[date].map((message, index, array) => (
                  <Message
                    key={message.id}
                    text={formatMessage(message.message)}
                    time={message.time}
                    isSender={ 
                     selectedChat.group_id===0?  message.from === splashData?.user.id:message.user_id===splashData?.user.id
                    }
                    attachment={message.attachment_type === "image" ? message.attachment : null}
                    showTime={shouldShowTime(message, array[index + 1])}
                    avatar={selectedChat.group_id!==0?message?.user?.image:""}
                    message={message}
                    isGroup={selectedChat.group_id!==0}
                    fullname={message?.user?.name}
                  
                    showAvatar={shouldShowAvatar(message, array[index+1])}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      <ChatInput  />
    </RefetchMessagesContext.Provider>
  );
}
