import { api } from "./api";
import { toast } from 'react-toastify';

export const getRecentSearchApi = async () => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/web/recent-search/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching recent search:', error);
        throw error;
    }
}

export const getSearchAgainstKeywordApi = async (keyword) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.post(`api/web/recent-search/keyword`, { user_id, keyword });
        return response.data;
    } catch (error) {
        toast.error('Error fetching search against keyword:', error);
        throw error;
    }
}

export const getAllSearchAgainstKeywordApi = async (keyword, type, pageParam) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.post(`api/web/recent-search?page=${pageParam || 1}`, { user_id, keyword, type });
        return response.data;
    } catch (error) {
        toast.error('Error fetching search against keyword:', error);
        throw error;
    }
}