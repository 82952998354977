import { Carousel } from "react-responsive-carousel";
import SearchedPostHeader from "./SearchedPostHeader";
import SearchResultCard from "./SearchResultCard";
import { LoginUserData, PostDetailContext } from "../../App";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio";
import VideoWithAspectRatio from "../common/VideowithAspectRatio.tsx";
import { BaseURL, RepostApi } from "../../constants/API";
import { Col, Row } from "reactstrap";
import { ReadMore } from "../../pages/Feed";
import PostFooter from "../feeds/PostFooter";
import axios from "axios";
import { toast } from "react-toastify";
import { likePostApi, savePostApi } from "../../services/feedApis.js";

export default function PostsResultsComp({ data, keyword, loading }) {
  return (
    <SearchResultCard
      title="Posts"
      link={`/search/posts?keyword=${keyword}`}
      seeAll={!loading && data?.length > 2}
    >
      <div>
        {data?.map((post, index) => (
          <PostsCard key={index} post={post} />
        ))}
      </div>
    </SearchResultCard>
  );
}

export const PostsCard = ({ post }) => {
  const { splashData } = useContext(LoginUserData);
  const [data, setData] = useState({});

  const handleRepost = async () => {
    try {
      setData({ ...data, isShare: !data.isShare });
      const response = await axios.get(
        `${RepostApi}${post?.id}/${splashData?.user?.id}`
      );
      if (response.data.status) {
        toast.success(response.data.action);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSave = async () => {
    try {
      setData({ ...data, isSave: !data.isSave });
      const response = await savePostApi(data.id);
      if (!response.data.status) {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLike = async () => {
    try {
      const updatedData = {
        ...data,
        isLike: !data.isLike,
        likes_count: data.isLike ? data.likes_count - 1 : data.likes_count + 1,
      };
      setData(updatedData);
      await likePostApi(data.id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setData(post);
  }, [post]);

  return (
    <div className="border border-inherit p-2">
      <SearchedPostHeader postDetails={data} />
      <PostCarousel post={data} />
      {data?.caption && (
        <>
          <Row className="p-2">
            <Col>
              <ReadMore text={data.caption} />
            </Col>
          </Row>
          <hr className="m-0" />
        </>
      )}
      <PostFooter
        likes_count={data?.likes_count}
        setPostId={() => {}}
        original_id={data?.original_id}
        handleLikeUsers={() => {}}
        like_users_images={data?.like_users_images}
        handleLike={handleLike}
        isLike={data?.isLike}
        handleGetComments={() => {}}
        setPostModal={() => {}}
        comments_count={data?.comments_count}
        handleRepost={handleRepost}
        isShare={data?.isShare}
        isSave={data?.isSave}
        handleSave={handleSave}
      />
    </div>
  );
};

export const PostCarousel = ({ post }) => {
  const { updatePostDetail } = useContext(PostDetailContext);
  const indicatorStyles = useMemo(
    () => ({
      background: "#fff",
      width: 20,
      height: 4,
      display: "inline-block",
      margin: "0 4px",
      borderRadius: "1px",
    }),
    []
  );
  return (
    <Row
      className="pt-3"
      onClick={() => {
        updatePostDetail(post);
      }}
    >
      <Col>
        {post.type === "video" ? (
          <>
            {post.media &&
              post.media.map((media) => (
                <VideoWithAspectRatio
                  src={BaseURL + media}
                  aspectRatio={post.sizes}
                />
              ))}
          </>
        ) : post.media && post.media.length === 1 ? (
          post.media.map((media, index) => (
            <ImageWithAspectRatio
              key={index}
              height={555 / post.sizes}
              src={BaseURL + media}
              alt="..."
            />
          ))
        ) : (
          <Carousel
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <IoIosArrowDroprightCircle
                  className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                  size={30}
                  color="white"
                  onClick={onClickHandler}
                />
              )
            }
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <IoIosArrowDropleftCircle
                  className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                  size={30}
                  style={{ zIndex: "1" }}
                  color="white"
                  onClick={onClickHandler}
                />
              )
            }
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <>
                  {isSelected ? (
                    <li
                      style={{
                        ...indicatorStyles,
                        background: "#00c5de",
                      }}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                    />
                  ) : (
                    <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    />
                  )}
                </>
              );
            }}
            autoPlay={false}
            infiniteLoop={false}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
          >
            {post.media &&
              post.media.map((data, index) => (
                <ImageWithAspectRatio
                  key={index}
                  height={555 / post.sizes}
                  src={BaseURL + data}
                  alt="..."
                />
              ))}
          </Carousel>
        )}
      </Col>
    </Row>
  );
};
