import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { Language, Sponsorpost } from "../../App";
import { CONTEST_LIST } from "../../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar";
import { CONTEST_DETAILS } from "../../constants/RoutesUrl";

// Individual contest item component
const ContestItem = ({ title, content }) => {
  const { postImage } = useContext(Sponsorpost);
  return (
    <Col md={12} className="border border-1 border-inherit rounded p-2">
      <h5 style={{ color: postImage?.color_code }} className="roboto-bold">
        {title}
      </h5>
      <p
        className="roboto-medium mb-0"
        dangerouslySetInnerHTML={{ __html: content }}
      ></p>
    </Col>
  );
};

// Contest details component
const ContestDetails = ({ details }) => (
  <Row className="justify-content-center gap-3 m-3">
    {details.map((item, index) => (
      <ContestItem key={index} {...item} />
    ))}
  </Row>
);

const NewContest = () => {
  const { lang } = useContext(Language);
  const Navigate = useNavigate();
  const { postImage } = useContext(Sponsorpost);
  const contestDetails = [
    {
      title: "SOBRE BRILLIANT MINDS",
      content:
        "Se trata de un concurso de casos clínicos individual con el objetivo de aprender y compartir conocimientos, así como fomentar la publicación y presentación de trabajos en las categorías de Glaucoma, Retina, Superficie y Oculoplastia.",
    },
    {
      title: "CALENDARIO",
      content:
        "- Fecha límite para participar: 10 de diciembre de 2024 a las 23:59 h. <br/> - Comunicación de los 3 finalistas de cada categoría: 1 de febrero de 2025 <br/> - Presentación de los casos clínicos finalistas: 21 de febrero a las 11h.",
    },
    {
      title: "PRESENTACIÓN DE LOS CASOS CLÍNICOS",
      content:
        "Presentación presencial de los casos clínicos finalistas en la sede de Brill Pharma, S.L. (C/ Munner, 8 de Barcelona). Retransmitida por streaming. <br/> El Jurado decidirá la clasificación de los ganadores in situ.",
    },
    {
      title: "3 PREMIOS* POR CATEGORÍA",
      content:
        "1er PREMIO 750€ <br/> 2º PREMIO 500€ <br/> 3er PREMIO 250€ <br/> *En concepto de formación",
    },
    {
      title: "REQUISITOS",
      content:
        "El contenido del caso clínico debe ser: <br/> - Oftalmológico <br/> - Anónimo <br/> - Original <br/> - Formato PDF (máximo 3 páginas) <br/> - Inédito <br/> - Contenido mínimo: título, descripción del caso, exploración y pruebas complementarias, resultados y conclusiones. <br/> <br/> Las bases completas del concurso están disponibles en la web de Brill Pharma (www.brillpharma.com).",
    },
    {
      title: "JURADO",
      content:
        "CATEGORÍA GLAUCOMA <br/> - Dr. Juan Carlos Pinto (Complejo Hospitalario de Navarra) <br/> - Dra. María Rojo (Hospital Virgen de la Luz) <br/> <br/> CATEGORÍA RETINA <br/> - Dra. Elena Rodríguez Neila (Hospital Juan Ramón Jiménez) <br/> - Dra. María Gómez (Hospital de Son Espases) <br/> <br/> CATEGORÍA SUPERFICIE <br/> - Dra. María Teresa Rodríguez (Complejo Hospitalario Universitario de Santiago) <br/> - Dra. Itziar Martínez-Soroa (Hospital Universitario Donostia) <br/> - Dra. Núria Ibáñez (Institut Català de Retina) <br/> - Dr. Marco Sales (IMO Grupo Miranza)",
    },
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "3rem" }}>
          <Col lg={6} className="py-3">
            <div className="br-16 bg-white py-3">
              <Row>
                <Col className="text-center">
                  <h5 className="mb-0 roboto-bold">CONCURSO BRILLIANT MINDS</h5>
                </Col>
              </Row>
              <ImageComponent
                src={postImage?.image}
                compClassName="img-fluid rounded"
                loader_height="405px"
              />
              <ContestDetails details={contestDetails} />
              <Col md={12} className="text-center">
                <button
                  className="btn-blue border-0 w-75 py-3"
                  style={{ backgroundColor: postImage?.color_code }}
                  onClick={() => {
                    Navigate(CONTEST_LIST);
                  }}
                >
                  {lang?.participate}
                </button>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewContest;
