import React, { useContext, useEffect, useState, useCallback } from "react";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link } from "react-router-dom";
import { BaseURL, FollowApi } from "../../constants/API";
import axios from "axios";
import { PROFILE } from "../../constants/RoutesUrl";
import { toast } from "react-toastify";
import ImageComponent from "../common/ImageComponent";
import { Language, LoginUserData } from "../../App";
import ProfileSuggestionModal from "../modals/ProfileSuggestionModal";
import { ShimmerCircularImage, ShimmerTitle } from "react-shimmer-effects";

const SuggestedProfiles = () => {
  const userToken = JSON.parse(localStorage.getItem("SignupData"));
  const user_id = userToken.data.id;

  const { lang } = useContext(Language);
  const { splashData } = useContext(LoginUserData);

  const [suggestionsData, setSuggestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const toggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, []);

  const handleFollow = useCallback(
    (id) => {
      setIsLoading(true);
      axios
        .get(`${FollowApi}${id}/${user_id}`)
        .then((res) => {
          if (res.data.status) {
            setSuggestionsData((prevSuggestions) =>
              prevSuggestions.map((profile) =>
                profile.id === id
                  ? { ...profile, follow: !profile.follow }
                  : profile
              )
            );
          } else {
            toast.error(res.data.error);
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    [user_id]
  );

  useEffect(() => {
    if (splashData?.user && splashData.home) {
      setSuggestionsData(splashData.home.suggestion);
      setSpinner(false);
    }
  }, [splashData]);

  return (
    <>
      <ProfileSuggestionModal
        preview={modalOpen}
        updatePreview={toggleModal}
        list={suggestionsData}
        updateList={setSuggestionsData}
        toFollow={handleFollow}
        loading={isLoading}
      />
      <div
        className="d-xl-block d-none mt-3 w-100"
        style={{ maxWidth: "335px" }}
      >
        <div
          className="bg-white"
          style={{
            border: "0.5px solid rgba(0,0,0,0.25)",
            borderRadius: "8px",
            height: "459px",
          }}
        >
          <div className="px-3 pt-4 pb-3 border-bottom">
            <h4 className="fs-16 roboto-bold">{lang?.people_suggestions}</h4>
          </div>

          {spinner ? (
            <>
              {[1, 2, 3, 4, 5].map((value, index) => (
                <React.Fragment key={index}>
                  <div className="d-flex p-2 justify-content-between manue-hover rounded">
                    <div className="d-flex gap-2 text-dark w-100">
                      <div
                        className="align-self-center"
                        style={{
                          width: "3rem",
                          height: "3rem",
                        }}
                      >
                        <ShimmerCircularImage size={48} />
                      </div>
                      <div className="align-self-center">
                        <p className="fs-14 roboto-bold suggest_name m-0">
                          <ShimmerTitle line={1} variant="secondary" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {index !== 4 && (
                    <hr className="my-0" style={{ opacity: "6%" }} />
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              {suggestionsData.slice(0, 5).map((value, index) => (
                <React.Fragment key={value.id}>
                  <div className="d-flex p-2 justify-content-between manue-hover rounded">
                    <Link
                      to={`${PROFILE}/${value.id}`}
                      className="d-flex gap-2 text-dark w-100"
                    >
                      <div
                        className="align-self-center"
                        style={{
                          maxWidth: "3rem",
                          maxHeight: "3rem",
                        }}
                      >
                        <ImageComponent
                          src={
                            value.image ? `${BaseURL}${value.image}` : Avatar
                          }
                          compClassName="rounded-circle"
                          img_height="48px"
                          img_width="48px"
                          loader_height="3rem"
                          loader_width="3rem"
                          roundShimmerSize={48}
                        />
                      </div>
                      <div className="align-self-center">
                        <p className="fs-14 roboto-medium suggest_name m-0">
                          {value?.name}
                        </p>
                      </div>
                    </Link>
                    <div className="align-self-center">
                      <button
                        disabled={isLoading}
                        className="text-white rounded border-0 px-3 py-1 follow-btn"
                        onClick={() => handleFollow(value.id)}
                      >
                        <span className="roboto-medium fs-14">
                          {value.follow ? lang?.following : lang?.follow}
                        </span>
                      </button>
                    </div>
                  </div>
                  {index !== 4 && (
                    <hr className="my-0" style={{ opacity: "6%" }} />
                  )}
                </React.Fragment>
              ))}
            </>
          )}

          <hr style={{ opacity: "25%" }} />
          <div className="text-center cursor pb-3">
            <h6
              className="fs-14 roboto-medium hover-blue view-more-btn m-0"
              onClick={toggleModal}
            >
              {lang?.view_all_recommendations}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuggestedProfiles;
