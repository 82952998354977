import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { IoIosMore } from "react-icons/io";

export default function LiveFeeds() {
  return (
    <>
      <div
        className="bg-white d-xl-block d-none my-3"
        style={{
          width: "335px",
          height: "649px",
          borderRadius: "8px",
          boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.05)",
          border: "0.5px solid rgba(0,0,0,0.25)",
        }}
      >
        <div className="p-3 border-bottom d-flex justify-content-between">
          <h4 className="fs-16 roboto-bold mb-0">Live Feeds</h4>
          <p className="fs-12 roboto-medium mb-0" style={{ color: "#8A93A1" }}>
            view all
          </p>
        </div>

        {categoriesArr.map((item, index) => (
          <div className={`pt-3 ${index !== 2 && "border-bottom"}`}>
            <div>
              <h6 className="px-3" style={{ color: item.color }}>
                {item.name}
              </h6>
            </div>
            <div>
              {[1, 2].map((val, i) => (
                <>
                  <LiveSectionCard borderColor={item.color} />
                  {i !== 1 && <hr className="m-0" />}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

const LiveSectionCard = ({ borderColor }) => {
  return (
    <div
      className="d-flex px-3 justify-content-between manue-hover rounded"
      style={{ paddingTop: "0.76rem", paddingBottom: "0.76rem" }}
    >
      <div className="d-flex gap-2 text-dark w-100">
        <div
          className="align-self-center rounded-circle"
          style={{
            maxWidth: "52px",
            border: `1px solid ${borderColor}`,
            maxHeight: "52px",
          }}
        >
          <ImageComponent
            src={Avatar}
            compClassName="rounded-circle"
            img_height="50px"
            img_width="50px"
            loader_height="50px"
            loader_width="50px"
            roundShimmerSize={48}
          />
        </div>
        <div className="align-self-center">
          <p className="fs-14 roboto-medium suggest_name mb-0">Dr. John Doe</p>
          <small className="roboto-regular fs-12">Just now</small>
        </div>
      </div>
      <div className="align-self-center">
        <IoIosMore color="#8A93A1" size={20} />
      </div>
    </div>
  );
};

const categoriesArr = [
  {
    id: 1,
    name: "Live Webinars",
    color: "rgba(255, 149, 0, 1)",
  },
  {
    id: 2,
    name: "Clinical Sessions",
    color: "rgba(255, 59, 48, 1)",
  },
  {
    id: 3,
    name: "Live Users",
    color: "rgba(0, 122, 255, 1)",
  },
];
