import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  AddEventQuestionApi,
  DeleteQuestionApi,
  EditEventQuestionsApi,
  EventDetailApi,
} from "../../constants/API";
import { useParams } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import { FcCancel } from "react-icons/fc";
import { FaPlus } from "react-icons/fa";
import SpinnerGif from "../../assets/images/Spinner.gif";
import { Language } from "../../App";
import { toast } from "react-toastify";

const EditQuestions = () => {
  const param = useParams();
  const event_id = param.id;
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;
  const { lang } = useContext(Language);
  const [questions, setQuestions] = useState([]);
  const [addQuestions, setAddQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [addQsModal, setAddQsModal] = useState(false);
  const [addQsOptModal, setAddQsOptModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [addQsInput, setAddQsInput] = useState("");
  const [addQsOptInput, setAddQsOptInput] = useState("");
  const [QsOpt, setQsOpt] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [EditOptionsModal, setEditOptionsModal] = useState(false);
  const [EditSavedOptionsModal, setEditSavedOptionsModal] = useState(false);

  const handleDelete = (question_id) => {
    setIsLoading(true);
    axios
      .get(DeleteQuestionApi + question_id)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setDeleteModal(false);
          setRefetch(!refetch);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddQuestions = () => {
    setIsLoading(true);
    // Raw Data
    const data = {
      event_id,
      questions: addQuestions,
    };
    axios
      .post(AddEventQuestionApi, data)
      .then((res) => {
        if (res.data.status) {
          setAddQuestions([]);
          setIsLoading(false);
          setAddQsModal(false);
          setRefetch(!refetch);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleEdit = () => {
    setIsLoading(true);
    var empty_array;
    if (
      questions[questionIndex]?.answers &&
      questions[questionIndex].answers.length > 0
    ) {
      empty_array = false;
    } else {
      empty_array = true;
    }

    const data = {
      question_id: questionId,
      question: questions[questionIndex]?.question,
      answers: questions[questionIndex]?.answers,
      empty_array,
    };
    axios.post(EditEventQuestionsApi, data).then((res) => {
      if (res.data.status) {
        setIsLoading(false);
        setEditModal(false);
      } else {
        toast.error(res.data.action);
      }
    });
  };

  const getQuestions = () => {
    setSpin(true);
    axios
      .get(EventDetailApi + event_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setQuestions(res.data.data.questions);
          setSpin(false);
        } else {
          setSpin(false);
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getQuestions();
  }, [refetch]);
  return (
    <>
      {spin ? (
        <Row>
          <Col xl={5} className="text-end pe-5">
            <img src={SpinnerGif} alt="picture" width={100} height={100} />
          </Col>
        </Row>
      ) : (
        <Col lg={8} className="overflowY-auto">
          <div className="bg-white p-3 br-14 border border-1 border-inherit">
            <Row className="justify-content-end">
              <Col md={7}>
                <div className="justify-content-between d-flex">
                  <div>
                    <h5>{lang?.event_questions}</h5>
                  </div>
                  <div className="me-1">
                    <HiPlus
                      size={25}
                      className="cursor"
                      onClick={() => {
                        setAddQsModal(true);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {questions.map((value, index) => (
              <div
                className="px-3 py-4 mt-3 border border-1 border-inherit rounded d-flex justify-content-between"
                key={value.id}
              >
                <div className="align-self-center">
                  <h6 className="mb-0">
                    {index + 1}. {value.question}
                  </h6>
                  {value.answers && value.answers.length > 0 && (
                    <div>
                      <small className="text-blue fw-bold">
                        {value.answers.length} {lang?.multiple_options_added}
                      </small>
                    </div>
                  )}
                </div>
                <div className="d-flex gap-3 align-self-center">
                  <div
                    className="py-1 px-2 rounded cursor bg-success"
                    onClick={() => {
                      setQuestionId(value.id);
                      setQuestionIndex(index);
                      setEditModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline size={20} color="white" />
                  </div>
                  <div
                    className="py-1 px-2 rounded cursor bg-danger"
                    onClick={() => {
                      setQuestionId(value.id);
                      setDeleteModal(true);
                    }}
                  >
                    <BsTrash color="white" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      )}

      {/* Add Questions Modal */}
      <Modal isOpen={addQsModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setAddQsModal(false)}>
          {lang?.add_question}
        </ModalHeader>
        <ModalBody>
          {addQuestions.map((value, index) => (
            <div className="mt-3">
              <Row>
                <Col>
                  <div className="mb-1 d-flex justify-content-between">
                    <label>
                      {lang?.question} # {index + 1}:
                    </label>
                    <div>
                      <small
                        className="text-blue fw-bold cursor"
                        onClick={() => {
                          setQuestionIndex(index);
                          setEditOptionsModal(true);
                        }}
                      >
                        {value.answers && value.answers.length > 0
                          ? value.answers.length + "Multiple Options"
                          : "Add Multiple Options"}{" "}
                        {value.answers && value.answers.length > 0 ? (
                          ""
                        ) : (
                          <span className="text-gray">{" (Optional)"}</span>
                        )}
                      </small>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={value.question}
                    onChange={(e) => {
                      const updatedQuestionsData = [...addQuestions];
                      updatedQuestionsData[index].question = e.target.value;
                      setAddQuestions(updatedQuestionsData);
                    }}
                  />
                </Col>
                <Col md={1} className="align-self-end mb-1">
                  <div
                    className="bg-grey p-1 rounded text-center cursor"
                    onClick={() => {
                      const updatedQuestionsData = [...addQuestions];
                      updatedQuestionsData.splice(index, 1);
                      setAddQuestions(updatedQuestionsData);
                    }}
                  >
                    <FcCancel size={20} />
                  </div>
                </Col>
              </Row>
            </div>
          ))}

          <div className="mt-3">
            <Row>
              <Col>
                <div className="mb-1 d-flex justify-content-between">
                  <label>Question:</label>
                  <div>
                    <small
                      className="text-blue fw-bold cursor"
                      onClick={() => {
                        setAddQsOptModal(true);
                      }}
                    >
                      {QsOpt && QsOpt.length > 0
                        ? QsOpt.length + "Multiple Options"
                        : "Add Multiple Options"}
                      <span className="text-gray">{" (Optional)"}</span>
                    </small>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Question"
                  value={addQsInput}
                  onChange={(e) => {
                    setAddQsInput(e.target.value);
                  }}
                />
              </Col>
              <Col md={1} className="align-self-end">
                <button
                  className="btn-blue border-0 px-3 py-2"
                  disabled={addQsInput === ""}
                  onClick={() => {
                    setAddQuestions([
                      ...addQuestions,
                      { question: addQsInput, answers: QsOpt },
                    ]);
                    setAddQsInput("");
                    setQsOpt([]);
                  }}
                >
                  <FaPlus />
                </button>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary rounded"
            onClick={() => {
              setAddQsModal(false);
              setAddQuestions([]);
              setAddQsInput("");
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2 rounded"
            disabled={isLoading}
            onClick={() => {
              handleAddQuestions();
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
      {/* End Add Question Modal */}

      {/* Add Questions Options Modal */}
      <Modal isOpen={addQsOptModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setAddQsOptModal(false)}>
          {lang?.add_ques_opt}
        </ModalHeader>
        <ModalBody>
          {QsOpt.map((value, index) => (
            <div className="mt-3">
              <div className="mb-1 d-flex justify-content-between">
                <label>
                  {lang?.option} # {index + 1}:
                </label>
              </div>
              <Row>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    value={value}
                    onChange={(e) => {
                      const updatedQuestionsData = [...QsOpt];
                      updatedQuestionsData[index] = e.target.value;
                      setQsOpt(updatedQuestionsData);
                    }}
                  />
                </Col>
                <Col md={1} className="align-self-center">
                  <div
                    className="bg-grey p-1 rounded text-center cursor"
                    onClick={() => {
                      const updatedQuestionsData = [...QsOpt];
                      updatedQuestionsData.splice(index, 1);
                      setQsOpt(updatedQuestionsData);
                    }}
                  >
                    <FcCancel size={20} />
                  </div>
                </Col>
              </Row>
            </div>
          ))}

          <div className="mt-3">
            <Row>
              <Col>
                <div className="mb-1 d-flex justify-content-between">
                  <label>{lang?.option}:</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={lang?.option}
                  value={addQsOptInput}
                  onChange={(e) => {
                    setAddQsOptInput(e.target.value);
                  }}
                />
              </Col>
              <Col md={1} className="align-self-end">
                <button
                  className="btn-blue border-0 px-3 py-2"
                  disabled={addQsOptInput === ""}
                  onClick={() => {
                    setQsOpt([...QsOpt, addQsOptInput]);
                    setAddQsOptInput("");
                  }}
                >
                  <FaPlus />
                </button>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary rounded"
            onClick={() => {
              setAddQsOptModal(false);
              setQsOpt([]);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 rounded px-4 py-2"
            onClick={() => {
              setAddQsOptModal(false);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
      {/* End Add Questions Options Modal */}

      {/* Edit Options of unsaved question */}
      <Modal isOpen={EditOptionsModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setEditOptionsModal(false)}>
          {lang?.add_ques_opt}
        </ModalHeader>
        <ModalBody>
          {addQuestions[questionIndex]?.answers &&
            addQuestions[questionIndex]?.answers.map((value, index) => (
              <div className="mt-3">
                <div className="mb-1 d-flex justify-content-between">
                  <label>
                    {lang?.option} # {index + 1}:
                  </label>
                </div>
                <Row>
                  <Col>
                    <input
                      type="text"
                      className="form-control"
                      value={value}
                      onChange={(e) => {
                        const updatedQuestionsData = [...addQuestions];
                        updatedQuestionsData[questionIndex].answers[index] =
                          e.target.value;
                        setAddQuestions(updatedQuestionsData);
                      }}
                    />
                  </Col>
                  <Col md={1} className="align-self-center">
                    <div
                      className="bg-grey p-1 rounded text-center cursor"
                      onClick={() => {
                        const updatedQuestionsData = [...addQuestions];
                        updatedQuestionsData[questionIndex].answers.splice(
                          index,
                          1
                        );
                        setAddQuestions(updatedQuestionsData);
                      }}
                    >
                      <FcCancel size={20} />
                    </div>
                  </Col>
                </Row>
              </div>
            ))}

          <div className="mt-3">
            <Row>
              <Col>
                <div className="mb-1 d-flex justify-content-between">
                  <label>{lang?.option}:</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Option"
                  value={addQsOptInput}
                  onChange={(e) => {
                    setAddQsOptInput(e.target.value);
                  }}
                />
              </Col>
              <Col md={1} className="align-self-end">
                <button
                  className="btn-blue border-0 px-3 py-2"
                  disabled={addQsOptInput === ""}
                  onClick={() => {
                    const updatedQuestionsData = [...addQuestions];
                    updatedQuestionsData[questionIndex]?.answers.push(
                      addQsOptInput
                    );
                    setAddQsOptInput("");
                  }}
                >
                  <FaPlus />
                </button>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary rounded"
            onClick={() => {
              setEditOptionsModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 rounded px-4 py-2"
            onClick={() => {
              setEditOptionsModal(false);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
      {/* End Edit Options of unsaved question */}

      {/* Edit Modal */}
      <Modal isOpen={editModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setEditModal(false)}>
          {lang?.edit_ques}
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="mb-1 d-flex justify-content-between">
              <label>{lang?.question}:</label>
              <div>
                <small
                  className="text-blue fw-bold cursor"
                  onClick={() => {
                    setEditSavedOptionsModal(true);
                  }}
                >
                  {questions[questionIndex]?.answers &&
                  questions[questionIndex]?.answers.length > 0
                    ? questions[questionIndex]?.answers.length +
                      lang?.multiple_option
                    : lang?.add_multiple_option}
                  <span className="text-gray">{" (Optional)"}</span>
                </small>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              value={questions[questionIndex]?.question}
            />
          </div>
        </ModalBody>
        <ModalFooter className="gap-3">
          <button
            className="btn btn-secondary rounded"
            onClick={() => setEditModal(false)}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2 rounded"
            onClick={() => {
              handleEdit();
            }}
          >
            {isLoading ? <Spinner size={"sm"} /> : lang?.save}
          </button>
        </ModalFooter>
      </Modal>
      {/* End Edit Modal */}

      {/* Edit Options of saved question */}
      <Modal isOpen={EditSavedOptionsModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setEditSavedOptionsModal(false)}>
          {lang?.add_ques_opt}
        </ModalHeader>
        <ModalBody>
          {questions[questionIndex]?.answers &&
            questions[questionIndex]?.answers.map((value, index) => (
              <div className="mt-3">
                <div className="mb-1 d-flex justify-content-between">
                  <label>
                    {lang?.option} # {index + 1}:
                  </label>
                </div>
                <Row>
                  <Col>
                    <input
                      type="text"
                      className="form-control"
                      value={value}
                      onChange={(e) => {
                        const updatedQuestionsData = [...questions];
                        updatedQuestionsData[questionIndex].answers[index] =
                          e.target.value;
                        setQuestions(updatedQuestionsData);
                      }}
                    />
                  </Col>
                  <Col md={1} className="align-self-center">
                    <div
                      className="bg-grey p-1 rounded text-center cursor"
                      onClick={() => {
                        const updatedQuestionsData = [...questions];
                        updatedQuestionsData[questionIndex].answers.splice(
                          index,
                          1
                        );
                        setQuestions(updatedQuestionsData);
                      }}
                    >
                      <FcCancel size={20} />
                    </div>
                  </Col>
                </Row>
              </div>
            ))}

          <div className="mt-3">
            <Row>
              <Col>
                <div className="mb-1 d-flex justify-content-between">
                  <label>{lang?.option}:</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={lang?.option}
                  value={addQsOptInput}
                  onChange={(e) => {
                    setAddQsOptInput(e.target.value);
                  }}
                />
              </Col>
              <Col md={1} className="align-self-end">
                <button
                  className="btn-blue border-0 px-3 py-2"
                  disabled={addQsOptInput === ""}
                  onClick={() => {
                    const updatedQuestionsData = [...questions];
                    updatedQuestionsData[questionIndex]?.answers.push(
                      addQsOptInput
                    );
                    setAddQsOptInput("");
                  }}
                >
                  <FaPlus />
                </button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
      {/* End Edit Options of saved question */}

      {/* Delete Modal */}
      <Modal isOpen={deleteModal} zIndex={9} centered>
        <div className="bg-white rounded p-4">
          <div>
            <h2 className="text-blue fs-24 fs-md-16 roboto-bold">
              {lang?.do_want_delete_ques}
            </h2>
            <p className="fs-18 fs-md-16 roboto-regular">
              {lang?.do_want_delete_ques_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="bg-white text-dark border-1 border rounded px-md-3 py-2"
              onClick={() => setDeleteModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded px-md-3 py-2"
              disabled={isLoading}
              onClick={() => handleDelete(questionId)}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.delete}
            </button>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal */}
    </>
  );
};

export default EditQuestions;
