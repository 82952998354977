import { GoDotFill } from "react-icons/go";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { BaseURL } from "../../constants/API";
import { useContext } from "react";
import { selectedChatContext } from "../../pages/chat/ChatModule";

export default function ChatUserCard({ chat }) {
  const { setSelectedChat,selectedChat } = useContext(selectedChatContext);
console.log("chat",chat)
console.log("selectedChat.id ",selectedChat )
  const handleClick = () => {
    setSelectedChat(chat);
  };

  const getImageSrc = () => {
    if (chat.group_id !== 0) {
      return chat.image ? BaseURL + chat.image : Avatar;
    }
    return chat.user.image ? BaseURL + chat.user.image : Avatar;
  };

  const truncateText = (text, length) => {
    return text.length > length ? `${text.substring(0, length)}...` : text;
  };

  const displayName = chat.group_name
    ? truncateText(chat.group_name, 15)
    : truncateText(chat.user.name, 15);

  const displayMessage = truncateText(chat.message, 15);
  // const isSelected = selectedChat && selectedChat?.user?.id === chat?.user?.id  
  // const isSelected = (selectedChat?.group_id !== 0 && selectedChat?.group_id === chat?.group_id) || 
  //                  (selectedChat?.group_id === 0 && selectedChat?.user?.id === chat?.user?.id);
  // const isSelected = (selectedChat.group_id !== 0 && selectedChat.group_id === chat.group_id) || 
  // (selectedChat.group_id === 0 && selectedChat.user.id === chat.user.id);
  const isSelected = (selectedChat?.group_id !== 0 && selectedChat?.group_id === chat?.group_id) || 
  (selectedChat?.group_id === 0 && selectedChat?.user?.id === chat?.user?.id && 
  selectedChat?.from === chat?.from && selectedChat?.to === chat?.to);

  console.log("isSelected",isSelected)
  return (
    <div
      // className="d-flex align-items-center gap-3 p-3 bg-white rounded border-bottom cursor"
      className={`d-flex align-items-center gap-3 p-3 rounded border-bottom cursor ${
        isSelected ? "selected-chat-bg" : "white"
      }`}
      // style={{
      //   backgroundColor: isSelected ? "rgba(0, 197, 222, 0.1)" : "white",
      // }}
      onClick={handleClick}
    >
      <div
        className="bg-white rounded-circle align-self-center"
        style={{
          padding: "2px",
          width: "61px",
          height: "61px",
          border: "1px solid rgba(29, 193, 221, 1)",
        }}
      >
        <ImageComponent
          src={getImageSrc()}
          img_height="100%"
          img_width="100%"
          loader_height="100%"
          loader_width="100%"
          compClassName="rounded-circle"
          roundShimmerSize={56}
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        <div>
          <h6
            className="roboto-medium fs-16"
            title={chat.group_name ? chat.group_name : chat.user.name}
          >
            {displayName}
          </h6>
          <div className="d-flex align-items-center gap-2">
            <p className="mb-0 roboto-regular fs-12" title={chat.message}>
              {displayMessage}
            </p>
            <GoDotFill size={5} />
            <p className="mb-0 roboto-regular fs-12">
              {FormatDateTime(chat.time).split(",")[0]}
            </p>
          </div>
        </div>
      </div>
      {chat.unread_count > 0 && (
        <div className="ms-auto">
          <GoDotFill size={20} color="#1Dc1dd" />
        </div>
      )}
    </div>
  );
}

export const FormatDateTime = (unixTime) => {
  const now = new Date();
  const date = new Date(unixTime * 1000);
  const diffTime = Math.abs(now - date);
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (isToday && diffHours < 24) {
    return date.toLocaleTimeString(undefined, options);
  } else if (diffHours >= 24 && diffHours < 48) {
    return "Yesterday";
  } else if (diffDays >= 2 && diffDays <= 7) {
    return date.toLocaleDateString(undefined, { weekday: "long" });
  } else {
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }
};
