import React, { useContext, useState } from "react";
import ImageComponent from "../common/ImageComponent.jsx";
import { PROFILE } from "../../constants/RoutesUrl.js";
import { BaseURL, DeletePostApi, EditPostApi } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Dots from "../../assets/images/icon/post-dropdown-dots.png";
import { Link } from "react-router-dom";
import { UnixToRelative } from "../notification/Notification.jsx";
import { Collapse, Modal, ModalBody, Spinner, ModalHeader } from "reactstrap";
import { FeedDataRefetch, Language } from "../../App.js";
import { toast } from "react-toastify";
import axios from "axios";

const PostHeader = ({
  postDetails,
  postDropDown,
  setPostDropDown,
  setReportModal,
  setDropDownId,
  dropDownId,
}) => {
  const { lang } = useContext(Language);
  const { updateRefetch } = useContext(FeedDataRefetch);

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;

  const [editPostInput, setEditPostInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeletePost = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${DeletePostApi}${dropDownId}/delete`);
      if (response.data.status) {
        toast.success("Post Deleted!");
        setDeleteModal(false);
        updateRefetch();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPost = async () => {
    setIsLoading(true);
    try {
      const editData = { caption: editPostInput, post_id: dropDownId };
      const response = await axios.post(EditPostApi, editData);
      if (response.data.status) {
        toast.success("Edited Successfully!");
        setEditModal(false);
        updateRefetch();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative px-3">
        <div className="d-flex gap-2">
          <div
            className="bg-white rounded-circle align-self-center"
            style={{
              padding: "2px",
              width: "50px",
              height: "50px",
              border: "1px solid rgba(29, 193, 221, 1)",
            }}
          >
            <Link to={PROFILE + "/" + postDetails?.user?.id}>
              <ImageComponent
                src={
                  postDetails?.user?.image
                    ? BaseURL + postDetails?.user?.image
                    : Avatar
                }
                img_height="100%"
                img_width="100%"
                loader_height="100%"
                loader_width="100%"
                compClassName="rounded-circle"
                roundShimmerSize={45}
              />
            </Link>
          </div>
          <div className="align-self-center">
            <div>
              <Link to={PROFILE + "/" + postDetails?.user?.id}>
                <h1 className="fs-16 roboto-bold hover-blue mb-0 text-ellips-162px">
                  {postDetails?.user?.name}
                </h1>
              </Link>
              <p
                className="mb-0 fs-12 roboto-regular"
                style={{
                  color: "#434343",
                  opacity: "80%",
                }}
              >
                Posted <UnixToRelative unixTimestamp={postDetails?.time} />
              </p>
            </div>
          </div>
        </div>
        <div
          className="manue-hover cursor d-flex justify-content-center align-items-center p-2 rounded"
          onClick={() => {
            setPostDropDown(!postDropDown);
            setDropDownId(postDetails?.original_id);
          }}
        >
          <img width={20} src={Dots} alt="..." />
        </div>
        {/* Post DropDown */}
        <div
          className="position-absolute w-25 end-0"
          style={{ zIndex: "9", marginTop: "5rem" }}
        >
          <Collapse
            isOpen={
              postDropDown && postDetails?.original_id === dropDownId
                ? true
                : false
            }
            className="bg-white rounded p-1 text-center"
            style={{
              boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
            }}
          >
            {postDetails?.user?.id !== user_id && (
              <h6
                className="mb-0 text-red p-2 rounded cursor fs-md-12 manue-hover"
                onClick={() => {
                  setReportModal(true);
                  setPostDropDown(false);
                }}
              >
                {lang?.report}
              </h6>
            )}
            {postDetails?.user?.id === user_id && (
              <>
                <h6
                  onClick={() => {
                    setDeleteModal(true);
                    setPostDropDown(false);
                  }}
                  className="mb-0 text-red p-2 rounded cursor fs-md-12 manue-hover"
                >
                  {lang?.delete}
                </h6>
                {postDetails?.user?.id === user_id && (
                  <h6
                    className="mb-0 text-red p-2 rounded cursor fs-md-12 manue-hover mt-1"
                    onClick={() => {
                      setEditPostInput(postDetails?.caption);
                      setEditModal(true);
                    }}
                  >
                    {lang?.edit}
                  </h6>
                )}
              </>
            )}
          </Collapse>
        </div>
        {/* End Post DropDown */}
      </div>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(false)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* End Delete Modal */}

      {/* Edit Modal */}
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(false)}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* End Edit Modal */}
    </>
  );
};

export default PostHeader;
