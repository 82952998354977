import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import NavBar from "../NavBar";
import axios from "axios";
import { BaseURL, LiveWebinarApi, ReserveApi } from "../../constants/API";
import { toast } from "react-toastify";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio";
import { PROFILE } from "../../constants/RoutesUrl";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { UnixToRelative } from "../notification/Notification.jsx";
import { Link } from "react-router-dom";
import { Language } from "../../App.js";
import { ConvertToTime } from "../../pages/Feed.jsx";

const LiveWebinars = () => {
  const { lang } = useContext(Language);
  const localData = JSON.parse(sessionStorage.getItem("profileData"));
  const user_id = localData?.user?.id;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleReserveWebinar = (post_id) => {
    const updatedData = data.map((value) => {
      if (value.id === post_id) {
        return { ...value, isReserve: !value.isReserve };
      }
      return value;
    });
    setData(updatedData);
    axios
      .get(ReserveApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFetch = () => {
    setLoading(true);
    axios
      .get(LiveWebinarApi + user_id)
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        } else {
          toast.error(res.data.action);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);
  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row className="justify-content-center" style={{ paddingTop: "5rem" }}>
        <Col md={6} className="bg-white br-16">
          <Row className="justify-content-center">
            <Col md={12} className="text-center">
              <h5 className="my-3">{lang?.live_webinars}</h5>
            </Col>
            {loading ? (
              <Spinner className="my-3" />
            ) : (
              <>
                {data.map((value) => (
                  <>
                    <div
                      key={value.id}
                      className="bg-white br-6 my-3 py-3"
                      style={{
                        border: "0.5px solid rgba(0,0,0,0.25)",
                        maxWidth: "579px",
                      }}
                    >
                      <Row className="justify-content-between px-3 position-relative">
                        <Col md={10} className="d-flex gap-3">
                          <div
                            className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                            style={{
                              maxHeight: "4rem",
                              maxWidth: "4rem",
                            }}
                          >
                            <Link to={PROFILE + "/" + value.user.id}>
                              <img
                                src={
                                  value.user.image
                                    ? BaseURL + value.user.image
                                    : Avatar
                                }
                                alt="picture"
                                className="br-16 img-fluid"
                              />
                            </Link>
                          </div>
                          <div className="align-self-end ">
                            <Link to={PROFILE + "/" + value.user.id}>
                              <h1 className="fs-16 roboto-medium text-dark">
                                {value?.user?.name}
                              </h1>
                            </Link>
                            <span className="fs-12 roboto-regular">
                              <UnixToRelative unixTimestamp={value?.time} />
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className="pt-3 pb-2">
                        <Col>
                          {value.media.map((value) => (
                            <ImageWithAspectRatio
                              height={555 / value.sizes}
                              src={BaseURL + value}
                              alt="..."
                            />
                          ))}
                        </Col>
                      </Row>
                      {value?.caption || value?.title ? (
                        <>
                          <Row className="px-3 py-2">
                            <Col>
                              <h4>
                                {value?.title && value.title.length > 26
                                  ? value.title.slice(0, 26) + "..."
                                  : value.title}
                              </h4>
                              <p className="fs-16 roboto-regular">
                                {value?.caption && value.caption.length > 100
                                  ? value.caption.slice(0, 100) + "..."
                                  : value.caption}
                              </p>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                      <Row className="px-3 justify-content-between">
                        <Col className="align-self-center">
                          <p className="mb-0 text-warning">
                            {lang?.scheduled_on}
                          </p>
                          <small className="mb-0">
                            {value?.scheduled}
                            {", "}
                            <ConvertToTime tstamp={value.start_timestamp} />
                          </small>
                        </Col>
                        <Col className="text-end align-self-center">
                          {value.status !== 0 && (
                            <button
                              className="btn btn-warning fw-bold text-white px-4 py-2"
                              onClick={
                                value.user.id === user_id
                                  ? value.status === 0
                                    ? null
                                    : value.status === 2
                                    ? lang?.webinar_ended
                                    : () => {
                                        if (value.user.id === user_id) {
                                          // sessionStorage.setItem(
                                          //   "webinar",
                                          //   JSON.stringify({
                                          //     user_id: value.user.id,
                                          //     title: value.title,
                                          //     post_id: value.id,
                                          //     url: value.meeting_host_url,
                                          //   })
                                          // );
                                          window.open(
                                            value.meeting_host_url,
                                            "_blank"
                                          );
                                        } else {
                                          // sessionStorage.setItem(
                                          //   "webinar",
                                          //   JSON.stringify({
                                          //     user_id: value.user.id,
                                          //     title: value.title,
                                          //     post_id: value.id,
                                          //     url: value.meeting_url,
                                          //   })
                                          // );
                                          window.open(
                                            value.meeting_url,
                                            "_blank"
                                          );
                                        }
                                        // navigate("/webinar");
                                      }
                                  : value.status === 0
                                  ? value.isReserve
                                    ? () => handleReserveWebinar(value.id)
                                    : () => handleReserveWebinar(value.id)
                                  : value.status === 1
                                  ? value.isReserve
                                    ? () => {
                                        if (value.user.id === user_id) {
                                          // sessionStorage.setItem(
                                          //   "webinar",
                                          //   JSON.stringify({
                                          //     user_id: value.user.id,
                                          //     title: value.title,
                                          //     post_id: value.id,
                                          //     url: value.meeting_host_url,
                                          //   })
                                          // );
                                          window.open(
                                            value.meeting_host_url,
                                            "_blank"
                                          );
                                        } else {
                                          if (value.meeting_url !== "") {
                                            // sessionStorage.setItem(
                                            //   "webinar",
                                            //   JSON.stringify({
                                            //     user_id: value.user.id,
                                            //     title: value.title,
                                            //     post_id: value.id,
                                            //     url: value.meeting_url,
                                            //   })
                                            // );
                                            // navigate("/webinar");
                                            window.open(
                                              value.meeting_url,
                                              "_blank"
                                            );
                                          } else {
                                            toast.info("Not started yet.");
                                          }
                                        }
                                      }
                                    : () => {
                                        handleReserveWebinar(value.id);
                                      }
                                  : () => {
                                      alert(lang?.webinar_is_ended);
                                    }
                              }
                            >
                              {value.user.id === user_id
                                ? value.status === 0
                                  ? "Not Started Yet"
                                  : value.status === 2
                                  ? lang?.webinar_ended
                                  : lang?.start_webinar
                                : value.status === 0
                                ? value.isReserve
                                  ? lang?.unreserve
                                  : lang?.reserve_seat
                                : value.status === 1
                                ? value.isReserve
                                  ? lang?.join_webinar
                                  : lang?.reserve_seat
                                : lang?.webinar_ended}
                            </button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                ))}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LiveWebinars;
