import React, { useCallback, useContext, useEffect, useState } from "react";
import UploadIcon from "../../assets/images/icon/upload.png";
import { Col, Form, Modal, ModalFooter, ModalHeader, Row } from "reactstrap";
import Spinner from "../Spinner";
import Cropper from "react-easy-crop";
import { BaseURL, DetailCourseApi, EditCourseApi } from "../../constants/API";
import axios from "axios";
import getCroppedImg from "../../hooks/useCropImage";
import { Language } from "../../App";
import { toast } from "react-toastify";

const EditCourse = () => {
  // sessionStorages data
  const course_id = sessionStorage.getItem("CourseId");
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;
  // States
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [data, setData] = useState({});
  const [courseImagePreview, setCourseImagePreview] = useState(null);
  const [courseInput, setCourseInput] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const { lang } = useContext(Language);
  // Functions
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropper = async () => {
    try {
      const croppedImage = await getCroppedImg(
        courseImagePreview,
        croppedAreaPixels
      );
      // Replace the Blob URL with your actual Blob URL
      const blobUrl = croppedImage;
      // Fetch the Blob content
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            setCroppedImage(base64Data);
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.subcategory_id || data.subcategory_id === "") {
      toast.error(lang?.select_course_subcategory_please);
      return;
    }

    setIsLoading(true);
    axios
      .post(
        EditCourseApi,
        {
          course_id,
          title: data.title,
          short_description: data.short_description,
          price: data.price,
          category: "3",
          subcategory: data.subcategory_id,
          cover_base64: croppedImage,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleData = () => {
    setSpin(true);
    axios
      .get(DetailCourseApi + course_id + "/" + user_id)
      .then((res) => {
        setData(res.data.data);
        setSpin(false);
      })
      .catch((error) => {
        console.log(error);
        setSpin(false);
      });
  };

  useEffect(() => {
    handleData();
  }, []);
  return (
    <>
      <Row>
        {spin ? (
          <div className="ticketchatspinner">
            <Spinner />
          </div>
        ) : (
          <Col
            lg={7}
            className="bg-white rounded p-4 ms-lg-0 ms-md-0 ms-sm-0 ms-0"
          >
            <Row className="pb-3">
              <Col className="text-center">
                <h3 className="fs-20 fs-md-16 roboto-bold">
                  {lang?.edit_course}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                {data.cover || croppedImage ? (
                  <div
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      minHeight: "15rem",
                      borderRadius: "6px",
                      backgroundColor: "rgba(0,0,0,0.70)",
                    }}
                  >
                    <button
                      className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                      style={{ zIndex: "1" }}
                      onClick={() => {
                        const update = { ...data, cover: "" };
                        setData(update);
                        setCourseImagePreview("");
                        setCroppedImage("");
                      }}
                    >
                      x
                    </button>
                    <img
                      src={
                        data.cover
                          ? BaseURL + data.cover
                          : croppedImage
                          ? croppedImage
                          : ""
                      }
                      className="w-100"
                      style={{ objectFit: "cover", height: "15rem" }}
                      onClick={() => setImagePreviewModal(true)}
                      alt="picture"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      border: "1px dashed #A2A2A2",
                      backgroundColor: "#F2FCFE",
                      minHeight: "12rem",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      document.getElementById("fileInput").click();
                    }}
                  >
                    <div className="text-center mt-5">
                      <img src={UploadIcon} width={40} height={40} alt="picture" />
                      <h4
                        className="fs-14 roboto-medium pt-3"
                        style={{ color: "#4470FF" }}
                      >
                        {lang?.add_course_img}
                      </h4>
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  id="fileInput"
                  className="d-none"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setCourseInput(file);
                    // Preview the image
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setCourseImagePreview(reader.result);
                      setImageModal(true);
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </Col>
            </Row>
            <Form className="mt-3">
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-16 roboto-medium">
                    {lang?.course_title}
                  </label>
                  <input
                    type="text"
                    className="py-2 form-control"
                    value={data?.title}
                    onChange={(e) => {
                      const update = { ...data, title: e.target.value };
                      setData(update);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-16 roboto-medium">
                    {lang?.course_category}
                  </label>
                  <select
                    className="form-select py-2"
                    value={data.subcategory_id}
                    onChange={(e) => {
                      const update = {
                        ...data,
                        subcategory_id: e.target.value,
                      };
                      setData(update);
                    }}
                  >
                    {user.home.subcategories.map((value, index) => (
                      <option value={value.id} key={index}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-16 roboto-medium">
                    {lang?.course_price}
                  </label>
                  <input
                    type="number"
                    className="py-2 form-control"
                    value={data.price}
                    onChange={(e) => {
                      const update = { ...data, price: e.target.value };
                      setData(update);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <label className="pb-1 fs-18 fs-md-16 roboto-medium">
                    {lang?.description}
                  </label>
                  <textarea
                    value={data.short_description}
                    onChange={(e) => {
                      const update = {
                        ...data,
                        short_description: e.target.value,
                      };
                      setData(update);
                    }}
                    className="form-control"
                    cols="30"
                    rows="8"
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    disabled={
                      isLoading ||
                      !data.title ||
                      data.title.length > 150 ||
                      !data.short_description ||
                      !data.price ||
                      data.price > 999.99 ||
                      !data.subcategory
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    className="btn-blue border-0 w-100 my-4 py-3"
                  >
                    {isLoading ? (
                      <div className="loader"></div>
                    ) : (
                      lang?.edit_course
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
      </Row>
      {/* image cropping  */}
      <Modal isOpen={imageModal} centered zIndex={9}>
        <div className="bg-white px-3 rounded">
          <ModalHeader toggle={() => setImageModal(!imageModal)}>
            {lang?.image_croper}
          </ModalHeader>
          <div className="position-relative" style={{ minHeight: "40rem" }}>
            <Cropper
              image={
                courseImagePreview ? courseImagePreview : BaseURL + data.cover
              }
              crop={crop}
              zoom={zoom}
              aspect={0.86}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="d-none">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
          <ModalFooter className="gap-3">
            <button
              className="btn btn-secondary border-0 px-4 py-2"
              onClick={() => setImageModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue border-0 px-4 py-2"
              onClick={() => {
                handleCropper();
                setImageModal(false);
              }}
            >
              {lang?.save}
            </button>
          </ModalFooter>
        </div>
      </Modal>

      {/* cropped image preview */}
      <Modal
        isOpen={imagePreviewModal}
        centered
        zIndex={9}
        toggle={() => {
          setImagePreviewModal(!imagePreviewModal);
        }}
      >
        <img
          src={croppedImage ? croppedImage : BaseURL + data.cover}
          alt="picture"
          className="img-fluid"
        />
      </Modal>
    </>
  );
};

export default EditCourse;
