import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import CreateCourseCard from "../../components/courses/CreateCourseCard";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import SuggestedCoursesEvents from "../../components/feeds/SuggestedCoursesEvents";
import CourseBanner from "../../components/courses/CourseBanner";
// banner images
import banner_1 from "../../assets/images/icons/course_banner_1.jpg";
import banner_2 from "../../assets/images/icons/course_banner_2.jpg";
import banner_3 from "../../assets/images/icons/course_banner_3.jpg";
import CoursesTabMenuCard from "../../components/courses/CoursesTabMenuCard";
import { fetchCoursesData } from "../../services/coursesApi";
import { toast } from "react-toastify";
import TrendingCourses from "../../components/courses/TrendingCourses";
import SubcategoryCards from "../../components/courses/SubcategoryCards";
import FollowingDoctorCourses from "../../components/courses/FollowingDoctorCourses";
import RecommendedCourses from "../../components/courses/RecommendedCourses";
import PurchasedCourses from "../../components/courses/PurchasedCourses";
import SavedCourses from "../../components/courses/SavedCourses";
import MyCourses from "../../components/courses/MyCourses";
import { useQuery } from "react-query";

const banner_images = [banner_1, banner_2, banner_3];

const courses_types = [
  {
    id: 1,
    title: "Purchased",
    key: "purchased",
  },
  {
    id: 2,
    title: "Saved",
    key: "saved",
  },
  {
    id: 3,
    title: "My Created",
    key: "created",
  },
];

const Courses = () => {
  const [selectedCourseType, setSelectedCourseType] = useState(null);
  const [counts, setCounts] = useState({
    purchased: 0,
    saved: 0,
    created: 0,
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["coursesData"],
    queryFn: () => fetchCoursesData(),
    onSuccess: (data) => {
      setCounts({
        purchased: data?.home?.purchased_count,
        saved: data?.home?.saved_count,
        created: data?.home?.user_created_count,
      });
    },
  });

  if (isError) {
    toast.error("Error fetching courses data");
  }

  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row>
        <div
          className="d-flex justify-content-center gap-5 pb-3"
          style={{ paddingTop: "5rem" }}
        >
          <div style={{ width: "335px" }}>
            <CreateCourseCard />
            <FeedAdvertisement ads={data?.ads.courses} />
            <SuggestedCoursesEvents
              type="courses"
              purchasedCourses={data?.side?.purchased}
              popularCourses={data?.side?.popular}
            />
          </div>
          <div style={{ width: "922px" }}>
            <CourseBanner banner_images={banner_images} />
            {/* Tabs */}
            <Row className="justify-content-between gap-2 my-3">
              {courses_types.flatMap((course_type) => (
                <Col key={course_type.id}>
                  <CoursesTabMenuCard
                    active={course_type.id === selectedCourseType}
                    onClick={() => {
                      if (course_type.id === selectedCourseType) {
                        setSelectedCourseType(null);
                      } else {
                        setSelectedCourseType(course_type.id);
                      }
                    }}
                    title={course_type.title}
                    count={counts[course_type.key]}
                  />
                </Col>
              ))}
            </Row>
            {/* end of tabs */}

            {selectedCourseType ? (
              <>
                <div className="my-3">
                  <h4 className="mb-0 fs-20 roboto-bold">
                    {courses_types.find(
                      (course_type) => course_type.id === selectedCourseType
                    ).title + " Courses"}
                  </h4>
                </div>
                {selectedCourseType === 1 ? (
                  <PurchasedCourses count={counts.purchased} />
                ) : selectedCourseType === 2 ? (
                  <SavedCourses count={counts.saved} />
                ) : selectedCourseType === 3 ? (
                  <MyCourses count={counts.created} />
                ) : null}
              </>
            ) : (
              <>
                <TrendingCourses
                  courses={data?.home?.trending}
                  loading={isLoading}
                />
                <SubcategoryCards categories={data?.home?.subcategories} />
                <FollowingDoctorCourses
                  followingDoctorCourses={data?.home?.following}
                  loading={isLoading}
                />
                <RecommendedCourses />
              </>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Courses;
