import { Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import SearchHashtags from "../../components/search/SearchHashtags";
import { Link, useLocation } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../../components/common/ImageComponent";
import { Filter } from "./Filter";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import { getAllSearchAgainstKeywordApi } from "../../services/searchApis";
import { useInfiniteQuery } from "react-query";
import { BaseURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { ShimmerCircularImage, ShimmerTitle } from "react-shimmer-effects";
import { useContext, useEffect, useState } from "react";
import { followUserApi } from "../../services/feedApis";
import { LoginUserData } from "../../App";

export default function DoctorsSearch() {
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  const { splashData } = useContext(LoginUserData);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["search-doctors", keyword],
      queryFn: ({ pageParam = 1 }) =>
        getAllSearchAgainstKeywordApi(keyword, "doctors", pageParam),
      enabled: !!keyword,
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const doctors = data?.pages?.flatMap((page) => page.data.data);
  const ads = data?.pages?.flatMap((page) => page.ads);
  const hashTags = data?.pages?.flatMap((page) => page.hash_tags);

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
          <div className="position-relative px-0">
            <Filter
              title="Doctors"
              options={[
                { title: "Category", options: splashData?.home?.categories },
                {
                  title: "Subcategory",
                  options: splashData?.home?.subcategories,
                },
                { title: "Hospital", options: [] },
                { title: "City", options: [] },
              ]}
            />
          </div>
        </Row>
        <div className="d-flex justify-content-center gap-3">
          <div>
            <div
              className="position-sticky"
              style={{ width: "335px", top: "8.2rem" }}
            >
              <FeedAdvertisement ads={ads} />
            </div>
          </div>
          <div>
            <DoctorsList
              loading={isLoading}
              data={doctors}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
          <div className="d-flex flex-column gap-3" style={{ width: "335px" }}>
            <SearchHashtags data={hashTags} />
            <FeedAdvertisement ads={ads} />
          </div>
        </div>
      </Container>
    </>
  );
}

const DoctorsList = ({
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  loading,
}) => {
  const [searchData, setSearchData] = useState(data);
  const handleFollow = async (id) => {
    const isFollowed = searchData?.find((item) => item?.id === id)?.follow;
    try {
      const response = await followUserApi(id);
      if (response.status) {
        const updatedData = searchData?.map((item) => {
          if (item?.id === id) {
            return { ...item, follow: !isFollowed };
          }
          return item;
        });
        setSearchData(updatedData);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    setSearchData(data);
  }, [data]);

  return (
    <div
      className="bg-white border rounded"
      style={{ marginTop: "1rem", width: "570px" }}
    >
      <div
        className="p-3"
        style={{ borderBottom: "1px solid rgba(29, 193, 221, 0.2)" }}
      >
        <h5 className="mb-0 fs-16 roboto-bold">Doctors</h5>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (!isFetchingNextPage) {
            fetchNextPage();
          }
        }}
        hasMore={hasNextPage}
        loader={
          <div className="d-flex px-2 pb-4 justify-content-between manue-hover rounded">
            <div className="d-flex gap-4 text-dark w-100">
              <div
                className="align-self-center"
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
              >
                <ShimmerCircularImage size={60} />
              </div>
              <div className="align-self-center">
                <p className="fs-14 roboto-bold suggest_name m-0">
                  <ShimmerTitle line={1} variant="secondary" />
                </p>
              </div>
            </div>
          </div>
        }
      >
        <div className="p-2">
          {loading ? (
            <>
              {[1, 2, 3, 4, 5].map((_, index) => (
                <div
                  className="d-flex px-2 pb-4 justify-content-between manue-hover rounded"
                  key={index}
                >
                  <div className="d-flex gap-4 text-dark w-100">
                    <div
                      className="align-self-center"
                      style={{
                        width: "3rem",
                        height: "3rem",
                      }}
                    >
                      <ShimmerCircularImage size={60} />
                    </div>
                    <div className="align-self-center">
                      <p className="fs-14 roboto-bold suggest_name m-0">
                        <ShimmerTitle line={1} variant="secondary" />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {searchData?.map((item, key) => (
                <div
                  className="d-flex mb-3 justify-content-between manue-hover rounded"
                  key={key}
                >
                  <Link
                    to={`${PROFILE}/${item?.id}`}
                    className="d-flex gap-3 text-dark w-100"
                  >
                    <div
                      className="bg-white rounded-circle align-self-center"
                      style={{
                        padding: "2px",
                        width: "66px",
                        height: "66px",
                        border: "1px solid rgba(29, 193, 221, 1)",
                      }}
                    >
                      <ImageComponent
                        src={
                          item?.image !== "" ? BaseURL + item?.image : Avatar
                        }
                        img_height="100%"
                        img_width="100%"
                        loader_height="100%"
                        loader_width="100%"
                        compClassName="rounded-circle"
                        roundShimmerSize={60}
                      />
                    </div>
                    <div className="align-self-center">
                      <p className="fs-16 roboto-medium m-0">{item?.name}</p>
                      <p className="fs-12 text-muted roboto-medium m-0">
                        {item?.username}
                      </p>
                    </div>
                  </Link>
                  <div className="align-self-center">
                    <button
                      className="text-white rounded border-0 px-3 py-1 follow-btn"
                      onClick={() => handleFollow(item?.id)}
                    >
                      <span className="roboto-medium fs-14">
                        {item?.is_following ? "Unfollow" : "Follow"}
                      </span>
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};
