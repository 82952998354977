import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Language } from "../../App";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import { IoIosArrowForward } from "react-icons/io";
import { PROFILE } from "../../constants/RoutesUrl";

const GroupParticipantsModal = ({ isOpen, toggle }) => {
  const { lang } = useContext(Language);
  return (
    <Modal isOpen={isOpen} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{lang?.participants_list}</ModalHeader>
      <ModalBody>
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="d-flex p-2 justify-content-between manue-hover rounded">
                <Link
                  to={`${PROFILE}/1`}
                  className="d-flex gap-2 text-dark w-100"
                >
                  <div
                    className="bg-white rounded-circle align-self-center"
                    style={{
                      padding: "2px",
                      width: "48px",
                      height: "48px",
                      border: "1px solid #1DC1DD",
                    }}
                  >
                    <ImageComponent
                      src={Avatar}
                      img_height="100%"
                      img_width="100%"
                      loader_height="100%"
                      loader_width="100%"
                      compClassName="rounded-circle"
                      roundShimmerSize={48}
                    />
                  </div>

                  <div className="align-self-center">
                    <p className="fs-14 roboto-medium mb-0">
                      John Doe{" "}
                      <span
                        className="roboto-medium fs-12 ms-2"
                        style={{ color: "#1DC1DD" }}
                      >
                        (Admin)
                      </span>
                    </p>
                    <p className="roboto-regular fs-10 mb-0">john_doe</p>
                  </div>
                </Link>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GroupParticipantsModal;
