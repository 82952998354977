import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";

export function Filter({ title, options }) {
  const navigate = useNavigate();
  const query = useLocation().search;
  const keyword = decodeURIComponent(query.split("=")[1]);
  return (
    <div className="position-relative" style={{ zIndex: 1 }}>
      <div className="position-sticky top-0">
        <div
          className="d-flex gap-3 justify-content-center bg-white pb-2"
          style={{
            paddingTop: "4rem",
            boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.05)",
            width: "100%",
          }}
        >
          <div className="border-end pe-3">
            <button
              type="button"
              className="rounded px-4 py-2 roboto-bold border border-1 border-inherit text-white"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={() => {
                navigate(`/search?keyword=${keyword}`);
              }}
            >
              {title}
            </button>
          </div>
          <div className="d-flex gap-3">
            {options &&
              options?.map((option, i) => (
                <DropDownFilter
                  key={i}
                  title={option.title}
                  options={option.options}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function DropDownFilter({ title, options }) {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".position-relative")) {
        setShow(false);
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [show, isActive]);
  return (
    <>
      <div className="position-relative">
        <button
          type="button"
          onClick={() => {
            setIsActive(!isActive);
            setShow(!show);
          }}
          className="d-flex align-items-center gap-2 rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
        >
          {title} <IoIosArrowDown size={18} />
        </button>
        {show && (
          <div className="position-absolute" style={{ zIndex: "2" }}>
            <div
              className="bg-white mt-2 p-3 rounded border border-1 border-inherit"
              style={{ width: "366px" }}
            >
              <div className="position-relative">
                <IoIosSearch
                  size={20}
                  className="position-absolute my-auto top-0 bottom-0 m-2 left-0 text-secondary outline-none"
                />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  className="form-control text-secondary"
                  style={{
                    backgroundColor: "rgba(247, 247, 247, 1)",
                    paddingLeft: "2rem",
                  }}
                />
              </div>
              <div className="py-3">
                <h6 className="roboto-medium fs-14 text-secondary mb-0">
                  {title}
                </h6>
              </div>
              <div>
                {options &&
                  options?.map((val, index) => (
                    <p key={index} className="fs-14 cursor roboto-regular mb-0 py-2">
                      {val?.name}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {isActive && (
        <div className="overlay" style={{ zIndex: "1", top: "7rem" }}></div>
      )}
    </>
  );
}
