// import { BaseURL } from "../../constants/API";
import Categoryicon from "../../assets/images/icons/category_icon.svg";
export default function SubcategoryCards({ categories }) {
  return (
    <>
      <div className="mb-3">
        <h4 className="mb-0 fs-20 roboto-bold">Explore Course Categories</h4>
      </div>
      <div className="w-100 overflow-x-scroll scroll-hide">
        <div className="d-flex gap-2 my-2">
          {categories?.map((category) => (
            <CategoryCard key={category.id} category={category} />
          ))}
        </div>
        <div className="d-flex gap-2 my-2">
          {categories?.reverse()?.map((category) => (
            <CategoryCard key={category.id} category={category} />
          ))}
        </div>
      </div>
    </>
  );
}

const CategoryCard = ({ category }) => {
  return (
    <div
      className="bg-white br-8 shadow-sm p-3 cursor"
      style={{ width: "fit-content" }}
    >
      <div className="d-flex align-items-center gap-3">
        {/* <div
          style={{ backgroundColor: "#F2F2F7", width: "42px", height: "42px" }}
          className="d-flex align-items-center justify-content-center rounded-circle p-2"
        >
          <img src={BaseURL + category.image} className="img-fluid" alt="..." />
        </div> */}
        <img src={Categoryicon} width={42} height={42} alt="..." />
        <div>
          <h4
            className="mb-1 fs-12 roboto-bold"
            style={{ whiteSpace: "nowrap" }}
          >
            {category.name}
          </h4>
          <p
            style={{ whiteSpace: "nowrap" }}
            className="mb-0 fs-12 roboto-medium text-muted"
          >
            {category.courses_count} Courses
          </p>
        </div>
      </div>
    </div>
  );
};
