import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import NavBar from "../NavBar";
import { useNavigate } from "react-router-dom";
import { CONTEST_DETAILS } from "../../constants/RoutesUrl";
import Retina from "../../assets/images/retina.jpg";
import Superficie from "../../assets/images/superficie.jpg";
import Glaucoma from "../../assets/images/glaucoma.jpg";
import Oculoplastia from "../../assets/images/oculoplastia.jpg";
import { Language } from "../../App";

const ContestList = () => {
  const { lang } = useContext(Language);
  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "3rem" }}>
          <Col lg={6} className="py-3">
            <div className="br-16 bg-white p-3">
              <Row>
                <Col className="text-center">
                  <h5 className="mb-0 roboto-bold">
                    {lang?.briliant_mind_competion}
                  </h5>
                </Col>
              </Row>
              <Row className="justify-content-center">
                {listData.map((data) => (
                  <ContestCard Image={data.image} type={data.type} />
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContestList;

const ContestCard = (props) => {
  const redirect = useNavigate();
  const { lang } = useContext(Language);
  const { Image, type } = props;
  return (
    <>
      <Col lg={6} className="my-3">
        <div>
          <div>
            <img
              src={Image}
              alt="picture"
              className="img-fluid"
              style={{ borderRadius: "12px 12px 0px 0px" }}
            />
          </div>
          <div
            className="text-center cursor"
            style={{
              borderRadius: "0px 0px 12px 12px",
              backgroundColor: "#2D3155",
            }}
            onClick={() => {
              redirect(CONTEST_DETAILS + type);
            }}
          >
            <h6 className="mb-0 py-2 text-white">{lang?.participate}</h6>
          </div>
        </div>
      </Col>
    </>
  );
};

const listData = [
  { image: Retina, type: "RETINA" },
  { image: Superficie, type: "SUPERFICIE" },
  { image: Glaucoma, type: "GLAUCOMA" },
  { image: Oculoplastia, type: "OCULOPLASTIA" },
];
