
import { useContext, useState, useRef, useEffect } from "react";
import { LuImagePlus, LuSmile } from "react-icons/lu";
import { LoginUserData } from "../../App";
import { FiSend } from "react-icons/fi";
import {  selectedChatContext } from "../../pages/chat/ChatModule";
import { toast } from "react-toastify";
import { BaseURL } from "../../constants/API";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import {  RefetchMessagesContext } from "./Chats";
import { ChatsContext } from "./ChatsProvider";

export default function ChatInput() {
  const { splashData } = useContext(LoginUserData);
  const { selectedChat } = useContext(selectedChatContext);
const { updateChatMessage ,setChats} = useContext(ChatsContext);
 
  const { fetchUsersMessages, fetchGroupMessages } = useContext(
    RefetchMessagesContext
  );

  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const textareaRef = useRef(null);


  
  const fetchChats = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        "https://medicalradar.es/api/conversation/" + splashData.user.id
      );
      if (response.data.status === true) {
        setChats(response.data.data);
        console.log("response.data.data",response.data.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching chats. Please try again.");
    } finally {
      // setLoading(false);
    }
  };
  const handleUsersMessage = async () => {
    if (!message && !image) {
      toast.error("Please enter a message or select an image to send");
      return;
    }
  

  
    const formData = new FormData();
  
    if (selectedChat?.group_id === 0) {
      // For individual chat
      formData.append("from", splashData?.user.id);
      formData.append(
        "to",
        selectedChat?.to === splashData?.user.id
          ? selectedChat?.from
          : selectedChat?.to
      );
    } else {
      // For group chat
      formData.append("group", selectedChat?.group_id);
      formData.append("user", splashData?.user.id);
    }
  
    if (message.length > 0) {
      formData.append("message", message.trim()); // Send message with line breaks preserved
      formData.append("type", "text");
    }
  
    if (image) {
      formData.append("type", "image");
      formData.append("attachment", image); // Send selected image
    }
  
    let apiUrl;
    setIsApiCalling(true)
    if (selectedChat?.group_id === 0) {
      apiUrl = `${BaseURL}api/messaging`; // URL for individual chat
    } else {
      apiUrl = `${BaseURL}api/group/messaging`; // URL for group chat
    }
    const response = await axios.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  
    if (response.data.result === true||response.data.status) {
      setMessage("");

      setImage(null); // Clear image after sending
      textareaRef.current.style.height = "auto"; // Reset textarea height
      setIsApiCalling(false)
      fetchChats()
      if (selectedChat?.group_id === 0) {
        fetchUsersMessages();
      } else {
        fetchGroupMessages();
      }
    } else if (response.data.status === false) {
      toast.error(response.data.action);
    }
  };
  
//   const handleUsersMessage = async () => {
//     if (!message && !image) {
//       toast.error("Please enter a message or select an image to send");
//       return;
//     }
// console.log("groupID",selectedChat.group_id)
//     const formData = new FormData();
//     formData.append("from", splashData?.user.id);
//     formData.append(
//       "to",
//       selectedChat?.to === splashData?.user.id
//         ? selectedChat?.from
//         : selectedChat?.to
//     );

//     if (message.length > 0) {
//       formData.append("message", message.trim()); // Send message with line breaks preserved
//       formData.append("type", "text");
//     }

//     if (image) {
//       formData.append("type", "image");
//       formData.append("attachment", image); // Send selected image
//     }

//     const response = await axios.post(`${BaseURL}api/messaging`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });

//     if (response.data.result === true) {
//       setMessage("");
//       setImage(null); // Clear image after sending
//       textareaRef.current.style.height = "auto"; // Reset textarea height
 
//       if (selectedChat?.group_id === 0) {
//         fetchUsersMessages();
//       } else {
//         fetchGroupMessages();
//       }
//     } else if (response.data.status === false) {
//       toast.error(response.data.action);
//     }
//   };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image
  };

  const handleInputChange = (e) => {
    const textarea = textareaRef.current;

    // Reset height to auto for recalculation
    textarea.style.height = "auto";

    // Expand textarea height based on scroll height up to a max height
    if (textarea.scrollHeight <= 70) {
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.height = "70px"; // Lock height at 100px with scrollbar
    }

    setMessage(e.target.value); // Set the message content
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.scrollTop = textarea.scrollHeight; // Scroll to the bottom
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior
      handleUsersMessage(); // Send the message and image if available
    }
  };

  return (
    <div className="container mt-2">
      <div className="chat-input-container d-flex align-items-center p-2 border rounded">
        {/* Attachment Icon */}
        {image ? (
          <div className="mt-1">
            <div
              className="d-flex align-items-center gap-2 rounded-pill px-2"
              style={{ backgroundColor: "#1DC1DD" }}
            >
              <div className="d-flex align-items-center gap-2">
                <img
                  src={URL.createObjectURL(image)}
                  width={40}
                  className="rounded"
                  alt="preview"
                />
                <p
                  className="mb-0 roboto-medium fs-14 cursor text-white"
                  onClick={() =>
                    window.open(URL.createObjectURL(image), "_blank")
                  }
                >
                  {/* {image.name} */}
                </p>
              </div>
              <div>
                <IoClose
                  size={18}
                  className="text-danger cursor"
                  onClick={() => setImage(null)} // Remove the image preview
                />
              </div>
            </div>
          </div>
        ) : (
          <LuImagePlus
            size={24}
            onClick={() => document.getElementById("imageInput").click()}
            className="mr-4 cursor"
          />
        )}

        {/* Textarea for message input */}
        <textarea
          ref={textareaRef}
          className="form-control border-0 mx-2"
          placeholder="Message..."
          rows="1"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            flex: 1,
            borderRadius: "20px",
            resize: "none",
            minHeight: "40px",
            maxHeight: "150px",
            overflowY: "auto",
            outline: 'none', boxShadow: 'none',
            whiteSpace: "pre-wrap", // Ensure the textarea preserves newlines
          }}
          
        />

        {/* Emoji Icon */}
        {/* <button className="btn btn-light me-2">
          <LuSmile size={24} />
        </button> */}

        {/* Image upload trigger */}
        <input
          type="file"
          id="imageInput"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />

        {/* Send Button */}
        {(message.length > 0 || image)  && !isApiCalling&&(
          <button className="btn btn-primary" onClick={handleUsersMessage}>
            <FiSend size={20} />
          </button>
     
        ) } 
        {isApiCalling&& (
            <button class="btn btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="visually-hidden">Loading...</span>
        </button>
        )}
      </div>
    </div>
  );
}


