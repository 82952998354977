import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';

export const fetchCoursesData = async () => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    try {
        const response = await apiAdmin.get(
            // `api/web/course/home/${user_id}`
            `user/web/course/home`,
            // { user_id,type:"all" },
            {
                headers: {
                  Authorization: `Bearer ${token}`, // Set the Authorization header
                },
              }
        );
        return response.data;
    } catch (error) {
        toast.error('Error fetching courses:', error);
        throw error;
    }
};

export const fetchRecommendedCourses = async ({ page }) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/web/course/recommended/${user_id}?page=${page}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching recommended courses:', error);
        throw error;
    }
};

export const fetchPurchasedCourses = async ({ page }) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/course/v1/purchased/${user_id}/${user_id}?page=${page}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching purchased courses:', error);
        throw error;
    }
};

export const fetchSavedCourses = async ({ page }) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/course/v1/saved/${user_id}/${user_id}?page=${page}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching purchased courses:', error);
        throw error;
    }
};

export const fetchMyCourses = async ({ page }) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/course/v1/my/${user_id}/${user_id}?page=${page}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching my courses:', error);
        throw error;
    }
};

export const fetchClinicalSessionData = async ({type}) => {
    console.log("type",type)
    const token = JSON.parse(localStorage.getItem('SignupData'))?.data?.token;
    try {
        const response = await apiAdmin.get(
            `user/web/clinical-session/${type}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`, // Set the Authorization header
                },
            }
        );
        return response.data;
    } catch (error) {
        toast.error('Error fetching clinical sessions:', error);
        throw error;
    }
};
