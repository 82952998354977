import axios from 'axios';
import { SplashApi } from '../constants/API';


export const api = axios.create({
    baseURL: 
    // 'https://admin.medicalradar.es/endpoint/'
    'https://medicalradar.es/',
});
export const apiAdmin = axios.create({
    baseURL: 
    'https://admin.medicalradar.es/endpoint/'
    // 'https://medicalradar.es/',
});

export const getSplashData = () => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    return api.post(SplashApi + user_id, { type: "all", });
};
