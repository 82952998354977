import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import {
  AddCommentApi,
  AddCommentRepliesApi,
  BaseURL,
  DeleteCommentApi,
  DeletePostApi,
  EditPostApi,
  GetCommentsApi,
  GetRepliesApi,
  LikeApi,
  PostDetailsApi,
  ReportJobApi,
  RepostApi,
  ReserveApi,
  SaveApi,
} from "../../constants/API";
import { toast } from "react-toastify";
import { PROFILE } from "../../constants/RoutesUrl";
import {
  Col,
  Collapse,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import { UnixToRelative } from "../notification/Notification";
import VideoWithAspectRatio from "../common/VideowithAspectRatio.tsx";
import ImageWithAspectRatio from "../common/ImagewithAspectRatio";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Dots from "../../assets/images/icon/post-dropdown-dots.png";
import {
  ConvertToTime,
  ReadMore,
  TimestampConverter,
} from "../../pages/Feed.jsx";
import { MdOutlineCancel } from "react-icons/md";
import { BsSendFill } from "react-icons/bs";
import { Language } from "../../App.js";
import PostHeader from "../feeds/PostHeader.jsx";
import PostFooter from "../feeds/PostFooter.jsx";
import InfiniteScroll from "react-infinite-scroller";
import ImageComponent from "../common/ImageComponent.jsx";
import { Helmet } from "react-helmet";
import LoginAlertModal from "./LoginAlertModal.jsx";

const Post = () => {
  const { post_id } = useParams();
  const { lang } = useContext(Language);

  const navigate = useNavigate();

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id ? user.data.id : "11";

  const [value, setValue] = useState({});
  const [postDropDown, setPostDropDown] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [editPostInput, setEditPostInput] = useState("");
  const [editPostModal, setEditPostModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [postComments, setPostComments] = useState({});
  const [spin, setSpin] = useState(false);
  const [replyUser, setReplyUser] = useState({});
  const [commentId, setCommentId] = useState("");
  const [commentReplies, setCommentReplies] = useState([]);
  const [comment, setComment] = useState("");
  const [reportInput, setReportInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState(0);
  const [postId, setPostId] = useState("");
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeUsers, setLikeUsers] = useState({});
  const [likePage, setLikePage] = useState(1);
  const [loginModal, setLoginModal] = useState(false);

  const handlePollAnswer = (answer) => {
    axios
      .post(BaseURL + "api/poll/answer/submit", {
        post_id,
        user_id,
        answer: answer + 1,
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDeleteComment = () => {
    axios
      .get(DeleteCommentApi + commentId + "/delete")
      .then((res) => {
        if (res.data.status) {
          setCommentId("");
          setDeleteModal(false);
          handleGetComments();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddReplies = (comment_id) => {
    axios
      .post(AddCommentRepliesApi + post_id + "/" + user_id, {
        parent: comment_id,
        comment,
      })
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments(post_id);
          handleGetReplies(commentId);
          setIsReply(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEditPost = () => {
    setIsLoading(true);
    const editData = { caption: editPostInput, post_id: dropDownId };
    axios
      .post(EditPostApi, editData)
      .then((res) => {
        if (res.data.status) {
          toast.success(lang?.edit_success);
          setIsLoading(false);
          setEditPostModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddComment = () => {
    const data = {
      comment,
    };
    axios
      .post(AddCommentApi + post_id + "/" + user_id, data)
      .then((res) => {
        if (res.data.status) {
          setComment("");
          handleGetComments();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeletePost = () => {
    setIsLoading(true);
    axios
      .get(DeletePostApi + `${dropDownId}/delete`)
      .then((res) => {
        if (res.data.status) {
          toast.success("Post Deleted!");
          setIsLoading(false);
          setPostDeleteModal(false);
          window.location.reload();
        } else {
          toast.error(res.data.action);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handlePostReport = () => {
    setIsLoading(true);
    const reportData = {
      report_id: dropDownId,
      user_id,
      report: reportInput,
      type: "post",
    };
    axios
      .post(ReportJobApi, reportData)
      .then((res) => {
        if (res.data.status) {
          toast.success(lang?.reported_successfully);
          setIsLoading(false);
          setReportModal(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleGetReplies = (comment_id) => {
    axios
      .get(GetRepliesApi + comment_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setCommentReplies(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLike = () => {
    const updatedData = { ...value, isLike: !value.isLike };
    setValue(updatedData);
    axios
      .get(LikeApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSave = () => {
    const updatedData = { ...value, isSave: !value?.isSave };

    setValue(updatedData);
    axios
      .get(SaveApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleGetComments = () => {
    setIsLoading(true);
    const id = post_id;
    axios
      .get(GetCommentsApi + id + "/" + user_id + "?page=1")
      .then((res) => {
        if (res.data.status) {
          setPostComments(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRepost = () => {
    const updatedData = { ...value, isShare: !value.isShare };

    setValue(updatedData);
    axios
      .get(RepostApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.action);
        } else {
          toast.error(res.data.actionI);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDetails = () => {
    setSpin(true);

    axios
      .get(PostDetailsApi + post_id + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setValue(res?.data?.data);
        } else {
          toast.error(res.data.action);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setTimeout(() => {
          setSpin(false);
        }, 2000);
      });
  };

  const handleReserveWebinar = () => {
    const updatedData = { ...value, isReserve: !value.isReserve };

    setValue(updatedData);

    axios
      .get(ReserveApi + post_id + "/" + user_id)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLikeUsers = (otherId) => {
    setLikeSpinner(true);
    setLikeUsersModal(true);
    axios
      .get(BaseURL + "api/post/likes/" + otherId + "/" + user_id)
      .then((res) => {
        if (res.data.status) {
          setLikeUsers(res.data.data);
        } else {
          toast.error(res.data.error);
          setLikeUsersModal(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLikeSpinner(false);
      });
  };

  const indicatorStyles = {
    background: "#fff",
    width: 20,
    height: 4,
    display: "inline-block",
    margin: "0 4px",
    borderRadius: "1px",
  };

  const handleNewLikeUsers = () => {
    axios
      .get(
        BaseURL +
          "api/post/likes/" +
          postId +
          "/" +
          user_id +
          "?page=" +
          JSON.stringify(likePage + 1)
      )
      .then((res) => {
        if (res.data.status) {
          const update = likeUsers.data.concat(res.data.data.data);
          setLikeUsers({ ...likeUsers, data: update });
          setLikePage(likePage + 1);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleDetails();

    setLoginModal(user?.data?.id === undefined ? true : false);
    if (user?.data?.id === undefined) {
      sessionStorage.setItem("postUrl", window.location.pathname);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{value?.caption}</title>
        <meta property="al:ios:app_name" content="Medicalradar" />
        <meta property="al:ios:app_store_id" content="1605786600" />
        <meta property="al:ios:url" content={window.location} />
        <meta property="al:android:app_name" content="MedicalRadar" />
        <meta
          property="al:android:package"
          content="com.drudotstech.medicalradar"
        />
        <meta property="al:android:url" content={window.location} />
        <meta
          property="al:android:package"
          content="com.drudotstech.medicalradar"
        />
        <meta property="al:android:url" content={window.location} />
        <meta
          name="description"
          content={value.caption ? value.caption : "attached/Images"}
        />
        <meta
          property="og:description"
          content={value.caption ? value.caption : "attached/Images"}
        />
        <meta property="og:site_name" content="MedicalRadar" />
        <meta
          property="og:title"
          content={value.caption ? value.caption : "Medical Radar Post"}
        ></meta>

        {value.type === "image" ? (
          <meta
            property="og:image"
            content={value.media[0] ? BaseURL + value?.media[0] : ""}
          ></meta>
        ) : (
          ""
        )}
      </Helmet>

      <LoginAlertModal open={loginModal} />

      {spin ? (
        <Row>
          <Col className="text-center my-2">
            <Spinner />
          </Col>
        </Row>
      ) : (
        <>
          {value?.type === "video" ||
            (value?.type === "image" ? (
              <div
                className="bg-white my-3 py-3 rounded"
                style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
              >
                <PostHeader
                  Id={value.user.id}
                  img={value.user.image}
                  name={value.user.name}
                  time={value?.time}
                  postDropDown={postDropDown}
                  setPostDropDown={setPostDropDown}
                  setReportModal={setReportModal}
                  setDropDownId={setDropDownId}
                  original_id={value.original_id}
                  dropDownId={dropDownId}
                  setEditPostInput={setEditPostInput}
                  caption={value.caption}
                  setEditPostModal={setEditPostModal}
                  setPostDeleteModal={setPostDeleteModal}
                />
                <Row className="pt-3">
                  <Col>
                    {value.type === "video" ? (
                      <>
                        {value.media &&
                          value.media.map((media) => (
                            <VideoWithAspectRatio
                              src={BaseURL + media}
                              aspectRatio={value.sizes}
                            />
                          ))}
                      </>
                    ) : value.media && value.media.length === 1 ? (
                      value.media.map((media) => (
                        <ImageWithAspectRatio
                          height={555 / value.sizes}
                          src={BaseURL + media}
                          alt="..."
                        />
                      ))
                    ) : (
                      <Carousel
                        renderArrowNext={(onClickHandler, hasNext) =>
                          hasNext && (
                            <IoIosArrowDroprightCircle
                              className="top-0 bottom-0 position-absolute m-auto end-0 me-3"
                              size={30}
                              color="white"
                              onClick={onClickHandler}
                            />
                          )
                        }
                        renderArrowPrev={(onClickHandler, hasPrev) =>
                          hasPrev && (
                            <IoIosArrowDropleftCircle
                              className="top-0 bottom-0 position-absolute m-auto ms-3 start-0"
                              size={30}
                              style={{ zIndex: "1" }}
                              color="white"
                              onClick={onClickHandler}
                            />
                          )
                        }
                        renderIndicator={(
                          onClickHandler,
                          isSelected,
                          index,
                          label
                        ) => {
                          return (
                            <>
                              {isSelected ? (
                                <li
                                  style={{
                                    ...indicatorStyles,
                                    background: "#00c5de",
                                  }}
                                  aria-label={`Selected: ${label} ${index + 1}`}
                                  title={`Selected: ${label} ${index + 1}`}
                                />
                              ) : (
                                <li
                                  style={indicatorStyles}
                                  onClick={onClickHandler}
                                  onKeyDown={onClickHandler}
                                  value={index}
                                  key={index}
                                  role="button"
                                  tabIndex={0}
                                  title={`${label} ${index + 1}`}
                                  aria-label={`${label} ${index + 1}`}
                                />
                              )}
                            </>
                          );
                        }}
                        statusFormatter={(current, total) => {
                          return (
                            <>
                              <div
                                className="mt-3 px-3 py-1 rounded-pill me-2"
                                style={{
                                  backgroundColor: "rgba(0,0,0,0.2)",
                                }}
                              >
                                <p className="roboto-bold mb-0 fs-12">{`${current}/${total}`}</p>
                              </div>
                            </>
                          );
                        }}
                        autoPlay={false}
                        infiniteLoop={false}
                        showIndicators={true}
                        showThumbs={false}
                        showStatus={true}
                      >
                        {value.media &&
                          value.media.map((data) => (
                            <ImageWithAspectRatio
                              height={555 / value.sizes}
                              src={BaseURL + data}
                              alt="..."
                            />
                          ))}
                      </Carousel>
                    )}
                  </Col>
                </Row>
                {value?.caption && (
                  <>
                    <Row className="p-2">
                      <Col>
                        <ReadMore text={value.caption} />
                      </Col>
                    </Row>
                    <hr className="m-0" />
                  </>
                )}
                <PostFooter
                  likes_count={value.likes_count}
                  setPostId={setPostId}
                  original_id={value.original_id}
                  handleLikeUsers={handleLikeUsers}
                  like_users_images={value.like_users_images}
                  handleLike={handleLike}
                  isLike={value.isLike}
                  handleGetComments={handleGetComments}
                  setPostModal={setPostModal}
                  comments_count={value.comments_count}
                  handleRepost={handleRepost}
                  isShare={value.isShare}
                  isSave={value.isSave}
                  handleSave={handleSave}
                />
              </div>
            ) : value.type === "webinar" ? (
              <div
                className="bg-white my-3 py-3 br-6"
                style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
              >
                <div className="position-relative d-flex justify-content-between align-items-center px-3">
                  <div className="d-flex gap-3">
                    <div
                      className="bg-white dropdown-avatar-shadow wh-lg-60px wh-md-50px align-self-center br-22 br-md-10px"
                      style={{ padding: "2px" }}
                    >
                      <Link to={PROFILE + "/" + value.user.id}>
                        <ImageComponent
                          src={
                            value.user.image
                              ? BaseURL + value.user.image
                              : Avatar
                          }
                          img_height="100%"
                          img_width="100%"
                          loader_height="100%"
                          loader_width="100%"
                          compClassName="br-22 br-md-10px"
                        />
                      </Link>
                    </div>
                    <div className="align-self-center">
                      <div>
                        <Link to={PROFILE + "/" + value.user.id}>
                          <h1 className="roboto-bold mb-0 text-dark text-ellips-162px fs-16">
                            {value.user.name}
                          </h1>
                        </Link>
                        <p
                          className="roboto-regular mb-0 fs-12"
                          style={{
                            color: "#434343",
                            opacity: "80%",
                          }}
                        >
                          <UnixToRelative unixTimestamp={value?.time} />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img
                      onClick={() => {
                        setPostDropDown(!postDropDown);
                        setDropDownId(value.original_id);
                      }}
                      width={20}
                      className="cursor"
                      src={Dots}
                      alt="picture"
                    />
                  </div>
                  {/* Post DropDown */}
                  <div className="position-absolute mt-5 w-25 end-0">
                    <Collapse
                      isOpen={
                        postDropDown && value.original_id === dropDownId
                          ? true
                          : false
                      }
                      className="bg-white p-3 text-center br-16"
                      style={{
                        boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
                      }}
                    >
                      {value.user.id !== user_id && (
                        <h6
                          className="mb-0 text-red cursor fs-md-12"
                          onClick={() => {
                            setReportModal(true);
                            setPostDropDown(false);
                          }}
                        >
                          {lang?.report}
                        </h6>
                      )}
                      {value.user.id === user_id && (
                        <>
                          <h6
                            onClick={() => {
                              setPostDeleteModal(true);
                              setPostDropDown(false);
                            }}
                            className="mb-0 text-red cursor fs-md-12"
                          >
                            {lang?.delete}
                          </h6>
                          <h6 className="mt-3 mb-0 cursor">{lang?.edit}</h6>
                        </>
                      )}
                    </Collapse>
                  </div>
                  {/* End Post DropDown */}
                </div>
                <Row className="pt-3 pb-2">
                  <Col>
                    {value.media.map((media) => (
                      <ImageWithAspectRatio
                        height={555 / value.sizes}
                        src={BaseURL + media}
                        alt="..."
                      />
                    ))}
                  </Col>
                </Row>
                {value?.caption || value?.title ? (
                  <>
                    <Row className="p-2">
                      <Col>
                        <h4>
                          {value?.title && value.title.length > 26
                            ? value.title.slice(0, 26) + "..."
                            : value.title}
                        </h4>
                        <ReadMore text={value.caption} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <Row className="justify-content-between px-3">
                  <Col className="align-self-center">
                    <p className="mb-0 text-warning">{lang?.scheduled_on}</p>
                    <small className="mb-0">
                      {value?.scheduled}
                      {", "}
                      <ConvertToTime tstamp={value.start_timestamp} />
                    </small>
                  </Col>
                  <Col className="text-end align-self-center">
                    {value.status !== 0 && (
                      <button
                        className="px-4 py-2 fw-bold text-white btn btn-warning"
                        onClick={
                          value.user.id === user_id
                            ? value.status === 0
                              ? null
                              : value.status === 2
                              ? "Webinar Ended"
                              : () => {
                                  if (value.user.id === user_id) {
                                    window.open(
                                      value.meeting_host_url,
                                      "_blank"
                                    );
                                  } else {
                                    if (value.isHost) {
                                      window.open(
                                        value.meeting_host_url,
                                        "_blank"
                                      );
                                    } else {
                                      window.open(value.meeting_url, "_blank");
                                    }
                                  }
                                }
                            : value.status === 0
                            ? value.isReserve
                              ? () => handleReserveWebinar(value.id)
                              : () => handleReserveWebinar(value.id)
                            : value.status === 1
                            ? value.isReserve
                              ? () => {
                                  if (value.user.id === user_id) {
                                    window.open(
                                      value.meeting_host_url,
                                      "_blank"
                                    );
                                  } else {
                                    if (value.meeting_url !== "") {
                                      window.open(value.meeting_url, "_blank");
                                    } else {
                                      toast.info("Not started yet.");
                                    }
                                  }
                                }
                              : () => handleReserveWebinar(value.id)
                            : () => toast.info("Webinar is Ended.")
                        }
                      >
                        {value.user.id === user_id
                          ? value.status === 0
                            ? "Not Started Yet"
                            : value.status === 2
                            ? "Webinar Ended"
                            : "Start Webinar"
                          : value.status === 0
                          ? value.isReserve
                            ? "Unreserve"
                            : "Reserve Seat"
                          : value.status === 1
                          ? value.isReserve
                            ? "Join Webinar"
                            : "Reserve Seat"
                          : "Webinar Ended"}
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
            ) : value.type === "clinical-image" ? (
              <div
                className="bg-white my-3 py-3 br-6"
                style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
              >
                {value.repost_user.image && value.repost_user.name && (
                  <>
                    <Row>
                      <Col className="d-flex">
                        <div
                          className="bg-white dropdown-avatar-shadow br-14 ms-3"
                          style={{
                            maxWidth: "2.5rem",
                            maxHeight: "2.5rem",
                            padding: "0.8px",
                          }}
                        >
                          <ImageComponent
                            src={BaseURL + value.repost_user.image}
                            loader_height="2.5rem"
                            loader_width="2.5rem"
                            compClassName="br-12 img-fluid"
                          />
                        </div>
                        <div className="align-self-center">
                          <h6 className="mx-3 mb-0">
                            {value.repost_user.name} {lang?.reposted_this}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    <hr style={{ opacity: "0.1px" }} />
                  </>
                )}
                <PostHeader
                  Id={value.user.id}
                  img={value.user.image}
                  name={value.user.name}
                  time={value?.time}
                  setPostDropDown={setPostDropDown}
                  postDropDown={postDropDown}
                  setDropDownId={setDropDownId}
                  original_id={value.original_id}
                  dropDownId={dropDownId}
                  setReportModal={setReportModal}
                  setPostDeleteModal={setPostDeleteModal}
                />
                <Row className="pt-3 pb-2">
                  <Col>
                    {value?.media && value?.media.length === 1 ? (
                      value?.media.map((data) => (
                        <ImageWithAspectRatio
                          aspectRatio={555 / value?.sizes}
                          src={BaseURL + data}
                          alt="..."
                        />
                      ))
                    ) : (
                      <Carousel
                        statusFormatter={(current, total) => {
                          return (
                            <>
                              <div
                                className="mt-3 px-3 py-1 rounded-pill me-2"
                                style={{
                                  backgroundColor: "rgba(0,0,0,0.2)",
                                }}
                              >
                                <p className="roboto-bold mb-0 fs-12">{`${current}/${total}`}</p>
                              </div>
                            </>
                          );
                        }}
                        renderArrowNext={(onClickHandler, hasNext) =>
                          hasNext && (
                            <IoIosArrowDroprightCircle
                              className="top-0 bottom-0 position-absolute m-auto end-0 me-3"
                              size={30}
                              color="white"
                              onClick={onClickHandler}
                            />
                          )
                        }
                        renderArrowPrev={(onClickHandler, hasPrev) =>
                          hasPrev && (
                            <IoIosArrowDropleftCircle
                              className="top-0 bottom-0 position-absolute m-auto ms-3 start-0"
                              size={30}
                              style={{ zIndex: "1" }}
                              color="white"
                              onClick={onClickHandler}
                            />
                          )
                        }
                        renderIndicator={(
                          onClickHandler,
                          isSelected,
                          index,
                          label
                        ) => {
                          if (isSelected) {
                            return (
                              <li
                                style={{
                                  ...indicatorStyles,
                                  background: "#00c5de",
                                }}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`}
                              />
                            );
                          }
                          return (
                            <li
                              style={indicatorStyles}
                              onClick={onClickHandler}
                              onKeyDown={onClickHandler}
                              value={index}
                              key={index}
                              role="button"
                              tabIndex={0}
                              title={`${label} ${index + 1}`}
                              aria-label={`${label} ${index + 1}`}
                            />
                          );
                        }}
                        autoPlay={false}
                        infiniteLoop={false}
                        showIndicators={true}
                        showThumbs={false}
                        showStatus={true}
                      >
                        {value?.media &&
                          value?.media.map((data) => (
                            <ImageWithAspectRatio
                              aspectRatio={555 / value?.sizes}
                              src={BaseURL + data}
                              alt="..."
                            />
                          ))}
                      </Carousel>
                    )}
                  </Col>
                </Row>
                {value?.caption || value?.title ? (
                  <>
                    <Row className="justify-content-between p-2">
                      <Col className="align-self-center">
                        <h4 className="mb-0">
                          {value?.title && value.title.length > 26
                            ? value.title.slice(0, 26) + "..."
                            : value.title}
                        </h4>
                      </Col>
                      {value?.pdf && (
                        <Col className="text-end">
                          <button
                            className="border-0 px-3 py-1 text-white btn-blue"
                            onClick={() => {
                              window.open(BaseURL + value.pdf, "_blank");
                            }}
                          >
                            {lang?.view_Case_Study}
                          </button>
                        </Col>
                      )}
                    </Row>
                    <Row className="px-2">
                      <Col>
                        <ReadMore text={value.caption} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <PostFooter
                  likes_count={value.likes_count}
                  setPostId={setPostId}
                  original_id={value.original_id}
                  handleLikeUsers={handleLikeUsers}
                  like_users_images={value.like_users_images}
                  handleLike={handleLike}
                  isLike={value.isLike}
                  handleGetComments={handleGetComments}
                  setPostModal={setPostModal}
                  comments_count={value.comments_count}
                  handleRepost={handleRepost}
                  isShare={value.isShare}
                  isSave={value.isSave}
                  handleSave={handleSave}
                />
              </div>
            ) : value?.type === "poll" ? (
              <div
                className="bg-white my-3 py-3 br-6"
                style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
              >
                {value.repost_user.image && value.repost_user.name && (
                  <>
                    <Row>
                      <Col className="d-flex">
                        <div
                          className="bg-white dropdown-avatar-shadow br-14 ms-3"
                          style={{
                            maxWidth: "2.5rem",
                            maxHeight: "2.5rem",
                            padding: "0.8px",
                          }}
                        >
                          <ImageComponent
                            loader_height="2.5rem"
                            loader_width="2.5rem"
                            src={BaseURL + value.repost_user.image}
                            compClassName="br-12 img-fluid"
                          />
                        </div>
                        <div className="align-self-center">
                          <h6 className="mx-3 mb-0">
                            {value.repost_user.name} {lang?.reposted_this}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    <hr style={{ opacity: "0.1px" }} />
                  </>
                )}
                <PostHeader
                  Id={value.user.id}
                  img={value.user.image}
                  name={value.user.name}
                  time={value?.time}
                  postDropDown={postDropDown}
                  setPostDropDown={setPostDropDown}
                  setReportModal={setReportModal}
                  setDropDownId={setDropDownId}
                  original_id={value.original_id}
                  dropDownId={dropDownId}
                  setEditPostInput={setEditPostInput}
                  caption={value.caption}
                  setEditPostModal={setEditPostModal}
                  setPostDeleteModal={setPostDeleteModal}
                />
                <Row className="pt-3 pb-2">
                  <Col>
                    {value?.media &&
                      value?.media.map((data) => (
                        <ImageWithAspectRatio
                          height={555 / value?.sizes}
                          src={BaseURL + data}
                          alt="..."
                        />
                      ))}
                  </Col>
                </Row>
                {value?.title && (
                  <>
                    <Row className="px-3 py-2">
                      <Col>
                        <p className="roboto-medium m-0 fs-20 fs-md-15">
                          {value?.title}
                        </p>
                      </Col>
                    </Row>
                    <hr className="m-0" />
                  </>
                )}

                {[
                  value?.option_1,
                  value?.option_2,
                  value?.option_3,
                  value?.option_4,
                ].map((data, index) => (
                  <div className="mx-3 my-3" key={index}>
                    {data !== "" && (
                      <div
                        className="p-3 cursor"
                        onClick={() => {
                          setSelectedPoll(index);
                          handlePollAnswer(value.original_id, index);
                        }}
                        style={{
                          border: "1px solid #00c5de",
                          borderRadius: "10px",
                          background: "rgba(0, 197, 222,0.1)",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="m-0 fs-md-15">{data}</h5>
                          </div>
                          <div className="d-flex justify-content-end gap-2">
                            <label
                              className="form-check-label"
                              style={{ width: "35px" }}
                            >
                              {value.answer_percentage[index]} %
                            </label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={selectedPoll === index}
                                value={index}
                                name="poll"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <hr className="mb-0" />

                <PostFooter
                  likes_count={value.likes_count}
                  setPostId={setPostId}
                  original_id={value.original_id}
                  handleLikeUsers={handleLikeUsers}
                  like_users_images={value.like_users_images}
                  handleLike={handleLike}
                  isLike={value.isLike}
                  handleGetComments={handleGetComments}
                  setPostModal={setPostModal}
                  comments_count={value.comments_count}
                  handleRepost={handleRepost}
                  isShare={value.isShare}
                  isSave={value.isSave}
                  handleSave={handleSave}
                />
              </div>
            ) : (
              <></>
            ))}
        </>
      )}

      <Offcanvas
        direction="end"
        scrollable
        isOpen={postModal}
        zIndex={9}
        style={{ width: "30%" }}
        toggle={() => {
          setPostModal(false);
        }}
      >
        <OffcanvasHeader
          className="bg-grey"
          toggle={() => {
            setPostModal(false);
          }}
        >
          {lang?.comments}
        </OffcanvasHeader>
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <OffcanvasBody
          className="bg-grey scroll-hide"
          style={{ paddingBottom: "5rem" }}
        >
          {isLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                {postComments?.data &&
                  postComments.data.map((content, index) => (
                    <div className="bg-white br-12 p-2 my-3" key={index}>
                      <div className="d-flex gap-3">
                        {/* Profile Image */}
                        <div>
                          <div
                            className="cursor bg-white br-16 dropdown-avatar-shadow"
                            style={{
                              maxWidth: "3rem",
                              maxHeight: "3rem",
                              padding: "0.8px",
                            }}
                          >
                            <img
                              src={
                                content.user.image
                                  ? BaseURL + content.user.image
                                  : Avatar
                              }
                              className="br-14 img-fluid"
                              alt="picture"
                            />
                          </div>
                        </div>
                        {/* Div End Profile Image */}
                        {/* Profile Detials */}
                        <div>
                          <p className="roboto-bold fs-16 mb-0 cursor">
                            {content.user.name}
                          </p>
                          <p className="roboto-regular fs-14 text-gray">
                            {content.comment}
                          </p>
                          <div className="d-flex gap-4">
                            <p className="roboto-medium fs-12">
                              {<TimestampConverter timestamp={content.time} />}
                            </p>
                            <p
                              className="roboto-medium fs-12 cursor text-blue"
                              onClick={() => {
                                setIsReply(true);
                                setReplyUser(content.user);
                                setCommentId(JSON.stringify(content.id));
                              }}
                            >
                              {lang?.reply}
                            </p>
                            <p
                              className="roboto-medium fs-12 cursor text-red"
                              onClick={() => {
                                setCommentId(JSON.stringify(content.id));
                                setDeleteModal(true);
                              }}
                            >
                              {lang?.delete}
                            </p>
                          </div>
                        </div>
                        {/* Div ended Profile Detials */}
                      </div>

                      {content.replies > 0 && (
                        <div>
                          <p
                            className="fw-bold text-gray fs-12 text-end me-3 cursor mb-0"
                            onClick={() => {
                              if (content.id === commentId) {
                                setCommentId("");
                                setCommentReplies([]);
                              } else {
                                setCommentId(content.id);
                                handleGetReplies(content.id);
                              }
                            }}
                          >
                            {content.id === commentId ? lang?.hide : lang?.view}{" "}
                            {content.replies}{" "}
                            {content.replies > 1 ? lang?.replies : lang?.reply}
                          </p>

                          {content.id === commentId &&
                            commentReplies.map((val) => {
                              return (
                                <>
                                  <div>
                                    <Collapse horizontal isOpen={true}>
                                      <div
                                        className="pt-3 ms-5 mt-4"
                                        key={val.id}
                                      >
                                        <div className="d-flex gap-3">
                                          {/* Profile Image */}
                                          <div>
                                            <div
                                              className="cursor bg-white br-16 dropdown-avatar-shadow"
                                              style={{
                                                maxWidth: "3rem",
                                                maxHeight: "3rem",
                                                padding: "0.8px",
                                              }}
                                            >
                                              <img
                                                src={
                                                  val.user.image
                                                    ? BaseURL + val.user.image
                                                    : Avatar
                                                }
                                                className="br-14 img-fluid"
                                                alt="picture"
                                              />
                                            </div>
                                          </div>
                                          {/* Div End Profile Image */}
                                          {/* Profile Detials */}
                                          <div>
                                            <p className="roboto-bold fs-16 mb-0 cursor">
                                              {val.user.name}
                                            </p>
                                            <p className="roboto-regular fs-14 text-gray">
                                              {val.comment}
                                            </p>
                                            <div className="d-flex gap-4">
                                              <p className="roboto-medium fs-12">
                                                {
                                                  <TimestampConverter
                                                    timestamp={val.time}
                                                  />
                                                }
                                              </p>
                                              <p
                                                className="roboto-medium fs-12 cursor text-blue"
                                                onClick={() => {
                                                  setIsReply(true);
                                                  setReplyUser(val.user);
                                                  setCommentId(
                                                    JSON.stringify(content.id)
                                                  );
                                                }}
                                              >
                                                {lang?.reply}
                                              </p>
                                              <p
                                                className="roboto-medium fs-12 cursor text-red"
                                                onClick={() => {
                                                  setCommentId(
                                                    JSON.stringify(val.id)
                                                  );
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                {lang?.delete}
                                              </p>
                                            </div>
                                          </div>
                                          {/* Div ended Profile Detials */}
                                        </div>
                                      </div>
                                    </Collapse>
                                    <hr
                                      className="ms-5"
                                      style={{ opacity: "0.1" }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              {/* if NO comments available */}
              {postComments.data && postComments.data.length === 0 && (
                <div className="text-center">
                  <h3 className="text-gray">{lang?.there_no_comment}</h3>
                  <p className="text-gray">{lang?.no_one_commented_post}</p>
                </div>
              )}
            </>
          )}

          {/* Comment input section */}
          <div className="position-absolute bottom-0 m-auto start-0 end-0">
            {isReply && (
              <Row>
                <Col
                  className="bg-blue br-12 d-flex justify-content-between"
                  style={{ marginLeft: "1.3rem", marginRight: "6.3rem" }}
                >
                  <h6 className="text-white mb-0 py-2">
                    {lang?.replying_to} {replyUser.name}
                  </h6>
                  <div
                    className="align-self-center cursor"
                    onClick={() => setIsReply(false)}
                  >
                    <MdOutlineCancel color="white" size={20} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex">
                <input
                  type="text"
                  placeholder={lang?.write_your_coment}
                  className="form-control m-2 py-3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className="align-self-center">
                  <button
                    type="button"
                    disabled={comment === "" ? true : false}
                    className="btn-blue border-0 ms-1 me-2 py-3 px-4"
                    onClick={() => {
                      isReply
                        ? handleAddReplies(commentId)
                        : handleAddComment();
                    }}
                  >
                    <BsSendFill size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Comment input section */}
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={editPostModal} centered zIndex={9}>
        <ModalHeader
          toggle={() => {
            setEditPostModal(false);
          }}
        >
          {lang?.edit_post}
        </ModalHeader>
        <ModalBody>
          <h6>{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <button
            className="btn-blue border-0 py-3 w-100"
            disabled={!editPostInput}
            onClick={handleEditPost}
          >
            {isLoading ? <Spinner size="sm" /> : lang?.update}
          </button>
        </ModalBody>
      </Modal>

      <Modal isOpen={postDeleteModal} centered zIndex={9}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setPostDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={reportModal} centered zIndex={9}>
        <ModalHeader
          toggle={() => {
            setReportModal(false);
          }}
        >
          {lang?.report_post}
        </ModalHeader>
        <ModalBody>
          <h6>{lang?.modal_enter_report_para}</h6>

          <textarea
            rows="7"
            className="form-control"
            placeholder={lang?.enter_your_report}
            onChange={(e) => setReportInput(e.target.value)}
          ></textarea>

          <button
            className="btn-blue w-100 border-0 rounded py-3 my-3"
            onClick={() => {
              handlePostReport();
            }}
          >
            {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
          </button>
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_comment}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_comment_able_comment}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeleteComment}
            >
              {lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={likeUsersModal}
        toggle={() => setLikeUsersModal(!likeUsersModal)}
        centered
        zIndex={9}
      >
        <ModalHeader toggle={() => setLikeUsersModal(false)}>
          {lang?.liked_users}
        </ModalHeader>
        <ModalBody>
          {likeSpinner ? (
            <Col className="my-4 text-center">
              <Spinner />
            </Col>
          ) : (
            <div>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewLikeUsers}
                hasMore={likeUsers.current_page !== likeUsers.last_page}
                loader={
                  <Col className="text-center">
                    <Spinner />
                  </Col>
                }
                useWindow={false}
              >
                {likeUsers.data &&
                  likeUsers.data.map((value, index) => (
                    <>
                      <Link
                        onClick={() => {
                          navigate(PROFILE + "/" + value.id);
                          window.location.reload();
                        }}
                        className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                        key={index}
                      >
                        <div className="d-flex gap-2">
                          <div
                            className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                            style={{
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? BaseURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="roboto-bold text-dark fs-17 search-post-name">
                              {value.name}
                            </div>
                            <p className="roboto-regular mb-0 fs-12 search-post-name">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Post;
