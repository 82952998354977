import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/RoutesUrl";
import Pick from "../../assets/images/icon/image.png";
import axios from "axios";
import { BaseURL, EmailVErificationApi, SignupApi } from "../../constants/API";
import { toast } from "react-toastify";
import login_top_img from "../../assets/images/auth/login_top_img.svg";
import login_bottom_img from "../../assets/images/auth/login_bottom_img.svg";
import email_icon from "../../assets/images/auth/login_email.svg";
import lock from "../../assets/images/auth/lock.svg";
import { CiLocationOn } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { FaHospitalAlt } from "react-icons/fa";
import show_eye from "../../assets/images/auth/show_eye.svg";
import hide_eye from "../../assets/images/auth/hide_eye.svg";
import { Language } from "../../App";

const SignupForm = ({ setAccountType }) => {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const [type, setType] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    first_name:"",
    last_name:"",
    full_name: "",
    username: "",
    hospital_name: "",
    doctor_id: "",
    password: "",
    id_front: null,
    id_back: null,
    country: "",
    city: "",
  });

  const [DisableBtn, setDisableBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState("");
  const [ModalStatus, setModalStatus] = useState(false);
  const [frontIdModal, setFrontIdModal] = useState(false);
  const [backIdModal, setBackIdModal] = useState(false);
  const [frontImagePreview, setFrontImagePreview] = useState(null);
  const [backImagePreview, setBackImagePreview] = useState(null);
  const [fetchingCities, setFetchingCities] = useState(false);

  // fetch countries from local storage
  useEffect(() => {
    const countriesData = sessionStorage.getItem("countriesData");
    if (countriesData) {
      setCountries(JSON.parse(countriesData));
    }
  }, []);

  // Function to handle form submission
  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Validate all required fields
    if (
      formData.email === "" ||
      formData.password === "" ||
      formData.doctor_id === "" ||
      formData.first_name === "" ||
      formData.last_name === "" ||
      // formData.full_name === "" ||
      formData.username === "" ||
      formData.id_front === null ||
      formData.city === "" ||
      formData.country === "" ||
      formData.hospital_name === ""
    ) {
      toast.error(lang?.please_enter_required_detail);
      setIsLoading(false);
      return;
    }

    // Email Validation
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      toast.error(lang?.please_enter_valid_email);
      setIsLoading(false);
      return;
    }

    // Password Validation rules
    if (formData.password.length < 6) {
      toast.error(lang?.password_should_least_6char_long);
      setIsLoading(false);
      return;
    } else if (formData.password.length > 30) {
      toast.error(lang?.password_should_not_exced_30_char);
      setIsLoading(false);
      return;
    } else if (/\s/.test(formData.password)) {
      toast.error(lang?.space_not_allowed_pass);
      setIsLoading(false);
      return;
    }

    // UserName Validation Code
    const usernameRegex = /^[a-zA-Z0-9_]{0,30}$/;
    if (!usernameRegex.test(formData.username)) {
      toast.error(lang?.username_formate);
      setIsLoading(false);
      return;
    }

    // FullName Validation
    const fullNameRegex = /^[A-Z][a-zA-Z\s]{0,29}$/;
    if (!fullNameRegex.test(formData.first_name)) {
      toast.error(lang?.fullname_formate);
      setIsLoading(false);
      return;
    }

    // Doctor Id Validation
    const doctorIDRegex = /^[A-Za-z0-9]{0,30}$/;
    if (!doctorIDRegex.test(formData.doctor_id)) {
      toast.error(lang?.doc_id_only_alphanumeric);
      setIsLoading(false);
      return;
    }

    // const timeZone = sessionStorage.getItem("user_timezone");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    axios
      .post(EmailVErificationApi, {
        user_name: formData?.username,
        email: formData?.email,
        doctor_id: formData?.doctor_id,
      })
      .then(function (response) {
        const status = response.data.status;

        if (status) {
          axios
            .post(
              SignupApi,
              {
                name: `${formData.first_name}${formData.last_name}`,
                firstname:formData.first_name,
                lastname:formData.last_name,
                user_name: formData.username,
                hospitalname: formData.hospital_name,
                doctor_id: formData.doctor_id,
                email: formData.email,
                password: formData.password,
                country: formData.country,
                city: formData.city,
                user_type: "doctor",
                device: "website",
                device_name: "web_abc",
                token: "web_abc",
                id_front: formData.id_front,
                id_back: formData?.id_back,
                timezone: timeZone,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function (response) {
              const status = response.data.status;
              if (status) {
                localStorage.setItem(
                  "SignupData",
                  JSON.stringify(response.data)
                );
                setIsLoading(false);
                setModalStatus(true);
              } else {
                toast.error(response.data.action);
                setIsLoading(false);
              }
            })
            .catch(function (error) {
              console.log(error);
              setIsLoading(false);
            });
        } else {
          toast.error(response.data.action);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const fetchCities = () => {
    setFetchingCities(true);
    const url = BaseURL + "api/app/country/" + formData.country;

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setCities(data.data);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
      })
      .finally(() => {
        setFetchingCities(false);
      });
  };

  // Function to toggle the image preview modal
  const toggle = () => setFrontIdModal(!frontIdModal);

  const FrontIdModalexternalCloseBtn = (
    <button
      type="button"
      className="close btn-secondary border-0 rounded"
      style={{ position: "absolute", top: "15px", right: "15px" }}
      onClick={toggle}
    >
      &times;
    </button>
  );
  const backIdModalexternalCloseBtn = (
    <button
      type="button"
      className="close btn-secondary border-0 rounded"
      style={{ position: "absolute", top: "15px", right: "15px" }}
      onClick={() => setBackIdModal(null)}
    >
      &times;
    </button>
  );

  return (
    <>
      <main className="overflow-scroll vh-100 py-2 login-bg lr-center1 bg-auth">
        <Modal isOpen={ModalStatus} zIndex={9} centered>
          <ModalBody className="bg-white rounded p-4">
            <div>
              <h2 className="text-blue fs-24 roboto-bold">
                {lang?.your_request_submitted}{" "}
              </h2>
              <p className="fs-18 roboto-regular">
                {lang?.your_request_submitted_desc1} <br />{" "}
                {lang?.your_request_submitted_desc2}
              </p>
            </div>
            <hr />
            <div className="text-end">
              <button
                className="btn-blue border-0 rounded px-md-3 w-lg-100 py-2"
                onClick={() => {
                  setModalStatus(false);
                  navigate(LOGIN);
                }}
              >
                OK
              </button>
            </div>
          </ModalBody>
        </Modal>

        <div className="container">
          <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
            <div className="position-relative">
              <img
                src={login_top_img}
                className="position-absolute"
                alt="loginTop"
              />
              <img
                src={login_bottom_img}
                className="position-absolute"
                alt="loginBottom"
              />
              <div className="card position-relative border-0">
                <div className="card-body px-4 py-4">
                  <div>
                    <h5 className="fs-md-16 text-normal-black">
                      {lang?.welcome_to} Medical Radar!
                    </h5>
                    <p className="text-normal-black m-0">
                      {lang?.please_sign_up_account}
                    </p>
                  </div>

                  <div className="my-3">
                    <form onSubmit={(e) => HandleSubmit(e)}>
                    <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={email_icon}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <input
                            type="text"
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                first_name: e.target.value,
                              });
                            }}
                            placeholder={"Enter First Name"}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={email_icon}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <input
                            type="text"
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                last_name: e.target.value,
                              });
                            }}
                            placeholder={"Enter Last Name"}
                          />
                        </div>
                      </div>
                      {/* <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={email_icon}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <input
                            type="text"
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                full_name: e.target.value,
                              });
                            }}
                            placeholder={lang?.enter_full_name}
                          />
                        </div>
                      </div> */}
                      <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={email_icon}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                username: e.target.value,
                              });
                            }}
                            type="text"
                            placeholder={lang?.enter_username}
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <FaHospitalAlt
                            className="position-absolute input-icon"
                            size={18}
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                hospital_name: e.target.value,
                              });
                            }}
                            type="text"
                            placeholder={lang?.enter_hospital_name}
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <FaHospitalAlt
                            className="position-absolute input-icon"
                            size={18}
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                doctor_id: e.target.value,
                              });
                            }}
                            type="text"
                            placeholder={lang?.enter_medical_id}
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <MdEmail
                            className="position-absolute input-icon"
                            size={19}
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              });
                            }}
                            type="email"
                            placeholder={lang?.enter_email_address}
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="position-relative">
                          <img
                            src={lock}
                            className="position-absolute"
                            alt="login-email"
                          />
                          <input
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              });
                            }}
                            type={type ? "password" : "text"}
                            placeholder={lang?.enter_password}
                            className="form-control"
                            style={{ paddingLeft: "40px" }}
                          />
                          <div>
                            <img
                              src={show_eye}
                              onClick={() => setType(!type)}
                              className={`position-absolute cursor ${
                                !type ? "d-none" : "d-block"
                              }`}
                              alt="login-show-eye"
                            />
                            <img
                              src={hide_eye}
                              onClick={() => setType(!type)}
                              className={`position-absolute cursor ${
                                type ? "d-none" : "d-block"
                              }`}
                              alt="login-show-eye"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="position-relative">
                          <CiLocationOn
                            className="position-absolute input-icon"
                            size={20}
                          />
                          <select
                            style={{ paddingLeft: "40px" }}
                            className="form-control"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                country: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled selected>
                              {lang?.select_country}
                            </option>
                            {countries.length === 0 && (
                              <option value="" disabled>
                                <h6>Loading ...</h6>
                              </option>
                            )}
                            {countries.map((value) => (
                              <option value={value.id}>{value.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {formData.country && (
                        <div className="mb-3">
                          <div className="position-relative">
                            <CiLocationOn
                              className="position-absolute input-icon"
                              size={20}
                            />
                            <select
                              onClick={fetchCities}
                              style={{ paddingLeft: "40px" }}
                              className="form-control"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  city: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled selected>
                                {lang?.select_city}
                              </option>
                              {cities.length === 0 && (
                                <option value="" disabled>
                                  <h6>Loading ...</h6>
                                </option>
                              )}
                              {cities.map((value) => (
                                <option value={value.id}>{value.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="mb-4">
                        <p className="fs-16 roboto-bold text-normal-black">
                          {lang?.we_need_verify_information}
                        </p>
                        <p className="fs-16 roboto-regular text-normal-black">
                          {lang?.verify_information_desc1}
                          <br />
                          {lang?.verify_information_desc2}
                        </p>
                      </div>

                      <div className="mb-4 d-flex justify-content-center gap-3">
                        <div className="position-relative w-50">
                          {formData?.id_front && (
                            <div className="position-absolute end-0 me-3 mt-1">
                              <button
                                className="btn-blue border-0 px-2"
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    id_front: null,
                                  });
                                  setFrontImagePreview(null);
                                }}
                              >
                                x
                              </button>
                            </div>
                          )}
                          <div
                            className="d-flex align-items-center justify-content-center bg-white cursor p-3 file-border h-100 w-100"
                            onClick={() => {
                              if (formData?.id_front) {
                                setFrontIdModal(true);
                              } else {
                                document.getElementById("fileInput").click();
                              }
                            }}
                          >
                            <div className="text-center">
                              {frontImagePreview ? ( // Display the image preview if available
                                <div>
                                  <img
                                    alt="picture"
                                    className="img-fluid"
                                    src={frontImagePreview}
                                  />
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {/* Display default content if no image is selected */}
                                  <h3 className="fs-20 fs-md-15 roboto-bold text-blue">
                                    {lang?.id_front}
                                  </h3>
                                  <p className="fs-10 roboto-medium">
                                    {"(required)"}
                                  </p>
                                  <img src={Pick} alt="picture" />
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              id="fileInput"
                              className="d-none"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                setFormData({
                                  ...formData,
                                  id_front: file, // Update the form data with the selected file
                                });

                                // Preview the image
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  setFrontImagePreview(reader.result);
                                };
                                reader.readAsDataURL(file);
                              }}
                            />
                          </div>
                        </div>

                        <div className="position-relative w-50">
                          {formData?.id_back && (
                            <div className="position-absolute end-0 me-3 mt-1">
                              <button
                                className="btn-blue border-0 px-2"
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    id_back: null,
                                  });
                                  setBackImagePreview(null);
                                }}
                              >
                                x
                              </button>
                            </div>
                          )}
                          <div
                            className="d-flex align-items-center justify-content-center bg-white cursor p-3 file-border h-100 w-100"
                            onClick={() => {
                              if (formData?.id_back) {
                                setBackIdModal(true);
                              } else {
                                document.getElementById("fileInput2").click();
                              }
                            }}
                          >
                            <div className="text-center">
                              {backImagePreview ? ( // Display the image preview if available
                                <div>
                                  <img
                                    alt="picture"
                                    className="img-fluid"
                                    src={backImagePreview}
                                  />
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {/* Display default content if no image is selected */}
                                  <h3 className="fs-20 fs-md-15 roboto-bold text-blue">
                                    {lang?.id_back}
                                  </h3>
                                  <p className="fs-10 roboto-medium">
                                    {"(optional)"}
                                  </p>
                                  <img src={Pick} alt="picture" />
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              id="fileInput2"
                              className="d-none"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                setFormData({
                                  ...formData,
                                  id_back: file, // Update the form data with the selected file
                                });

                                // Preview the image
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  setBackImagePreview(reader.result);
                                };
                                reader.readAsDataURL(file);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 form-check">
                        <input
                          className="form-check-input p-10"
                          type="checkbox"
                          checked={DisableBtn}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setDisableBtn(checked);
                          }}
                        />
                        <label className="fs-14 roboto-medium form-check-label ps-1 pt-1">
                          {lang?.by_creating_account_agree}{" "}
                          <a
                            href="https://medicalradar.es/terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            {lang?.terms_of_Use}
                          </a>{" "}
                          &{" "}
                          <a
                            href="https://medicalradar.es/privacy-policy"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            {lang?.privacy_policy}
                          </a>
                        </label>
                      </div>

                      <div className="mb-4">
                        <button
                          type="submit"
                          className="py-3 btn-blue w-100 border-0"
                          disabled={!DisableBtn || isLoading}
                        >
                          {isLoading ? (
                            <div className="loader"></div>
                          ) : (
                            lang?.lets_go
                          )}
                        </button>
                      </div>

                      <div className="text-center">
                        <p className="fs-16 roboto-medium text-gray">
                          {lang?.already_account}{" "}
                          <Link
                            onClick={() => setAccountType(null)}
                            className="fs-16 roboto-bold text-blue"
                          >
                            {lang?.login}
                          </Link>{" "}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Image Preview Modal */}
      <Modal
        isOpen={frontIdModal}
        toggle={toggle}
        external={FrontIdModalexternalCloseBtn}
        centered
        size={"lg"}
      >
        <ModalBody>
          {formData?.id_front && (
            <div className="text-center">
              <img
                alt="picture"
                className="img-fluid"
                src={frontImagePreview}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={backIdModal}
        toggle={() => setBackIdModal(!backIdModal)}
        external={backIdModalexternalCloseBtn}
        centered
        size={"lg"}
      >
        <ModalBody>
          {formData?.id_back && (
            <div className="text-center">
              <img alt="picture" className="img-fluid" src={backImagePreview} />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SignupForm;
