import { api, apiAdmin } from "./api";
import { toast } from 'react-toastify';

export const fetchEvents = async () => {
const {data} = JSON.parse(localStorage.getItem("SignupData"));

    // const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await apiAdmin.get(
            `user/web/event/home`,
            // { user_id,type:"all" },
            {
                headers: {
                  Authorization: `Bearer ${data.token}`, // Set the Authorization header
                },
              }
        );
        return response.data;
    } catch (error) {
        toast.error('Error fetching events:', error);
        throw error;
    }
};

export const fetchMyEvents = async ({ status }) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/events/my/${user_id}/${status}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching my events:', error);
        throw error;
    }
};

export const fetchJoinedEvents = async ({ status }) => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/events/joinedEvent/${user_id}/${status}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching joined events:', error);
        throw error;
    }
};

export const fetchSavedEvents = async () => {
    const user_id = JSON.parse(localStorage.getItem('SignupData'))?.data?.id;
    try {
        const response = await api.get(`api/events/favorite/${user_id}`);
        return response.data;
    } catch (error) {
        toast.error('Error fetching saved events:', error);
        throw error;
    }
};
