import React, { useState, useContext } from "react";
import { Col, Form, Row, Spinner } from "reactstrap";
import EyeIcon from "../../assets/images/icon/eye.png";
import axios from "axios";
import { PasswordChangeApi } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/RoutesUrl";
import { Language } from "../../App";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [inputType1, setInputType1] = useState(true);
  const [inputType2, setInputType2] = useState(true);
  const [inputType3, setInputType3] = useState(true);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoadingreset, setIsLoadingReset] = useState(false);
  const { lang } = useContext(Language);
  const navigate = useNavigate();

  const localData = JSON.parse(localStorage.getItem("SplashData"));
  const login_id = localData?.user?.id;

  const handleChangepassword = async (e) => {
    e.preventDefault();

    // Validate the new password
    if (newPassword.length !== 6) {
      setPasswordError(lang?.pass_must_exactly_6char_long);
      return;
    }

    // Validate the confirm password
    if (confirmNewPassword !== newPassword) {
      setConfirmPasswordError(lang?.pass_not_match);
      return;
    }

    setIsLoadingReset(true);
    try {
      const response = await axios.post(PasswordChangeApi, {
        old_password: oldPassword,
        password: newPassword,
        user_id: login_id,
      });

      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");

      // Check the response from the API
      if (response.data.status) {
        toast.success(lang?.pass_chang_success);
        setIsLoadingReset(false);
        sessionStorage.clear();
        localStorage.clear();
        navigate(LOGIN);
      } else {
        toast.error(response.data.action);
        setIsLoadingReset(false);
      }
    } catch (err) {
      toast.error(lang?.error_chang_pass, err);
      setIsLoadingReset(false);
    }
  };

  return (
    <div className="rounded pxy-20 bg-white w-xl-75 w-lg-100 box-shadow1 m-xl-0 m-lg-auto m-md-auto m-sm-auto m-auto">
      <h3 className="d-flex justify-content-center mt--12 fs-20 fs-md-16 fw-bold">
        {lang?.change_password}
      </h3>
      <div className="d-flex flex-column gap-4 mt-4">
        <Form onSubmit={handleChangepassword}>
          <Row className="mb-4">
            <Col className="position-relative">
              <label className="fs-16 roboto-bold ps-2 pb-1">
                {lang?.old_pass}
              </label>
              <div className="position-relative">
                <img
                  src={EyeIcon}
                  onClick={() => {
                    setInputType1(!inputType1);
                  }}
                  className="password-eye cursor"
                  alt="picture"
                />
                <input
                  type={inputType1 ? "password" : "text"}
                  className="form-control py-3 form-box rounded"
                  placeholder="******"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="position-relative">
              <label className="fs-16 roboto-bold ps-2 pb-1">
                {lang?.new_password}
              </label>
              <div className="position-relative">
                <img
                  src={EyeIcon}
                  onClick={() => {
                    setInputType2(!inputType2);
                  }}
                  className="password-eye cursor"
                  alt="picture"
                />
                <input
                  type={inputType2 ? "password" : "text"}
                  className="form-control py-3 form-box rounded"
                  placeholder="******"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    // Clear the password error message when the user types
                    setPasswordError("");
                  }}
                />
              </div>
              {passwordError && (
                <div className="text-danger fs-12 mt-2">{passwordError}</div>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="position-relative">
              <label className="fs-16 roboto-bold ps-2 pb-1">
                {lang?.confirm_password}
              </label>
              <div className="position-relative">
                <img
                  src={EyeIcon}
                  onClick={() => {
                    setInputType3(!inputType3);
                  }}
                  className="password-eye cursor"
                  alt="picture"
                />
                <input
                  type={inputType3 ? "password" : "text"}
                  className="form-control py-3 form-box rounded"
                  placeholder="******"
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    // Clear the confirm password error message when the user types
                    setConfirmPasswordError("");
                  }}
                />
              </div>
              {confirmPasswordError && (
                <div className="text-danger fs-12 mt-2">
                  {confirmPasswordError}
                </div>
              )}
            </Col>
          </Row>

          <button
            type="submit"
            className="bg-blue p-10 w-100 rounded text-white text-center border-0 opacity_50"
          >
            {isLoadingreset ? (
              <div>
                <Spinner />
              </div>
            ) : (
              lang?.reset
            )}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
