import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import NavBar from "../components/NavBar";
import ProfileInfoCard from "../components/feeds/ProfileInfoCard";
import SuggestedProfiles from "../components/feeds/SuggestedProfiles";
import PostForm from "../components/feeds/PostForm";
import {
  AddCommentApi,
  AddCommentRepliesApi,
  BaseURL,
  DeleteCommentApi,
  DeletePostApi,
  EditPostApi,
  FeedPostApi,
  GetCommentsApi,
  GetRepliesApi,
  LoginApi,
  ReportJobApi,
  RepostApi,
  ReserveApi,
} from "../constants/API";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { BsSendFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import ImageWithAspectRatio from "../components/common/ImagewithAspectRatio";
import VideoWithAspectRatio from "../components/common/VideowithAspectRatio.tsx";
import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import InfiniteScroll from "react-infinite-scroller";
import { LOGIN, PROFILE } from "../constants/RoutesUrl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageComponent from "../components/common/ImageComponent.jsx";
import {
  FeedDataRefetch,
  FeedType,
  Language,
  PostDetailContext,
  ScrollCordinates,
} from "../App.js";
import PostHeader from "../components/feeds/PostHeader.jsx";
import PostFooter from "../components/feeds/PostFooter.jsx";
import { ShimmerSocialPost } from "react-shimmer-effects";
import LiveFeeds from "../components/feeds/LiveFeeds.jsx";
import FeedAdvertisement from "../components/feeds/FeedAdvertisement.jsx";
import SuggestedCoursesEvents from "../components/feeds/SuggestedCoursesEvents.jsx";
import FeedSponsorPost from "../components/feeds/FeedSponsorPost.jsx";
import FeedPostFilter from "../components/feeds/FeedPostFilter.jsx";
import ProfileSuggestionSlider from "../components/feeds/ProfileSuggestionSlider.jsx";
import {
  fetchFeedData,
  likePostApi,
  savePostApi,
} from "../services/feedApis.js";
import { useQuery } from "react-query";

const Feed = () => {
  const { scrollPosition, updateScrollPosition } = useContext(ScrollCordinates);
  const { feedType } = useContext(FeedType);
  const { refetch } = useContext(FeedDataRefetch);
  const { updatePostDetail } = useContext(PostDetailContext);

  sessionStorage.setItem("feed_url", window.location.href);
  const navigate = useNavigate();

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
const {data:userToken} = JSON.parse(localStorage.getItem("SignupData"));

console.log("userData.token",userToken?.token)
  // New Code
  const [leftSideAds, setLeftSideAds] = useState([]);
  const [popularCourses, setPopularCourses] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [rightSideAds, setRightSideAds] = useState([]);
  const [profileInfo, setProfileInfo] = useState({});
  const [suggestedEvents, setSuggestedEvents] = useState([]);
  const [joinedEvents, setJoinedEvents] = useState([]);
  const [contest_obj, setContestObj] = useState({});
  const [new_contest_obj, setNewContestObj] = useState({});
  //

  const [page, setPage] = useState(1);
  const [selectedPoll, setSelectedPoll] = useState(0);
  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [postComments, setPostComments] = useState({});
  const [comment, setComment] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [postId, setPostId] = useState("");
  const [commentReplies, setCommentReplies] = useState([]);
  const [replyDropdown, setReplyDropdown] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [replyUser, setReplyUser] = useState({});
  const [postDropDown, setPostDropDown] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);
  const [editPostModal, setEditPostModal] = useState(false);
  const [editPostInput, setEditPostInput] = useState("");
  const [reportInput, setReportInput] = useState("");
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeUsers, setLikeUsers] = useState({});
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likePage, setLikePage] = useState(1);
  const [setHostModal, updateSetHostModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [inviteData, setInviteData] = useState({});
  const [invitePage, setInvitePage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const [loading, setLoading] = useState(false);

  const { lang } = useContext(Language);

  const handleInvitationList = useCallback(
    async (webinar_id) => {
      try {
        const response = await axios.get(
          `${BaseURL}api/webinar/reserved/users/${webinar_id}/${user_id}?page=${invitePage}`
        );
        if (inviteList.length) {
          setInviteList((prevList) => prevList.concat(response.data.data.data));
          setInviteData(response.data.data);
          setInvitePage((prevPage) => prevPage + 1);
          return;
        }
        if (response.data.status) {
          setInviteList(response.data.data.data);
          setInviteData(response.data.data);
          setInvitePage((prevPage) => prevPage + 1);
          setInviteSpin(false);
        } else {
          toast.error(response.data.error);
          setInviteSpin(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [inviteList, invitePage, user_id]
  );

  const handleNewPageData = useCallback(async () => {
    setIsLoading(true);
    if(data.token){
    try {
      const response = await axios.get(
        `${FeedPostApi + user_id + "/" + feedType}?page=${page + 1}`
      );
      const updatedValue = response.data.data.data;
      setPostData((prevState) => [...prevState, ...updatedValue]);
      const updated = [...postData, ...updatedValue];
      sessionStorage.setItem("prevFeedData", JSON.stringify(updated));
      sessionStorage.setItem(
        "prevFeedDataDetails",
        JSON.stringify(response.data.data)
      );
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }}else{
      // navigate()
    }
  }, [page, postData, user_id]);

  const handleLikeUsers = useCallback(
    async (otherId) => {
      setLikeSpinner(true);
      setLikeUsersModal(true);
      try {
        const response = await axios.get(
          `${BaseURL}api/post/likes/${otherId}/${user_id}`
        );
        if (response.data.status) {
          setLikeUsers(response.data.data);
        } else {
          toast.error(response.data.error);
          setLikeUsersModal(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLikeSpinner(false);
      }
    },
    [user_id]
  );

  const handleNewLikeUsers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BaseURL}api/post/likes/${postId}/${user_id}?page=${likePage + 1}`
      );
      if (response.data.status) {
        const update = likeUsers.data.concat(response.data.data.data);
        setLikeUsers((prevUsers) => ({ ...prevUsers, data: update }));
        setLikePage((prevPage) => prevPage + 1);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  }, [likePage, likeUsers, postId, user_id]);

  const indicatorStyles = useMemo(
    () => ({
      background: "#fff",
      width: 20,
      height: 4,
      display: "inline-block",
      margin: "0 4px",
      borderRadius: "1px",
    }),
    []
  );

  const handleReserveWebinar = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id
            ? { ...value, isReserve: !value.isReserve }
            : value
        );
        setPostData(updatedData);
        await axios.get(`${ReserveApi}${post_id}/${user_id}`);
      } catch (error) {
        console.error(error);
      }
    },
    [postData, user_id]
  );

  const handlePollAnswer = useCallback(
    async (post_id, answer) => {
      try {
        const response = await axios.post(`${BaseURL}api/poll/answer/submit`, {
          post_id,
          user_id,
          answer: answer + 1,
        });
        if (!response.data.status) {
          toast.error(response.data.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [user_id]
  );

  const handleLike = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map(
          (value) =>
            value.id === post_id && {
              ...value,
              isLike: !value.isLike,
              likes_count: value.isLike
                ? value.likes_count - 1
                : value.likes_count + 1,
            }
        );
        setPostData(updatedData);
        await likePostApi(post_id);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleSave = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id ? { ...value, isSave: !value.isSave } : value
        );
        setPostData(updatedData);
        const response = await savePostApi(post_id);
        if (!response.data.status) {
          toast.error(response.data.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleRepost = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id ? { ...value, isShare: !value.isShare } : value
        );
        setPostData(updatedData);
        const response = await axios.get(`${RepostApi}${post_id}/${user_id}`);
        if (response.data.status) {
          toast.success(response.data.action);
        } else {
          toast.error(response.data.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleGetComments = useCallback(
    async (post_id) => {
      setIsLoading(true);
      const id = post_id || postId;
      try {
        const response = await axios.get(
          `${GetCommentsApi}${id}/${user_id}?page=1`
        );
        if (response.data.status) {
          setPostComments(response.data.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postId, user_id]
  );

  const handleAddComment = useCallback(async () => {
    try {
      const response = await axios.post(
        `${AddCommentApi}${postId}/${user_id}`,
        { comment }
      );
      if (response.data.status) {
        setComment("");
        handleGetComments();
      }
    } catch (error) {
      console.error(error);
    }
  }, [comment, postId, user_id, handleGetComments]);

  const handleDeleteComment = useCallback(async () => {
    try {
      const response = await axios.get(
        `${DeleteCommentApi}${commentId}/delete`
      );
      if (response.data.status) {
        setCommentId("");
        setDeleteModal(false);
        handleGetComments();
      }
    } catch (error) {
      console.error(error);
    }
  }, [commentId, handleGetComments]);

  const handleGetReplies = useCallback(
    async (comment_id) => {
      try {
        const response = await axios.get(
          `${GetRepliesApi}${comment_id}/${user_id}`
        );
        if (response.data.status) {
          setCommentReplies(response.data.data);
          setReplyDropdown(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [user_id]
  );

  const handleAddReplies = useCallback(
    async (comment_id) => {
      try {
        const response = await axios.post(
          `${AddCommentRepliesApi}${postId}/${user_id}`,
          {
            parent: comment_id,
            comment,
          }
        );
        if (response.data.status) {
          setComment("");
          handleGetComments(postId);
          handleGetReplies(comment_id);
          setIsReply(false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [comment, postId, user_id, handleGetComments, handleGetReplies]
  );

  const handleSetHost = useCallback(async (webinarId, userId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BaseURL}api/webinar/make/host/${webinarId}/${userId}`
      );
      if (response.data.status) {
        setInviteList((prevState) =>
          prevState.map((value) =>
            value.id === userId ? { ...value, invite: !value.invite } : value
          )
        );
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeletePost = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${DeletePostApi}${dropDownId}/delete`);
      if (response.data.status) {
        toast.success("Post Deleted!");
        setPostDeleteModal(false);
        window.location.reload();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId]);

  const handlePostReport = useCallback(async () => {
    setIsLoading(true);
    try {
      const reportData = {
        report_id: dropDownId,
        user_id,
        report: reportInput,
        type: "post",
      };
      const response = await axios.post(ReportJobApi, reportData);
      if (response.data.status) {
        toast.success("Reported Successfully!");
        setReportModal(false);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId, reportInput, user_id]);

  const handleEditPost = useCallback(async () => {
    setIsLoading(true);
    try {
      const editData = { caption: editPostInput, post_id: dropDownId };
      const response = await axios.post(EditPostApi, editData);
      if (response.data.status) {
        toast.success("Edited Successfully!");
        setEditPostModal(false);
        setTimeout(() => window.location.reload(), 1000);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId, editPostInput]);

  useLayoutEffect(() => {
    const scrollDiv = document.querySelector(".find_scroll");
    const handleScroll = () => {
      updateScrollPosition(scrollDiv.scrollTop);
    };

    scrollDiv.addEventListener("scroll", handleScroll);

    const storedScrollPosition = scrollPosition;

    if (storedScrollPosition) {
      const position = parseInt(storedScrollPosition);
      setTimeout(() => {
        scrollDiv.scrollTo({
          top: position,
          behavior: "instant",
        });
      }, 400);
    } else {
      scrollDiv.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }

    return () => {
      scrollDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    const fetchFeedData = async () => {
      try {
        const prevFeedData = sessionStorage.getItem("prevFeedData");
        const prevFeedDataDetails = sessionStorage.getItem(
          "prevFeedDataDetails"
        );
        if (prevFeedData && prevFeedDataDetails) {
          setData(JSON.parse(prevFeedDataDetails));
          setPostData(JSON.parse(prevFeedData));
        } else {
          setLoading(true);
          const requestData = {
            type: feedType || "all",
          };
          
          const response = await axios.get(
            `${FeedPostApi + user_id + "/" + feedType}?page=1`,
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.status) {
            sessionStorage.setItem(
              "prevFeedDataDetails",
              JSON.stringify(response.data.data)
            );
            sessionStorage.setItem(
              "prevFeedData",
              JSON.stringify(response.data.data.data)
            );
            setData(response.data.data);
            setPostData(response.data.data.data);
          } else {
            toast.error(response.data.error);
          }
        
          setLoading(false);
        }
      } catch (error) {
        toast.error("Error fetching feed data:", error);
      }
    };

    fetchFeedData()
  
  }, [page, user_id, feedType, refetch]);

  useEffect(() => {
    const clearSessionStorageOnReload = () => {
      ["prevFeedData", "prevFeedDataDetails"].forEach((key) =>
        sessionStorage.removeItem(key)
      );
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  // New Code 26/8/2024
  useQuery({
    queryKey: ["feedData"],
    queryFn: fetchFeedData,
    // retry: false, // Disable automatic retries
    // enabled: !!userToken.token,
    onSuccess: (data) => {
      if (data?.status) {
        // console.log("data",data)
        setLeftSideAds(data?.ads?.home_left);
        setPopularCourses(data.left.popular);
        setPurchasedCourses(data.left.purchased);
        setRightSideAds(data?.ads?.home_right);
        setProfileInfo(data.right.profile);
        setSuggestedEvents(data.right.suggested);
        setJoinedEvents(data.right.joined);
        setContestObj(data.home.contest_obj);
        setNewContestObj(data.home.new_contest_obj);
      } else {
        toast.error(data.error);
        sessionStorage.clear();
        localStorage.clear();
        navigate(LOGIN)
      }
    },
    onError: (err) => {
      // navigate(LOGIN, { replace: true })
  },
  });

  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row
          className="d-flex justify-content-center"
          style={{ paddingTop: "4rem" }}
        >
          <div className="col-md-3 d-flex flex-column flex-wrap align-content-end">
            <LiveFeeds />
            <FeedAdvertisement ads={leftSideAds} />
            <SuggestedCoursesEvents
              type="courses"
              popularCourses={popularCourses}
              purchasedCourses={purchasedCourses}
            />
          </div>
          <div
            className="find_scroll pt-3 col-md-6"
            style={{
              maxWidth: "595px",
              paddingBottom: "5rem",
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={handleNewPageData}
              hasMore={data.current_page !== data.last_page}
              loader={<ShimmerSocialPost type="image" />}
              useWindow={true}
            >
              <>
                <PostForm />
                <FeedSponsorPost
                  loading={!contest_obj?.image}
                  new_contest_obj={contest_obj}
                />
                <FeedPostFilter />
                {!isLoading && loading ? (
                  <ShimmerSocialPost type="image" />
                ) : (
                  postData &&
                  postData.map((value, index) =>
                    index === 3 ? (
                      <ProfileSuggestionSlider />
                    ) : value?.type === "video" || value?.type === "image" ? (
                      <>
                        <div
                          key={value.id}
                          className="bg-white my-3 py-3 rounded"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image &&
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white dropdown-avatar-shadow br-16 ms-3"
                                      style={{
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                        padding: "0.8px",
                                      }}
                                    >
                                      <ImageComponent
                                        src={BaseURL + value.repost_user.image}
                                        loader_height="2.5rem"
                                        loader_width="2.5rem"
                                        compClassName="br-16 img-fluid"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        {value.repost_user.name}{" "}
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <PostHeader
                            postDetails={value}
                            postDropDown={postDropDown}
                            setPostDropDown={setPostDropDown}
                            setReportModal={setReportModal}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                          />
                          <Row
                            className="pt-3"
                            onClick={() => {
                              updatePostDetail(value);
                            }}
                          >
                            <Col>
                              {value.type === "video" ? (
                                <>
                                  {value.media &&
                                    value.media.map((media) => (
                                      <VideoWithAspectRatio
                                        src={BaseURL + media}
                                        aspectRatio={value.sizes}
                                      />
                                    ))}
                                </>
                              ) : value.media && value.media.length === 1 ? (
                                value.media.map((media, index) => (
                                  <ImageWithAspectRatio
                                    key={index}
                                    height={555 / value.sizes}
                                    src={BaseURL + media}
                                    alt="..."
                                  />
                                ))
                              ) : (
                                <Carousel
                                  renderArrowNext={(onClickHandler, hasNext) =>
                                    hasNext && (
                                      <IoIosArrowDroprightCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                                        size={30}
                                        color="white"
                                        onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderArrowPrev={(onClickHandler, hasPrev) =>
                                    hasPrev && (
                                      <IoIosArrowDropleftCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                                        size={30}
                                        style={{ zIndex: "1" }}
                                        color="white"
                                        onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderIndicator={(
                                    onClickHandler,
                                    isSelected,
                                    index,
                                    label
                                  ) => {
                                    return (
                                      <>
                                        {isSelected ? (
                                          <li
                                            style={{
                                              ...indicatorStyles,
                                              background: "#00c5de",
                                            }}
                                            aria-label={`Selected: ${label} ${
                                              index + 1
                                            }`}
                                            title={`Selected: ${label} ${
                                              index + 1
                                            }`}
                                          />
                                        ) : (
                                          <li
                                            style={indicatorStyles}
                                            onClick={onClickHandler}
                                            onKeyDown={onClickHandler}
                                            value={index}
                                            key={index}
                                            role="button"
                                            tabIndex={0}
                                            title={`${label} ${index + 1}`}
                                            aria-label={`${label} ${index + 1}`}
                                          />
                                        )}
                                      </>
                                    );
                                  }}
                                  autoPlay={false}
                                  infiniteLoop={false}
                                  showIndicators={true}
                                  showThumbs={false}
                                  showStatus={false}
                                >
                                  {value.media &&
                                    value.media.map((data, index) => (
                                      <ImageWithAspectRatio
                                        key={index}
                                        height={555 / value.sizes}
                                        src={BaseURL + data}
                                        alt="..."
                                      />
                                    ))}
                                </Carousel>
                              )}
                            </Col>
                          </Row>
                          {value?.caption && (
                            <>
                              <Row className="p-2">
                                <Col>
                                  <ReadMore text={value.caption} />
                                </Col>
                              </Row>
                              <hr className="m-0" />
                            </>
                          )}
                          <PostFooter
                            likes_count={value.likes_count}
                            setPostId={setPostId}
                            original_id={value.original_id}
                            handleLikeUsers={handleLikeUsers}
                            like_users_images={value.like_users_images}
                            handleLike={handleLike}
                            isLike={value.isLike}
                            handleGetComments={handleGetComments}
                            setPostModal={setPostModal}
                            comments_count={value.comments_count}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                          />
                        </div>
                      </>
                    ) : value.type === "webinar" ? (
                      <>
                        <div
                          key={value.id}
                          className="bg-white my-3 py-3 br-6"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          <PostHeader
                            postDetails={value}
                            postDropDown={postDropDown}
                            setPostDropDown={setPostDropDown}
                            setReportModal={setReportModal}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                          />

                          <Row
                            className="pt-3 pb-2"
                            onClick={() => {
                              updatePostDetail(value);
                            }}
                          >
                            <Col>
                              {value.media.map((media, index) => (
                                <ImageWithAspectRatio
                                  key={index}
                                  height={555 / value.sizes}
                                  src={BaseURL + media}
                                  alt="..."
                                />
                              ))}
                            </Col>
                          </Row>
                          {value?.caption || value?.title ? (
                            <>
                              <Row className="p-2">
                                <Col>
                                  <h4>
                                    {value?.title && value.title.length > 26
                                      ? value.title.slice(0, 26) + "..."
                                      : value.title}
                                  </h4>
                                  <ReadMore text={value.caption} />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <Row className="justify-content-between px-3">
                            <Col className="align-self-center">
                              <p className="mb-0 text-warning">
                                {lang?.scheduled_on}
                              </p>
                              <small className="mb-0">
                                {value?.scheduled}
                                {", "}
                                <ConvertToTime tstamp={value.start_timestamp} />
                              </small>
                            </Col>
                            <Col className="text-end align-self-center">
                              {value.status !== 0 && (
                                <button
                                  className="px-4 py-2 fw-bold text-white btn btn-warning"
                                  onClick={
                                    value.user.id === user_id
                                      ? value.status === 0
                                        ? null
                                        : value.status === 2
                                        ? "Webinar Ended"
                                        : () => {
                                            if (value.user.id === user_id) {
                                              window.open(
                                                value.meeting_host_url,
                                                "_blank"
                                              );
                                            } else {
                                              if (value.isHost) {
                                                window.open(
                                                  value.meeting_host_url,
                                                  "_blank"
                                                );
                                              } else {
                                                window.open(
                                                  value.meeting_url,
                                                  "_blank"
                                                );
                                              }
                                            }
                                          }
                                      : value.status === 0
                                      ? value.isReserve
                                        ? () => handleReserveWebinar(value.id)
                                        : () => handleReserveWebinar(value.id)
                                      : value.status === 1
                                      ? value.isReserve
                                        ? () => {
                                            if (value.user.id === user_id) {
                                              window.open(
                                                value.meeting_host_url,
                                                "_blank"
                                              );
                                            } else {
                                              if (value.meeting_url !== "") {
                                                window.open(
                                                  value.meeting_url,
                                                  "_blank"
                                                );
                                              } else {
                                                toast.info("Not started yet.");
                                              }
                                            }
                                          }
                                        : () => handleReserveWebinar(value.id)
                                      : () => toast.info("Webinar is Ended.")
                                  }
                                >
                                  {value.user.id === user_id
                                    ? value.status === 0
                                      ? "Not Started Yet"
                                      : value.status === 2
                                      ? "Webinar Ended"
                                      : "Start Webinar"
                                    : value.status === 0
                                    ? value.isReserve
                                      ? "Unreserve"
                                      : "Reserve Seat"
                                    : value.status === 1
                                    ? value.isReserve
                                      ? "Join Webinar"
                                      : "Reserve Seat"
                                    : "Webinar Ended"}
                                </button>
                              )}
                            </Col>
                          </Row>
                        </div>
                        {/* Set Host Modal */}
                        <Modal
                          isOpen={setHostModal}
                          centered
                          zIndex={9}
                          scrollable
                          size="lg"
                        >
                          <ModalHeader
                            toggle={() => {
                              updateSetHostModal(false);
                            }}
                          >
                            {lang?.invite}
                          </ModalHeader>
                          <ModalBody>
                            {inviteSpin ? (
                              <Row>
                                <Col className="text-center">
                                  <Spinner />
                                </Col>
                              </Row>
                            ) : (
                              <InfiniteScroll
                                pageStart={0}
                                loadMore={() =>
                                  handleInvitationList(value.original_id)
                                }
                                hasMore={
                                  inviteData?.current_page !==
                                  inviteData?.last_page
                                    ? true
                                    : false
                                }
                                loader={
                                  <Row>
                                    <Col className="text-center">
                                      <Spinner size="sm" />
                                    </Col>
                                  </Row>
                                }
                                useWindow={false}
                              >
                                <Row>
                                  {inviteList.map((val) => (
                                    <Col xs={12}>
                                      <div className="border border-1 border-inherit rounded my-2">
                                        <div className="d-flex m-3 justify-content-between">
                                          <div className="d-flex gap-2">
                                            <Link
                                              to={PROFILE + "/" + val.id}
                                              className="bg-white dropdown-avatar-shadow align-self-center"
                                              style={{
                                                borderRadius: "19px",
                                                maxWidth: "4rem",
                                                maxHeight: "4rem",
                                                padding: "1.8px",
                                              }}
                                            >
                                              <img
                                                src={
                                                  val?.image
                                                    ? BaseURL + val.image
                                                    : Avatar
                                                }
                                                alt="picture"
                                                className="img-fluid "
                                                style={{
                                                  borderRadius: "17px",
                                                }}
                                              />
                                            </Link>
                                            <div className="align-self-center">
                                              <Link
                                                to={PROFILE + "/" + val.id}
                                                className="fs-14 roboto-bold text-dark"
                                              >
                                                {val.name}
                                              </Link>
                                              <p className="mb-0 fs-12 roboto-regular">
                                                {val.user_name}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="align-self-center">
                                            <button
                                              className="btn btn-white border border-1 px-3 py-1"
                                              disabled={isLoading}
                                              onClick={() => {
                                                handleSetHost(
                                                  value.original_id,
                                                  val.id
                                                );
                                              }}
                                            >
                                              <span className="roboto-medium">
                                                {val.invite
                                                  ? lang?.undo
                                                  : "Set Host"}
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </InfiniteScroll>
                            )}
                          </ModalBody>
                        </Modal>
                        {/* End set host modal */}
                      </>
                    ) : value.type === "clinical-image" ? (
                      <>
                        <div
                          key={value.id}
                          className="bg-white my-3 py-3 br-6"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image &&
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white dropdown-avatar-shadow br-14 ms-3"
                                      style={{
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                        padding: "0.8px",
                                      }}
                                    >
                                      <ImageComponent
                                        src={BaseURL + value.repost_user.image}
                                        loader_height="2.5rem"
                                        loader_width="2.5rem"
                                        compClassName="br-12 img-fluid"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        {value.repost_user.name}{" "}
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <PostHeader
                            postDetails={value}
                            setPostDropDown={setPostDropDown}
                            postDropDown={postDropDown}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                            setReportModal={setReportModal}
                          />
                          <Row
                            className="pt-3 pb-2"
                            onClick={() => {
                              updatePostDetail(value);
                            }}
                          >
                            <Col>
                              {value?.media && value?.media.length === 1 ? (
                                value?.media.map((data, index) => (
                                  <ImageWithAspectRatio
                                    key={index}
                                    height={555 / value?.sizes}
                                    src={BaseURL + data}
                                    alt="..."
                                  />
                                ))
                              ) : (
                                <Carousel
                                  renderArrowNext={(onClickHandler, hasNext) =>
                                    hasNext && (
                                      <IoIosArrowDroprightCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                                        size={30}
                                        color="white"
                                        onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderArrowPrev={(onClickHandler, hasPrev) =>
                                    hasPrev && (
                                      <IoIosArrowDropleftCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                                        size={30}
                                        style={{ zIndex: "1" }}
                                        color="white"
                                        onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderIndicator={(
                                    onClickHandler,
                                    isSelected,
                                    index,
                                    label
                                  ) => {
                                    if (isSelected) {
                                      return (
                                        <li
                                          style={{
                                            ...indicatorStyles,
                                            background: "#00c5de",
                                          }}
                                          aria-label={`Selected: ${label} ${
                                            index + 1
                                          }`}
                                          title={`Selected: ${label} ${
                                            index + 1
                                          }`}
                                        />
                                      );
                                    }
                                    return (
                                      <li
                                        style={indicatorStyles}
                                        onClick={onClickHandler}
                                        onKeyDown={onClickHandler}
                                        value={index}
                                        key={index}
                                        role="button"
                                        tabIndex={0}
                                        title={`${label} ${index + 1}`}
                                        aria-label={`${label} ${index + 1}`}
                                      />
                                    );
                                  }}
                                  autoPlay={false}
                                  infiniteLoop={false}
                                  showIndicators={true}
                                  showThumbs={false}
                                  showStatus={false}
                                >
                                  {value?.media &&
                                    value?.media.map((data, index) => (
                                      <ImageWithAspectRatio
                                        key={index}
                                        height={555 / value?.sizes}
                                        src={BaseURL + data}
                                        alt="..."
                                      />
                                    ))}
                                </Carousel>
                              )}
                            </Col>
                          </Row>
                          {value?.caption || value?.title ? (
                            <>
                              <Row className="justify-content-between p-2">
                                <Col className="align-self-center">
                                  <h4 className="mb-0">
                                    {value?.title && value.title.length > 26
                                      ? value.title.slice(0, 26) + "..."
                                      : value.title}
                                  </h4>
                                </Col>
                                {value?.pdf && (
                                  <Col className="text-end">
                                    <button
                                      className="border-0 px-3 py-1 text-white btn-blue"
                                      onClick={() => {
                                        window.open(
                                          BaseURL + value.pdf,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      {lang?.view_Case_Study}
                                    </button>
                                  </Col>
                                )}
                              </Row>
                              <Row className="px-2">
                                <Col>
                                  <ReadMore text={value.caption} />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <PostFooter
                            likes_count={value.likes_count}
                            setPostId={setPostId}
                            original_id={value.original_id}
                            handleLikeUsers={handleLikeUsers}
                            like_users_images={value.like_users_images}
                            handleLike={handleLike}
                            isLike={value.isLike}
                            handleGetComments={handleGetComments}
                            setPostModal={setPostModal}
                            comments_count={value.comments_count}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                          />
                        </div>
                      </>
                    ) : value?.type === "poll" ? (
                      <>
                        <div
                          className="bg-white my-3 py-3 br-6"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image &&
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white dropdown-avatar-shadow br-14 ms-3"
                                      style={{
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                        padding: "0.8px",
                                      }}
                                    >
                                      <ImageComponent
                                        loader_height="2.5rem"
                                        loader_width="2.5rem"
                                        src={BaseURL + value.repost_user.image}
                                        compClassName="br-12 img-fluid"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        {value.repost_user.name}{" "}
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <PostHeader
                            postDetails={value}
                            postDropDown={postDropDown}
                            setPostDropDown={setPostDropDown}
                            setReportModal={setReportModal}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                          />
                          <Row className="pt-3 pb-2">
                            <Col>
                              {value?.media &&
                                value?.media.map((data, index) => (
                                  <ImageWithAspectRatio
                                    key={index}
                                    height={555 / value?.sizes}
                                    src={BaseURL + data}
                                    alt="..."
                                  />
                                ))}
                            </Col>
                          </Row>
                          {value?.title && (
                            <>
                              <Row className="px-3 py-2">
                                <Col>
                                  <p className="roboto-medium m-0 fs-20 fs-md-15">
                                    {value?.title}
                                  </p>
                                </Col>
                              </Row>
                              <hr className="m-0" />
                            </>
                          )}

                          {[
                            value?.option_1,
                            value?.option_2,
                            value?.option_3,
                            value?.option_4,
                          ].map((data, index) => (
                            <div className="mx-3 my-3" key={index}>
                              {data !== "" && (
                                <div
                                  className="p-3 cursor"
                                  onClick={() => {
                                    setSelectedPoll(index);
                                    handlePollAnswer(value.original_id, index);
                                  }}
                                  style={{
                                    border: "1px solid #00c5de",
                                    borderRadius: "10px",
                                    background: "rgba(0, 197, 222,0.1)",
                                  }}
                                >
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <h5 className="m-0 fs-md-15">{data}</h5>
                                    </div>
                                    <div className="d-flex justify-content-end gap-2">
                                      <label
                                        className="form-check-label"
                                        style={{ width: "35px" }}
                                      >
                                        {value.answer_percentage[index]} %
                                      </label>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          checked={selectedPoll === index}
                                          value={index}
                                          name="poll"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                          <hr className="mb-0" />

                          <PostFooter
                            likes_count={value.likes_count}
                            setPostId={setPostId}
                            original_id={value.original_id}
                            handleLikeUsers={handleLikeUsers}
                            like_users_images={value.like_users_images}
                            handleLike={handleLike}
                            isLike={value.isLike}
                            handleGetComments={handleGetComments}
                            setPostModal={setPostModal}
                            comments_count={value.comments_count}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  )
                )}
              </>
            </InfiniteScroll>
          </div>
          <div className="col-md-3">
            <SuggestedProfiles />
            <ProfileInfoCard profileInfo={profileInfo} />
            <FeedAdvertisement ads={rightSideAds} />
            <SuggestedCoursesEvents
              type="events"
              suggestedEvents={suggestedEvents}
              joinedEvents={joinedEvents}
            />
          </div>
        </Row>
      </Container>

      {/* Post Modal */}
      <Offcanvas
        direction="end"
        scrollable
        isOpen={postModal}
        zIndex={9}
        style={{ width: "480px" }}
        toggle={() => {
          setPostModal(false);
        }}
      >
        <OffcanvasHeader
          className="bg-grey"
          toggle={() => {
            setPostModal(false);
          }}
        >
          {lang?.comments}
        </OffcanvasHeader>
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <OffcanvasBody
          className="bg-grey scroll-hide"
          style={{ paddingBottom: "5rem" }}
        >
          {isLoading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                {postComments?.data &&
                  postComments.data.map((content, index) => (
                    <div className="bg-white my-3 p-2 br-12" key={index}>
                      <div className="d-flex gap-3">
                        {/* Profile Image */}
                        <div>
                          <Link
                            to={PROFILE + "/" + content.user.id}
                            className="d-flex gap-2 text-dark w-100"
                          >
                            <div
                              className="bg-white dropdown-avatar-shadow align-self-center rounded"
                              style={{
                                maxWidth: "8rem",
                                maxHeight: "8rem",
                                padding: "1.8px",
                              }}
                            >
                              <ImageComponent
                                src={
                                  content.user.image
                                    ? BaseURL + content.user.image
                                    : Avatar
                                }
                                compClassName="img-fluid rounded"
                                loader_height="8rem"
                                loader_width="8rem"
                              />
                            </div>
                          </Link>
                        </div>
                        {/* Div End Profile Image */}
                        {/* Profile Detials */}
                        <div>
                          <Link
                            to={PROFILE + "/" + content.user.id}
                            className="roboto-bold mb-0 text-dark fs-16"
                          >
                            {content.user.name}
                          </Link>
                          <p className="roboto-regular text-gray fs-14">
                            {content.comment}
                          </p>
                          <div className="d-flex gap-4">
                            <p className="roboto-medium fs-12">
                              {<TimestampConverter timestamp={content.time} />}
                            </p>
                            <p
                              className="roboto-medium text-blue cursor fs-12"
                              onClick={() => {
                                setIsReply(true);
                                setReplyUser(content.user);
                                setCommentId(JSON.stringify(content.id));
                              }}
                            >
                              {lang?.reply}
                            </p>
                            <p
                              className="roboto-medium text-red cursor fs-12"
                              onClick={() => {
                                setCommentId(JSON.stringify(content.id));
                                setDeleteModal(true);
                              }}
                            >
                              {lang?.delete}
                            </p>
                          </div>
                        </div>
                        {/* Div ended Profile Detials */}
                      </div>

                      {content.replies > 0 && (
                        <div>
                          <p
                            className="mb-0 fw-bold text-end text-gray cursor fs-12 me-3"
                            onClick={() => {
                              if (content.id === commentId) {
                                setCommentId("");
                                setCommentReplies([]);
                              } else {
                                setCommentId(content.id);
                                handleGetReplies(content.id);
                              }
                            }}
                          >
                            {content.id === commentId ? "Hide" : "View"}{" "}
                            {content.replies}{" "}
                            {content.replies > 1 ? "replies" : "reply"}
                          </p>

                          {content.id === commentId &&
                            commentReplies.map((val) => {
                              return (
                                <>
                                  <div>
                                    <Collapse horizontal isOpen={true}>
                                      <div
                                        className="mt-4 pt-3 ms-5"
                                        key={val.id}
                                      >
                                        <div className="d-flex gap-3">
                                          {/* Profile Image */}
                                          <div>
                                            <div
                                              className="bg-white dropdown-avatar-shadow br-16 cursor"
                                              style={{
                                                maxWidth: "3rem",
                                                maxHeight: "3rem",
                                                padding: "0.8px",
                                              }}
                                            >
                                              <ImageComponent
                                                src={
                                                  val.user.image
                                                    ? BaseURL + val.user.image
                                                    : Avatar
                                                }
                                                loader_height="3rem"
                                                loader_width="3rem"
                                                compClassName="br-14 img-fluid"
                                              />
                                            </div>
                                          </div>
                                          {/* Div End Profile Image */}
                                          {/* Profile Detials */}
                                          <div>
                                            <p className="roboto-bold mb-0 cursor fs-16">
                                              {val.user.name}
                                            </p>
                                            <p className="roboto-regular text-gray fs-14">
                                              {val.comment}
                                            </p>
                                            <div className="d-flex gap-4">
                                              <p className="roboto-medium fs-12">
                                                {
                                                  <TimestampConverter
                                                    timestamp={val.time}
                                                  />
                                                }
                                              </p>
                                              <p
                                                className="roboto-medium text-blue cursor fs-12"
                                                onClick={() => {
                                                  setIsReply(true);
                                                  setReplyUser(val.user);
                                                  setCommentId(
                                                    JSON.stringify(content.id)
                                                  );
                                                }}
                                              >
                                                {lang?.reply}
                                              </p>
                                              <p
                                                className="roboto-medium text-red cursor fs-12"
                                                onClick={() => {
                                                  setCommentId(
                                                    JSON.stringify(val.id)
                                                  );
                                                  setDeleteModal(true);
                                                }}
                                              >
                                                {lang?.delete}
                                              </p>
                                            </div>
                                          </div>
                                          {/* Div ended Profile Detials */}
                                        </div>
                                      </div>
                                    </Collapse>
                                    <hr
                                      className="ms-5"
                                      style={{ opacity: "0.1" }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>

              {/* if NO comments available */}
              {postComments.data && postComments.data.length === 0 && (
                <div className="text-center">
                  <h3 className="text-gray">{lang?.there_no_comment}</h3>
                  <p className="text-gray">{lang?.no_one_commented_post}</p>
                </div>
              )}
            </>
          )}

          {/* Comment input section */}
          <div className="bottom-0 position-absolute m-auto end-0 start-0">
            {isReply && (
              <Row>
                <Col
                  className="d-flex justify-content-between bg-blue br-12"
                  style={{ marginLeft: "1.3rem", marginRight: "6.3rem" }}
                >
                  <h6 className="mb-0 py-2 text-white">
                    {lang?.replying_to} {replyUser.name}
                  </h6>
                  <div
                    className="align-self-center cursor"
                    onClick={() => setIsReply(false)}
                  >
                    <MdOutlineCancel color="white" size={20} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex">
                <input
                  type="text"
                  placeholder={lang?.write_your_coment}
                  className="form-control m-2 py-3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className="align-self-center">
                  <button
                    type="button"
                    disabled={comment === "" ? true : false}
                    className="border-0 px-4 py-3 btn-blue me-2 ms-1"
                    onClick={() => {
                      isReply
                        ? handleAddReplies(commentId)
                        : handleAddComment();
                    }}
                  >
                    <BsSendFill size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Comment input section */}
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={deleteModal} centered zIndex={9}>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_comment}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_comment_able_comment}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeleteComment}
            >
              {lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={postDeleteModal}
        toggle={() => setPostDeleteModal(!postDeleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setPostDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={reportModal}
        centered
        zIndex={9}
        toggle={() => setReportModal(!reportModal)}
      >
        <ModalHeader>{lang?.report_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.modal_enter_report_para}</h6>

          <textarea
            rows="7"
            className="form-control"
            placeholder={lang?.enter_your_report}
            onChange={(e) => setReportInput(e.target.value)}
          ></textarea>

          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setReportModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              onClick={() => {
                handlePostReport();
              }}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editPostModal}
        toggle={() => setEditPostModal(!editPostModal)}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditPostModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={likeUsersModal}
        toggle={() => setLikeUsersModal(!likeUsersModal)}
        centered
        zIndex={9}
      >
        <ModalHeader toggle={() => setLikeUsersModal(false)}>
          {lang?.liked_users}
        </ModalHeader>
        <ModalBody>
          {likeSpinner ? (
            <Col className="my-4 text-center">
              <Spinner />
            </Col>
          ) : (
            <div>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewLikeUsers}
                hasMore={likeUsers.current_page !== likeUsers.last_page}
                loader={
                  <Col className="text-center">
                    <Spinner />
                  </Col>
                }
                useWindow={false}
              >
                {likeUsers.data &&
                  likeUsers.data.map((value, index) => (
                    <>
                      <Link
                        onClick={() => {
                          navigate(PROFILE + "/" + value.id);
                        }}
                        className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                        key={index}
                      >
                        <div className="d-flex gap-2">
                          <div
                            className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                            style={{
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? BaseURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="roboto-bold text-dark fs-17 search-post-name">
                              {value.name}
                            </div>
                            <p className="roboto-regular mb-0 fs-12 search-post-name">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Feed;

function TimestampConverter({ timestamp }) {
  const dt = new Date(timestamp * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dt.toLocaleString("en-US", options);

  return <div>{formattedDate}</div>;
}
export { TimestampConverter };

export const ReadMore = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const { lang } = useContext(Language);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const processTextWithHashtags = (text) => {
    return text.split(/(#[\w]+)/).map((part, index) => {
      if (part.startsWith("#")) {
        return (
          <span key={index} className="cursor" style={{ color: "#1DC1DD" }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div>
      {expanded ? (
        <div>
          <p
            className="roboto-regular mb-0 fs-14"
            style={{
              wordWrap: "break-word",
              color: "#434343",
            }}
          >
            {processTextWithHashtags(text)}
          </p>
          <span
            onClick={toggleReadMore}
            className="roboto-bold text-blue cursor fs-14 cursor"
            style={{ color: "#1DC1DD" }}
          >
            {lang?.see_less}
          </span>
        </div>
      ) : (
        <div>
          <p
            className="roboto-regular mb-0 fs-14"
            style={{ wordWrap: "break-word", color: "#434343" }}
          >
            {processTextWithHashtags(text.slice(0, 240))}
            {text.length > 240 && (
              <>
                ...{" "}
                <span
                  onClick={toggleReadMore}
                  className="roboto-bold text-blue cursor fs-14"
                  style={{ color: "#1DC1DD" }}
                >
                  {lang?.see_more}
                </span>
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export const ConvertToTime = ({ tstamp }) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    convertTimestampToTime();
  }, []);

  const convertTimestampToTime = () => {
    const date = new Date(Number(tstamp));
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const seconds = "0" + date.getSeconds();
    const formattedTime =
      hours + ":" + minutes.slice(-2) + ":" + seconds.slice(-2);
    setFormattedTime(formattedTime);
  };

  return formattedTime;
};
