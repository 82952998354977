import { useState, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import ImageComponent from "../common/ImageComponent";
import { BaseURL } from "../../constants/API";
import { ShimmerThumbnail } from "react-shimmer-effects";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/RoutesUrl";

export default function FeedAdvertisement({ ads }) {
 const navigate = useNavigate()

  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  const totalSlides = ads&&ads.length;

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.moveTo(currentSlide - 1);
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.moveTo(currentSlide + 1);
    }
  };
const {data} = JSON.parse(localStorage.getItem("SignupData"));

  const handleAdClick = async (ad) => {
    // Construct the API endpoint with the ad's ID
    const urlToOpen = ad?.url.startsWith('http://') || ad?.url.startsWith('https://') 
    ? ad.url 
    : `https://${ad.url}`;
    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
    if(data.token){
    const apiEndpoint = `https://admin.medicalradar.es/endpoint/user/web/ads/click/banner_ads/${ad.id}`;
  
    try {
      // Make the API call with the Authorization header
      await axios.post(apiEndpoint,        {device:"Web"
          
      }, {

        headers: {
          Authorization: `Bearer ${data.token}`, // Ensure token is passed correctly
        },
      });
  
      // Ensure the URL is absolute
      // const adUrl = ad?.url.startsWith('http://') || ad?.url.startsWith('https://')
      //   ? ad.url
      //   : `https://www.${ad.url}`;
     
    } catch (error) {
      console.error('Error in API call:', error);
    }
  }else{
    navigate(LOGIN)
  }
  };
  
  

  return (
    <>
      <div
        className="bg-white d-xl-block d-none mb-3 border border-inherit"
        style={{
          width: "335px",
          borderRadius: "8px",
          boxShadow: "0px 2px 20px 4px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="p-3 border-bottom d-flex justify-content-between">
          <h4 className="fs-16 roboto-bold mb-0">Advertisement</h4>
          <p
            className="fs-12 roboto-medium mb-0 align-items-center d-flex cursor"
            style={{ color: "rgba(29, 193, 221, 1)" }}
            // onClick={() => window.open(ads[currentSlide]?.url, "_blank")}
            onClick={() => {
              const url = ads[currentSlide]?.url;
              const urlToOpen = url?.startsWith('http://') || url?.startsWith('https://') 
                  ? url 
                  : `https://${url}`;
              window.open(urlToOpen, "_blank");
          }} 
          >
            Join Now <IoIosArrowForward size={16} />
          </p>
        </div>
        <div style={{ height: "335px", }}>
          {!ads?.length ? (
            <ShimmerThumbnail  center={true} fitOnFrame={true} rounded />
          ) : (
            <Carousel
              ref={carouselRef}
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
              onChange={setCurrentSlide}
              showArrows={false}
              // autoPlay={true}
              infiniteLoop={true}
              interval={3000}
            >
              {ads?.map((ad,index) => (
                <div style={{height:"335px"}} key={index} onClick={() => handleAdClick(ad)}>
                  <ImageComponent
                  //BaseURL +
                    src={ad.media}
                    compClassName="object-cover"
                    img_height="100%"
                    img_width="100%"
                    loader_height="100%"
                    loader_width="100%"
                    onClick={() => handleAdClick(ad)}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div className="border-top d-flex justify-content-between align-items-center px-3 py-2">
          <button
            className="btn btn-sm btn-white d-flex py-2"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.15)",
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 5px 0px",
            }}
            onClick={handlePrev}
            disabled={currentSlide === 0}
          >
            <IoIosArrowBack size={14} />
          </button>
          <div>
            <p
              className="fs-14 roboto-medium mb-0"
              style={{ color: "rgba(132, 135, 152, 1)" }}
            >
            {ads &&<span> {currentSlide + 1} / {totalSlides}</span>}
            </p>
          </div>
          <button
            className="btn btn-sm btn-white d-flex py-2"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.15)",
              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
            }}
            onClick={handleNext}
            disabled={currentSlide === totalSlides - 1}
          >
            <IoIosArrowForward size={14} />
          </button>
        </div>
      </div>
    </>
  );
}
