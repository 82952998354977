import { useContext, useRef, useState } from "react";
import { Language, Sponsorpost } from "../../App";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Row } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { CONTEST_DETAILS, LOGIN, NEW_CONTEST } from "../../constants/RoutesUrl";
import { ShimmerThumbnail } from "react-shimmer-effects";
import axios from "axios";

export default function FeedSponsorPost({ new_contest_obj, loading }) {
  const { lang } = useContext(Language);
  const { updateContext } = useContext(Sponsorpost);
  const {data} = JSON.parse(localStorage.getItem("SignupData"));

  // const [currentSlide, setCurrentSlide] = useState(0);
  // const carouselRef = useRef(null);

  const Redirect = useNavigate();

  const value = new_contest_obj?.show === "1" ? new_contest_obj : null;
console.log("value",value)
  const newContest = {
    ...value,
    color_code: "#2D3155",
    type: "2",
  };
  const handleAdClick = async (ad) => {
    // Construct the API endpoint with the ad's ID
    if(data.token){
    const apiEndpoint = `https://admin.medicalradar.es/endpoint/user/web/ads/click/contest_ads/${ad.id}`;
  
    try {
      // Make the API call with the Authorization header
      await axios.post(apiEndpoint,        {device:"Web"
          
      }, {

        headers: {
          Authorization: `Bearer ${data.token}`, // Ensure token is passed correctly
        },
      });
      Redirect(NEW_CONTEST);
      // Ensure the URL is absolute
      // const adUrl = ad?.url.startsWith('http://') || ad?.url.startsWith('https://')
      //   ? ad.url
      //   : `https://www.${ad.url}`;
  
      // // Open the URL in a new tab
      // window.open(adUrl, '_blank');
    } catch (error) {
      console.error('Error in API call:', error);
    }
  }else{
    Redirect(LOGIN)
  }
  };
  // const totalSlides = newContest?.length;

  // const handlePrev = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.moveTo(currentSlide - 1);
  //   }
  // };

  // const handleNext = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.moveTo(currentSlide + 1);
  //   }
  // };

  return (
    <>
      {loading ? (
        <div className="my-3">
          <ShimmerThumbnail height="361" />
        </div>
      ) : (
        <>
          {!value && (
            <div className="my-3">
              <ShimmerThumbnail height="361" />
            </div>
          )}
          {value && value?.show === "1" && (
            <div
              className="bg-white my-3 overflow-hidden br-6"
              style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
            >
              <Row>
                {/* <Carousel
                  ref={carouselRef}
                  autoPlay={false}
                  infiniteLoop={false}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  onChange={setCurrentSlide}
                  selectedItem={currentSlide}
                > */}
                {/* {contestArr?.map((data) => ( */}
                <>
                  <div
                    className="d-flex justify-content-between py-2 px-3 cursor"
                    onClick={() => {
                      updateContext(newContest);
                      Redirect(NEW_CONTEST);
                    }}
                  >
                    <div className="align-self-end">
                      <h6 className="mb-0 pb-0">
                        {lang?.haz_click_para_participar}
                      </h6>
                    </div>
                    <div className="align-self-end d-flex align-items-center">
                      <p
                        className="mb-0 roboto-bold fs-14"
                        style={{ color: newContest?.color_code }}
                      >
                        Join Now
                      </p>{" "}
                      <IoIosArrowForward
                        color={newContest?.color_code}
                        size={25}
                      />
                    </div>
                  </div>
                  <ImageComponent
                    src={newContest?.image}
                    img_height={"auto"}
                    img_width="100%"
                    loader_height={"265px"}
                    loader_width="100%"
                    compClassName="object-cover"
                  />
                </>
                {/* ))} */}
                {/* </Carousel> */}
                {/* {contestArr && contestArr.length > 1 && (
                  <div className="border-top d-flex justify-content-between align-items-center px-3 py-2">
                    <button
                      className="btn btn-sm btn-white border-0"
                      style={{
                        boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
                      }}
                      onClick={handlePrev}
                      disabled={currentSlide === 0}
                    >
                      <IoIosArrowBack size={14} />
                    </button>
                    <div>
                      <p
                        className="fs-14 roboto-medium mb-0"
                        style={{ color: "rgba(132, 135, 152, 1)" }}
                      >
                        {currentSlide + 1} / {totalSlides}
                      </p>
                    </div>
                    <button
                      className="btn btn-sm btn-white border-0"
                      style={{
                        boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
                      }}
                      onClick={handleNext}
                      disabled={currentSlide === totalSlides - 1}
                    >
                      <IoIosArrowForward size={14} />
                    </button>
                  </div>
                )} */}
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}
