import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Col,
    Form,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    Spinner,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Language, LoginUserData } from "../../App";
import select_icon from "../../assets/images/icons/select-icon.svg";
import axios from "axios"; // Import axios for API calls
import { toast } from "react-toastify"; // Import toast for notifications
import { AddCourseApi, adminBaseURL, BaseURL, imagesURL } from "../../constants/API"; // Import your API endpoint constants
import { CiSearch } from "react-icons/ci";
import { PROFILE } from "../../constants/RoutesUrl";
import InfiniteScroll from "react-infinite-scroller";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Link } from "react-router-dom";
import DatePickerComponent from "../common/DatePickerComponent";
import TimePickerComponent from "../common/TImePickerComponent";
import {  useQueryClient } from "react-query";
const CreateClinicalSessionModal = ({ isOpen, toggle, }) => {
  const queryClient = useQueryClient(); // Get the query client instance
    const { lang } = useContext(Language);
    const { splashData } = useContext(LoginUserData);
    const SplashData = JSON.parse(localStorage.getItem("SplashData"));
    const subcategories = SplashData?.home?.subcategories;
    const user_id = SplashData?.user?.id;
  const {data:userToken} = JSON.parse(localStorage.getItem("SignupData"));

    // State for course data
    const [data, setData] = useState({
        title: "",
        subcategory_id: "",
        caption: "",
        schedule_date: null,
        schedule_time: null,
        duration:null,
        type: "clinical-session"
    });

    const [errors, setErrors] = useState({
        title: "",
        subcategory_id: "",
        caption: "",
        duration: "",
        schedule_date: "",
        schedule_time: "",
    });

    const [isLoading, setIsLoading] = useState(false); // Loading state for API call
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
   const [isInvite,setIsInvite] = useState(false)
  const [searchArr, setSearchArr] = useState([]);
  const [searchArrEmpty, setSearchArrEmpty] = useState([]);
  const [clinicalId, setClinicalId] = useState("");
  const [inviteModal, setInviteModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [invitePage, setInvitePage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const [inviteData, setInviteData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTimeout = useRef(null);
  const dateInputRef = useRef(null);
  const timeInputRef = useRef(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  // const [selectedDate, setSelectedDate] = useState("");
  const [buttonDisabledState, setButtonDisabledState] = useState({});

  const handleButtonClick = (userId) => {
      // Disable the specific button
      setButtonDisabledState((prevState) => ({
          ...prevState,
          [userId]: true
      }));

      // Call the invite handler
      handleInvite(userId);
  };



  const handleSearch = (value) => {
   
      setSearchTerm(value);
      setIsLoading(true);
  };
// //   useEffect(() => {
// //     if (debounceTimeout.current) {
// //         clearTimeout(debounceTimeout.current);
// //     }

// //     if (!searchTerm) {
// //         setSearchArr([]); // Clear the search results if the search term is empty
// //         setIsLoading(false);
// //         return; // Exit early if there's no search term
// //     }

// //     // Set loading state immediately when the searchTerm is not empty
    

// //     debounceTimeout.current = setTimeout(() => {
// //         axios
// //             .post(
// //                 adminBaseURL + "user/post/clinical-session/user/search",
// //                 {
// //                     keyword: searchTerm,
// //                     post_id: clinicalId,
// //                 },
// //                 {
// //                     headers: {
// //                         "Content-Type": "multipart/form-data",
// //                         Authorization: `Bearer ${userToken?.token}`,
// //                     },
// //                 }
// //             )
// //             .then((res) => {
// //                 if (res.data.status) {
// //                     setSearchArr(res.data.data.data);
// //                     setSearchArrEmpty(res.data.data.data);
// //                 } else {
// //                     toast.error(res.data.error);
// //                     setSearchArr([]); // Clear the search array if the response is unsuccessful
// //                 }
// //             })
// //             .catch((err) => {
// //                 console.log(err);
// //                 setSearchArr([]); // Clear the search array if there's an error
// //             })
// //             .finally(() => {
// //                 setIsLoading(false); // Set loading to false once the request completes
// //             });
// //     }, 500); // 500ms delay for debouncing

// //     return () => {
// //         if (debounceTimeout.current) {
// //             clearTimeout(debounceTimeout.current);
// //         }
// //     };
// // }, [searchTerm, user_id, clinicalId, setSearchArr]);

  useEffect(() => {
    
      if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
      }
      if (!searchTerm.length) {
        console.log("search",searchTerm)
        setSearchArr([]);
        setIsLoading(false);
        return; // Exit early
    }
   
      if (searchTerm) {
       
          debounceTimeout.current = setTimeout(() => {
             
              axios
                  .post(adminBaseURL + "user/post/clinical-session/user/search", {
                      keyword: searchTerm,
                     
                      post_id: clinicalId,
                  },{
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${userToken?.token}`,
                    },
                  },)
                  .then((res) => {
                      if (res.data.status) {
                          setSearchArr(res.data.data.data);
                          setSearchArrEmpty(res.data.data.data);
                      } else {
                          toast.error(res.data.error);
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                  })
                  .finally(() => {
                      setIsLoading(false);
                  });
          }, 500); // 500ms delay for debouncing
      } else {
          setSearchArr([]); // Clear the search results if the search term is empty
          // setSearchArrEmpty()
      }

      return () => {
          if (debounceTimeout.current) {
              clearTimeout(debounceTimeout.current);
          }
      };
  }, [searchTerm, user_id, clinicalId, setSearchArr]);
  // const handleSearch = (value) => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     axios
  //       .post(adminBaseURL + "user/post/clinical-session/user/search", {
  //         keyword: value,
  //         user_id,
  //         post_id: clinicalId,
  //       },{
  //                             headers: {
  //                               "Content-Type": "multipart/form-data",
  //                               Authorization: `Bearer ${userToken?.token}`,
  //                             },
  //                           },)
  //       .then((res) => {
  //         if (res.data.status) {
  //           setSearchArr(res.data.data.data);
  //         } else {
  //           toast.error(res.data.error);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }, 2000);
  // };
  

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));

    };
    const handleInvitationList = (clinical_id) => {
        setIsLoading(true);
        axios
          .get(
            adminBaseURL +
              "user/post/clinical-session/users/" +
              clinical_id +
              
              "?page=" +
              JSON.stringify(invitePage),{
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${userToken?.token}`,
                },
              }
          )
          .then((res) => {
            if (inviteList.length) {
              setInviteList(inviteList.concat(res.data.data.data));
              setInviteData(res.data.data);
              setInvitePage(res.data.data.current_page + 1);
              return 0;
            }
            if (res.data.status) {
              setInviteList(res.data.data.data);
              setInviteData(res.data.data);
              setInvitePage(res.data.data.current_page + 1);
              setInviteSpin(false);
            } else {
              toast.error(res.data.error);
              setInviteSpin(false);
            }
          })
          .catch((err) => {
            toast.error(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
    
      // const handleInvite = (userId) => {
      //   setIsButtonDisabled(true);
      //   axios
      //     .get(adminBaseURL + "user/post/clinical-session/invite/" + clinicalId + "/" + userId,{
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         Authorization: `Bearer ${userToken?.token}`,
      //       },
      //     })
      //     .then((res) => {
      //       if (res.data.status) {
      //         const update = inviteList.map((value) => {
      //           if (value.id === userId) {
      //             return { ...value, invite: !value.invite };
      //           }
      //           return value;
      //         });
      //         setInviteList(update);
      //         setIsButtonDisabled(false);
      //       } else {
      //         setIsButtonDisabled(false);
      //         toast.error(res.data.error);
      //       }
      //     })
      //     .catch((err) => {
      //       toast.error(err);
      //     });
      // };
      const handleInvite = (userId) => {
        axios
            .get(adminBaseURL + "user/post/clinical-session/invite/" + clinicalId + "/" + userId, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userToken?.token}`,
                },
            })
            .then((res) => {
                if (res.data.status) {
                    const update = inviteList.map((value) => {
                        if (value.id === userId) {
                            return { ...value, invite: !value.invite };
                        }
                        return value;
                    });
                    setInviteList(update);
                    const updatedSearchArr = searchArr.map((value) => {
                      if (value.id === userId) {
                          return { ...value, invite: !value.invite };
                      }
                      return value;
                  });
                  setSearchArr(updatedSearchArr);
                } else {
                    toast.error(res.data.error);
                }
                // Re-enable the button after API call
                setButtonDisabledState((prevState) => ({
                    ...prevState,
                    [userId]: false
                }));
                
            })
            .catch((err) => {
                toast.error(err);
                // Re-enable the button if there's an error
                setButtonDisabledState((prevState) => ({
                    ...prevState,
                    [userId]: false
                }));
            });
    };
      // const handleSearchInvite = (userId) => {
      //   setIsLoading(true);
      //   axios
      //     .get(adminBaseURL + "api/clinical-session/invite/" + clinicalId + "/" + userId,{
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         Authorization: `Bearer ${userToken?.token}`,
      //       },
      //     })
      //     .then((res) => {
      //       if (res.data.status) {
      //         const update = searchArr.map((value) => {
      //           if (value.id === userId) {
      //             return { ...value, invite: !value.invite };
      //           }
      //           return value;
      //         });
      //         setSearchArr(update);
      //         setIsLoading(false);
      //       } else {
      //         setIsLoading(false);
      //         toast.error(res.data.error);
      //       }
      //     })
      //     .catch((err) => {
      //       toast.error(err);
      //     });
      // };
      function isValidFutureDate(inputDate) {
        // Parse the input date
        const userDate = new Date(inputDate);
    
        // Check if the parsed date is a valid date
        if (isNaN(userDate.getTime())) {
          toast.error(lang?.error_note_number);
          return false;
        }
    
        // Get today's date
        const today = new Date();
    
        // Set the time to midnight for both dates to compare only the dates
        userDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
    
        // Compare the dates
        if (userDate < today) {
          toast.error(lang?.date_should_greater_todays_date);
          return false;
        }
        return true;
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // setIsInvite(true)
       // Converting to unixTimeStamp
    const combinedDateTimeString = `${data.schedule_date}T${data.schedule_time}`;
    const combinedDateTime = new Date(combinedDateTimeString);
    const start_timestamp = combinedDateTime.getTime();
    // converting to 12-hrs
    const schedule_time = combinedDateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const _start_timestamp = Math.floor(start_timestamp / 1000);
// console.log(newNumber);

    if (data.duration > 120) {
      toast.error(lang?.clinical_session_duration_must_not_greater_min);
      return 0;
    }

    if (data.duration < 0) {
      toast.error(lang?.enter_correct_time_limit);
      return 0;
    }

    if (combinedDateTime < new Date()) {
      toast.error(lang?.start_time_less_current_time);
      return 0;
    }

    isValidFutureDate(data.schedule_date);
        let _data={
          start_timestamp:_start_timestamp,
          type: data.type,
          caption: data.caption,
          schedule_date: data.schedule_date,
          schedule_time,
          category_id: "3",
          subcategory_id: data.subcategory_id,
          title: data.title,
          visibility: data.visibility,
          duration: data.duration,
          
        }
        console.log("_data",_data)
        setIsLoading(true);
        axios
          .post(adminBaseURL + "user/post/create", _data,{
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken?.token}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
            //   setInviteModal(true);
            setIsInvite(true)
              setInviteSpin(true);
              setClinicalId(res.data.post.id);
              handleInvitationList(res.data.post.id);
              queryClient.invalidateQueries(["clinicalSessionData"]);
              queryClient.invalidateQueries(["myclinicalSessionData"]);
              queryClient.invalidateQueries(["invitedClinicalSessionData"]);
            } else {
              setIsLoading(false);
              toast.error(res.data.error);
            }
          })
          .catch((err) => {
            toast.error(err);
          });
    };
    // const handleCreateSession = async () => {
    //   try {
    //     await createSessionAPI(); // Your "create" session API call
    //     queryClient.invalidateQueries(["clinicalSessionData"]); // Invalidate clinical session data
    //   } catch (error) {
    //     console.error("Error creating session", error);
    //   }
    // };

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle}>
            <OffcanvasHeader toggle={toggle}>{isInvite?"Send Invites":"Create Clinical Session"}</OffcanvasHeader>
            <OffcanvasBody>
                <p className="fs-14 roboto-regular text-gray">
                    {isInvite?"Invite your friends and followers to the clinical session so they can learn about it and join as well.":"Fill out the fields below to create your new clinical session. Title, category and some other information."}
                </p>
               {isInvite ?<> <Row className="justify-content-center">
            <Col xs="auto">
                <div className="input-group mb-3 input-group-sm  overflow-hidden border "  style={{width: "300px", borderRadius: "5px"}}>
                    <input
                        type="text"
                        className="form-control hide-focus border-0"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        style={{
                            border: "none", // Remove default border
                            boxShadow: "none", // Remove input focus shadow
                        }}
                        onChange={(e) => {
                           
                              handleSearch(e.target.value);
                            
                          }}
                    />
                    <span className="input-group-text bg-white border-0 ps-1" id="basic-addon1" style={{
                            border: "none", // Remove default border
                            boxShadow: "none", // Remove input focus shadow
                        }}>
                    <CiSearch />
                    </span>
                </div>
            </Col>
        </Row>
        <Row>
                    <Col>
                <InfiniteScroll
                pageStart={0}
                loadMore={() => handleInvitationList(clinicalId)}
                hasMore={
                  inviteData?.current_page !== inviteData?.last_page
                    ? true
                    : false
                }
                loader={
                  <Row>
                    <Col className="text-center my-3">
                      <Spinner />
                    </Col>
                  </Row>
                }
                useWindow={false}
              >
                {searchTerm.length > 0 ? (
                  <Row>
                    {isLoading ? (
                      <Row>
                        <Col className="text-center my-3">
                          <Spinner />
                        </Col>
                      </Row>
                    ) : (
                        searchArr.map((value) => (
                        <>
                        <Col xs={12}>
                          <div className=" my-2">
                            <div className="d-flex mx-4 justify-content-between">
                              <div className="d-flex gap-2">
                                <Link
                                  to={PROFILE + "/" + value.id}
                                  className="bg-white dropdown-avatar-shadow align-self-center"
                                  style={{
                                    borderRadius: "19px",
                                    maxWidth: "4rem",
                                    maxHeight: "4rem",
                                    padding: "1.8px",
                                  }}
                                >
                                  <img
                                    src={
                                      value?.image
                                        ? imagesURL + value.image
                                        : Avatar
                                    }
                                    alt="picture"
                                    className="img-fluid "
                                    style={{ borderRadius: "20px" }}
                                  />
                                </Link>
                                <div className="align-self-center">
                                  <Link
                                    to={PROFILE + "/" + value.id}
                                    className="fs-14 roboto-bold text-dark"
                                  >
                                    {value.name}
                                  </Link>
                                  <p className="mb-0 fs-12 roboto-regular">
                                    {value.user_name}
                                  </p>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <button
                                  className="btn  border border-1 px-3 py-1"
                                  style={{
                                   backgroundColor:value.invite ?"#848798":"#1DC1DD" ,
                                    color:"white"
                                  }}
                                  disabled={buttonDisabledState[value.id] || (value.invite && lang?.undo !== 'Undo')}
                                  onClick={() => {
                                    handleButtonClick(value.id)
                                  }}
                                >
                                  <span className="roboto-medium">
                                    {value.invite ? lang?.undo : lang?.invite}
                                  </span>
                                </button>
                              </div>
                            </div>
                            <hr className="mx-4" style={{ opacity: "0.1" }} />
                          </div>
                          
                        </Col>
                        
                         </>
                      ))
                    )}
                   <Col xs={12} className="text-center my-3">
          <button
            className="btn border mx-2  border-1 "
            style={{
              backgroundColor: "#1DC1DD",
              color: "white",
              width:"88%"
            }}
            onClick={toggle}
            // onClick={() => handleDoneInvites()}
          >
            <span className="roboto-medium">Done Invites</span>
          </button>
        </Col>
                  </Row>
                ) : (
                  <Row>
                    {inviteSpin ? (
                      <Row>
                        <Col className="text-center my-3">
                          <Spinner />
                        </Col>
                      </Row>
                    ) : (
                      inviteList.map((value) => (
                        <>
                        <Col xs={12}>
                          <div className=" my-2">
                            <div className="d-flex mx-4 justify-content-between">
                              <div className="d-flex gap-2">
                                <Link
                                  to={PROFILE + "/" + value.id}
                                  className="bg-white dropdown-avatar-shadow align-self-center"
                                  style={{
                                    borderRadius: "19px",
                                    maxWidth: "4rem",
                                    maxHeight: "4rem",
                                    padding: "1.8px",
                                  }}
                                >
                                  <img
                                    src={
                                      value?.image
                                        ? imagesURL + value.image
                                        : Avatar
                                    }
                                    alt="picture"
                                    className="img-fluid "
                                    style={{ borderRadius: "17px" }}
                                  />
                                </Link>
                                <div className="align-self-center">
                                  <Link
                                    to={PROFILE + "/" + value.id}
                                    className="fs-14 roboto-bold text-dark"
                                  >
                                    {value.name}
                                  </Link>
                                  <p className="mb-0 fs-12 roboto-regular">
                                    {value.user_name}
                                  </p>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <button
                                  className="btn btn-white border border-1 px-3 py-1"
                                  disabled={buttonDisabledState[value.id] || (value.invite && lang?.undo !== 'Undo')}
                                  onClick={() => {
                                    // handleInvite(value.id);
                                    handleButtonClick(value.id)
                                  }}
                                  style={{backgroundColor:value.invite ?"#848798"   :"#1DC1DD" ,
                                    color:"white"}}
                                >
                                  <span className="roboto-medium">
                                    {value.invite ? lang?.undo : lang?.invite}
                                  </span>
                                </button>
                              </div>
                            </div>
                            <hr className="mx-4" style={{ opacity: "0.1" }} />
                          </div>
                         
                        </Col>
                        
                         </>
                      ))
                    )}
                        <Col xs={12} className="text-center my-3">
          <button
            className="btn border  mx-2 border-1 px-3 py-1"
            style={{
              backgroundColor: "#1DC1DD",
              color: "white",
              width:"88%"
            }}
            onClick={toggle}
            // onClick={() => handleDoneInvites()}
          >
            <span className="roboto-medium">Done Invites</span>
          </button>
        </Col>
                  </Row>
                )}


              </InfiniteScroll>
              </Col>
              </Row>
        </>
        
        :
        <> 
        <Row>
                    <Col>
                        <Form className="mt-3" onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Col>
                                    <input
                                        type="text"
                                        name="title"
                                        className="py-2 form-control"
                                        placeholder="Session Title"
                                        value={data.title}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.title && <p className="text-danger">{errors.title}</p>}
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <select
                                        name="subcategory_id"
                                        className="form-select py-2 custom-select"
                                        style={{
                                            position: "relative",
                                            backgroundImage: `url(${select_icon})`,
                                            backgroundSize: '12px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'right 10px center',
                                        }}
                                        value={data.subcategory_id}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Clinical Session Category</option>
                                        {splashData?.home?.subcategories?.map((value, index) => (
                                            <option value={value.id} key={index}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.subcategory_id && <p className="text-danger">{errors.subcategory_id}</p>}
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <input
                                        type="date"
                                        name="schedule_date"
                                        value={data.schedule_date}
                                        onChange={handleInputChange}
                                        // ref={dateInputRef}
                                        // onFocus={handelDatePicker}
                                        // onBlur={handleBlur} 
                                        className="form-control custom-date-input"
                                        // placeholder="Start Date"
                                        placeholder={showPlaceholder ? "Start Date" : ""}
                                        required
                                    />
            {/* <DatePicker placeholderText="Click to select a date"  /> */}
            {/* <DatePickerComponent value={data.schedule_date} name="schedule_date" onChange={handleInputChange}/> */}
                                    {errors.schedule_date && <p className="text-danger">{errors.schedule_date}</p>}
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <input
                                        type="time"
                                        name="schedule_time"
                                        value={data.schedule_time}
                                        onChange={handleInputChange}
                                        className="form-control custom-date-input"
                                        // onFocus={handelTimePicker}
                                        ref={timeInputRef}
                                        placeholder="Start Time"
                                        required
                                    />
                                    {/* <TimePickerComponent value={data.schedule_time} name="schedule_time" onChange={handleInputChange}/> */}
                                    {errors.schedule_time && <p className="text-danger">{errors.schedule_time}</p>}
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <input
                                        type="number"
                                        name="duration"
                                        value={data.duration}
                                        onChange={handleInputChange}
                                        className="form-control custom-date-input"
                                        placeholder="Time Limit"
                                        required
                                    />
                                    {errors.duration && <p className="text-danger">{errors.duration}</p>}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <select
                                        className="form-select py-2 custom-select"
                                        style={{
                                            position: "relative",
                                            backgroundImage: `url(${select_icon})`,
                                            backgroundSize: '12px', // Make sure the size matches the CSS
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'right 10px center' // Align the icon to the right
                                       
                                          }}
                                        value={data.visibility}
                                        onChange={(e) => setData({ ...data, visibility: e.target.value })}
                                        required
                                   >
                                        <option value="">{lang?.choose_session_commitment}</option>
                                        <option value="One Time">{lang?.one_time}</option>
                                        <option value="Daily">{lang?.daily}</option>
                                        <option value="Weekly">{lang?.weekly}</option>

                                    </select>
                                    {errors.visibility && <p className="text-danger">{errors.visibility}</p>}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <textarea
                                        name="caption"
                                        className="form-control resize"
                                        rows="5"
                                        placeholder="Clinical Session Description"
                                        value={data.caption}
                                        onChange={handleInputChange}
                                        required
                                    ></textarea>
                                    {errors.caption && <p className="text-danger">{errors.caption}</p>}
                                </Col>
                            </Row>

                            <div className="row">
                                <div className="col">
                                    <button  disabled={
                  isLoading ||
                  !data.caption ||
                  !data.duration ||
                  !data.schedule_date ||
                  !data.schedule_time ||
                  !data.title ||
                  !data.visibility
                } type="submit" className="btn-blue border-0 w-100 roboto-bold my-4 py-3 rounded" >
                                        {isLoading ? "Creating Session..." : "Create Clinical Session"}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
                
                </>
                }
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default CreateClinicalSessionModal;
