import { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LoginUserData } from "../../App";
import Pusher from "pusher-js";


export const ChatsContext = createContext();

export function ChatsProvider({ children }) {
  const { splashData } = useContext(LoginUserData);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
//   const {   error, fetchChatss } = useFetchChats(splashData?.user?.id);
  const fetchChats = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://medicalradar.es/api/conversation/" + splashData.user.id
      );
      if (response.data.status === true) {
        setChats(response.data.data);
        console.log("response.data.data",response.data.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching chats. Please try again.");
    } finally {
      setLoading(false);
    }
  };

//   const updateChatMessage = (newMessage, chatId) => {
//     console.log("newMessage",newMessage)
//     setChats((prevChats) =>
//       prevChats.map((chat) =>
//         chat.id === chatId ? { ...chat, message: newMessage } : chat
//       )
//     );
//   };
const updateChatMessage = (newMessage, chatId) => {
    console.log("newMessage", newMessage); // Debugging log
    setChats((prevChats) => {
      const updatedChats = prevChats.map((chat) => {
        if (chat.id === chatId) {
          return {
            ...chat,
            message: newMessage.text,  // Ensure you are updating the text correctly
            time: newMessage.time,      // Update the timestamp if necessary
          };
        }
        return chat;
      });
  
      return [...updatedChats];
    });
  };
  
  const fetchChatss = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        "https://medicalradar.es/api/conversation/" + splashData.user.id
      );
      if (response.data.status === true) {
        setChats(response.data.data);
        console.log("response.data.data",response.data.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching chats. Please try again.");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_CLUSTER,
    });
    let channel
    // if(splashData===null){
        channel =
       pusher.subscribe(String(`inbox-${splashData?.user.id}`))
 
        channel.bind("inbox-event", function (data) {
            if(data){              
            // fetchChats()
            fetchChatss()
        }
        
        });
    //   }else{
            // channel.bind("message-event", function (data) {
            //     fetchChats()
            // });
        //   }

    // Unsubscribe when component unmounts
    return () => {
      pusher.unsubscribe(String(`inbox-${splashData?.user.id}`));
    };
  }, [splashData.user.id]);
//   useEffect(() => {
//     // setLoading(true)
//     const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
//       cluster: process.env.REACT_APP_CLUSTER,
//     });
//     let channel;
// if(selectedChat===null){
//    channel =
//   pusher.subscribe(selectedChat?.group_id === 0 
//    ? String(selectedChat?.user.id) 
//    : String(selectedChat?.group_id))
// }else{
//   channel =
//   pusher.subscribe(String(selectedChat.from_to))
  
// }
// if(selectedChat.group_id!==0){
//   console.log("pusher block")
//   channel.bind("group-event", function (data) {
   
//     scrollToBottom();
//   });
// }else{
//       channel.bind("message-event", function (data) {
       
//         scrollToBottom();
//       });
//     }

//     return () => {
//       if (selectedChat?.group_id === 0) {
//         pusher.unsubscribe(String(selectedChat?.user.id));
//       } else {
//         pusher.unsubscribe(String(selectedChat?.group_id));
//       }
//       pusher.unsubscribe(String(selectedChat?.from_to)); // Ensure all channels are unsubscribed
//     };
//         // pusher.unsubscribe(
//         //   selectedChat?.group_id === 0 
//         //   ? String(selectedChat?.user.id) 
//         //   : String(selectedChat?.group_id)
//         // );
//     // };
//   }, [selectedChat]);
  return (
    <ChatsContext.Provider value={{ chats,setChats, loading, fetchChats, updateChatMessage }}>
      {children}
    </ChatsContext.Provider>
  );
}
