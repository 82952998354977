import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import NavBar from "../components/NavBar";
import FeedAdvertisement from "../components/feeds/FeedAdvertisement";
import SuggestedCoursesEvents from "../components/feeds/SuggestedCoursesEvents";
import CreateEventCard from "../components/events/create/CreateEventCard";
import EventTabs from "../components/events/EventTabs";
import UpcomingEventsCard from "../components/events/UpcomingEventsCard";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { addDays, format } from "date-fns";
import { fetchEvents } from "../services/eventsApis";
import { toast } from "react-toastify";
import { CiCalendar, CiClock2, CiLocationOn } from "react-icons/ci";
import { EVENT_DETAILS } from "../constants/RoutesUrl";
import { useNavigate } from "react-router-dom";
import MyEvents from "../components/events/MyEvents";
import JoinedEvents from "../components/events/JoinedEvents";
import SavedEvents from "../components/events/SavedEvents";
import { FeedDataRefetch } from "../App";

const colors = [
  "#E1F9FF",
  "#FFEAD5",
  "#F5F5F5",
  "#FFF7DA",
  "#FEECFF",
  "#FFF3EE",
];

const getColorForEvent = (title) => {
  const hash = title
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colors[hash % colors.length];
};

const splitMultiDayEvents = (events) => {
  const splitEvents = [];
  events?.forEach((event) => {
    const start = new Date(event.start);
    const end = new Date(event.end);
    const color = getColorForEvent(event.title);
    for (let d = start; d <= end; d = addDays(d, 1)) {
      splitEvents.push({
        title: event.title,
        start: format(d, "yyyy-MM-dd"),
        backgroundColor: color,
        textColor: "#000",
        borderColor: color,
        classNames: "cursor fs-8 roboto-bold",
      });
    }
  });
  return splitEvents;
};

const Events = () => {
  const { refetch } = useContext(FeedDataRefetch);
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [ads, setAds] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);
  const [upcomingMonth, setUpcomingMonth] = useState([]);
  const [suggestedEvents, setSuggestedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventPosition, setEventPosition] = useState({ top: 0, left: 0 });
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const data = await fetchEvents();
        console.log("dataa",data)
        setEvents(data.all);
        setAds(data?.ads?.events);
        setThisMonth(data.this_month);
        setUpcomingMonth(data.upcoming);
        setSuggestedEvents(data.suggested);
      } catch (error) {
        toast.error("Error loading events:", error);
      }
    };

    loadEvents();
  }, [refetch]);

  const processedEvents = splitMultiDayEvents(events);

  const handleEventClick = (clickInfo) => {
    const eventFromApi = events.find(
      (event) => event.title === clickInfo.event.title
    );
    setSelectedEvent(eventFromApi);
    setEventPosition({
      top: clickInfo.jsEvent.pageY,
      left: clickInfo.jsEvent.pageX,
    });
  };

  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <div
        className="d-flex justify-content-center gap-5"
        style={{ paddingTop: "5rem" }}
      >
        <div>
          <FeedAdvertisement ads={ads} />
          <CreateEventCard />
          <SuggestedCoursesEvents
            type="events"
            suggestedEvents={suggestedEvents}
          />
        </div>
        <div style={{ width: "714px" }}>
          <EventTabs activeTab={activeTab} setActiveTab={setActiveTab} />

          {activeTab === "all" ? (
            <div
              className="my-3 bg-white rounded py-3"
              style={{ height: "853px" }}
            >
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                height="100%"
                headerToolbar={{
                  start: "title",
                  center: "",
                  end: "prev,next",
                }}
                events={processedEvents}
                displayEventTime={false}
                displayEventEnd={false}
                eventClick={handleEventClick}
              />
            </div>
          ) : (
            <div className="my-3">
              {activeTab === "my-events" ? (
                <MyEvents />
              ) : activeTab === "joined-events" ? (
                <JoinedEvents />
              ) : (
                <SavedEvents />
              )}
            </div>
          )}
          {selectedEvent && (
            <div
              className="bg-white rounded p-3"
              style={{
                width: "252px",
                position: "absolute",
                top: `${eventPosition.top}px`,
                left: `${eventPosition.left}px`,
                transform: "translate(-50%, -100%)",
                zIndex: 1000,
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
              }}
            >
              {selectedEvent.title && (
                <div className="d-flex gap-2 align-items-center mb-2">
                  <div
                    className="rounded d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#EBEDF0",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <CiCalendar size={12} />
                  </div>
                  <div>
                    <h5 className="roboto-bold mb-0 fs-10">
                      {selectedEvent.title}
                    </h5>
                  </div>
                </div>
              )}
              {selectedEvent.start && selectedEvent.end && (
                <div className="d-flex gap-2 align-items-center mb-2">
                  <div
                    className="rounded d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#EBEDF0",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <CiClock2 size={12} />
                  </div>
                  <div>
                    <h5 className="roboto-regular mb-0 fs-10">
                      {selectedEvent.start} - {selectedEvent.end}
                    </h5>
                  </div>
                </div>
              )}
              {selectedEvent.short_location && (
                <div className="d-flex gap-2 align-items-center mb-2">
                  <div
                    className="rounded d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#EBEDF0",
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <CiLocationOn size={12} />
                  </div>
                  <div>
                    <h5 className="roboto-regular mb-0 fs-10">
                      {selectedEvent.short_location}
                    </h5>
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  <button
                    className="bg-white rounded text-secondary px-3 py-1 roboto-regular fs-12 border border-inherit"
                    onClick={() => setSelectedEvent(null)}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => navigate(EVENT_DETAILS + selectedEvent.id)}
                    className="bg-white rounded px-3 py-1 roboto-bold fs-12"
                    style={{ border: "1px solid #1DC1DD" }}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="position-relative">
          <UpcomingEventsCard
            thisMonth={thisMonth}
            upcomingMonth={upcomingMonth}
          />
        </div>
      </div>
    </Container>
  );
};

export default Events;
