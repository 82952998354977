import { useContext } from "react";
import { Language } from "../../App";
import { useNavigate } from "react-router-dom";

export default function SearchFilter({ keyword }) {
  const navigate = useNavigate();
  const { lang } = useContext(Language);
  const btns = [
    {
      id: 1,
      type: "user",
      name: lang?.doctors,
      link: `/search/doctors?keyword=${keyword}`,
    },
    {
      id: 2,
      type: "posts",
      name: lang?.posts,
      link: `/search/posts?keyword=${keyword}`,
    },
    {
      id: 3,
      type: "webinar",
      name: lang?.webinars,
      link: `/search/webinars?keyword=${keyword}`,
    },
    {
      id: 4,
      type: "clinical",
      name: lang?.clinicals,
      link: `/search/clinicals?keyword=${keyword}`,
    },
    {
      id: 5,
      type: "courses",
      name: lang?.courses,
      link: `/search/courses?keyword=${keyword}`,
    },
    {
      id: 6,
      type: "events",
      name: lang?.events,
      link: `/search/events?keyword=${keyword}`,
    },
    {
      id: 7,
      type: "jobs",
      name: lang?.jobs,
      link: `/search/jobs?keyword=${keyword}`,
    },
  ];

  return (
    <div className="position-sticky top-0">
      <div
        className="d-flex gap-3 justify-content-center bg-white pb-2"
        style={{
          paddingTop: "4rem",
          boxShadow: "0px 10px 10px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        {btns &&
          btns.map((value) => (
            <div key={value.id}>
              <button
                type="button"
                className="rounded px-4 py-2 roboto-bold manue-hover hover-blue border border-1 border-inherit"
                onClick={() => {
                  navigate(value.link);
                }}
              >
                {value.name}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
