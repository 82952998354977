import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, ModalFooter, ModalHeader, Row } from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../hooks/useCropImage";
import { BaseURL, EditEventApi } from "../../constants/API";
import UploadIcon from "../../assets/images/icon/upload.png";
import axios from "axios";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_KEY } from "../../constants/Keys";
import { parse, getTime } from "date-fns";
import { useParams } from "react-router-dom";
import { TiCancel } from "react-icons/ti";
import { Language } from "../../App";
import { toast } from "react-toastify";

function EditEvent(props) {
  const { lang } = useContext(Language);
  const param = useParams();
  const event_id = param.id;

  // Google Map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
  });

  // sessionStorage
  const SplashData = JSON.parse(localStorage.getItem("SplashData"));

  // States
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [eventImagePreview, setEventImagePreview] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [locationModal, setLocationModal] = useState(false);
  const [timeInput, setTimeInput] = useState({
    hr_start: "",
    min_start: "",
    median_start: "",
    hr_end: "",
    min_end: "",
    median_end: "",
  });

  const [mapCenter, setMapCenter] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const HH = [];
  for (let i = 1; i <= 12; i++) {
    const num = i > 9 ? `${i}` : `0${i}`;
    HH.push(num);
  }
  const MM = [];
  for (let i = 1; i <= 60; i++) {
    const num = i > 9 ? `${i}` : `0${i}`;
    MM.push(num);
  }

  // Functions that the cropper is using
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  // Funtion to crop cover image
  const handleCropper = async () => {
    try {
      const croppedImage = await getCroppedImg(
        eventImagePreview,
        croppedAreaPixels
      );
      // Replace the Blob URL with your actual Blob URL
      const blobUrl = croppedImage;
      // Fetch the Blob content
      fetch(blobUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a FileReader to read the Blob as base64
          const reader = new FileReader();
          reader.onload = () => {
            // The result will be the Blob content as base64
            const base64Data = reader.result;
            setCroppedImage(base64Data);
          };
          // Read the Blob as data URL
          reader.readAsDataURL(blob);
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error(e);
    }
  };
  // Function to hit submit api
  const handleSubmit = () => {
    if (!croppedImage && !data.cover) {
      toast.error(lang?.please_select_cover_image);
      return;
    }
    if (timeInput.hr_start || timeInput.min_start || timeInput.median_start) {
      if (timeInput.hr_start && timeInput.min_start && timeInput.median_start) {
        var StartTime = `${timeInput.hr_start}:${timeInput.min_start} ${timeInput.median_start}`;
      } else {
        toast.error(lang?.please_enter_event_s_time);
        return;
      }
    }

    if (timeInput.hr_end || timeInput.min_end || timeInput.median_end) {
      if (timeInput.hr_end && timeInput.min_end && timeInput.median_end) {
        var EndTime = `${timeInput.hr_end}:${timeInput.min_end} ${timeInput.median_end}`;
      } else {
        toast.error(lang?.please_enter_event_time);
        return;
      }
    }
    const numericRegex = /^[0-9]+$/;

    if (data.limit !== "" && !numericRegex.test(data.limit.toString())) {
      toast.error(lang?.audience_limit_should_numeric);
      return;
    }

    if (data.price !== "" && !numericRegex.test(data.price.toString())) {
      toast.error(lang?.price_should_numeric);
      return;
    }

    // creating UnixTimeStamp using Start Time & Start Date
    const StartTimeDate = `${data.date} ${StartTime ? StartTime : "12:01 AM"}`;
    const dateObject = parse(StartTimeDate, "yyyy-MM-dd hh:mm a", new Date());
    const StartunixTimestamp = getTime(dateObject) / 1000;

    // creating UnixTimeStamp using End Time & End Date
    const EndTimeDate = `${data.end_date} ${EndTime ? EndTime : "11:59 PM"}`;
    const enddateObject = parse(EndTimeDate, "yyyy-MM-dd hh:mm a", new Date());
    const EndunixTimestamp = getTime(enddateObject) / 1000;

    const currentDate = new Date();

    // Get the current date in YYYY-MM-DD format
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Ensure 2-digit month
    const currentDay = currentDate.getDate().toString().padStart(2, "0"); // Ensure 2-digit day
    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

    if (data.date > data.end_date) {
      toast.error(lang?.start_date_should_greater_end_date);
      return;
    }

    if (data.date < currentDateString) {
      toast.error(lang?.start_date_cannot_less_today_date);
      return;
    }

    if (data.end_date < currentDateString) {
      toast.error(lang?.end_date_cannot_less_today_date);
      return;
    }

    const timeDifferenceInSeconds = EndunixTimestamp - StartunixTimestamp;
    const tenMinutesInSeconds = 10 * 60; // 10 minutes in seconds

    if (timeDifferenceInSeconds < tenMinutesInSeconds) {
      toast.error(lang?.there_should_least_minute_betwen_s_time_e_time);
      return;
    }

    const urlRegex = /^(http|https):\/\/[^ "]+$/;

    if (data.website && !urlRegex.test(data.website)) {
      toast.error(lang?.website_url_note_valid);
      return;
    }

    if (data.link && !urlRegex.test(data.link)) {
      toast.error(lang?.registration_link_url_not_valid);
      return;
    }

    if (data.password && data.password.length < 6) {
      toast.error(lang?.password_should_min_characters);
      return;
    }

    const formData = {
      cover_base64: croppedImage,
      start_timestamp: StartunixTimestamp,
      end_timestamp: EndunixTimestamp,
      event_id,
      title: data.title,
      organizer: data.organizer,
      description: data.description,
      date: data.date,
      time: StartTime ? StartTime : "",
      availability: data.availability,
      location: data.location ? data.location : "",
      short_location: data.short_location ? data.short_location : "",
      lat_lng: data.lat_lng ? `${data.lat_lng.lat},${data.lat_lng.lng}` : "",
      website: data.website ? data.website : "",
      link: data.link ? data.link : "",
      end_date: data.end_date,
      end_time: EndTime ? EndTime : "",
      password: data.password ? data.password : "",
      limit: data.limit ? data.limit : "",
      price: data.price ? data.price : "",
      questions: data.questions,
      tags: "@nnn",
      category_id: "3",
      subcategory_id: data.subcategory_id,
    };

    setIsLoading(true);
    axios
      .post(EditEventApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          window.location.reload();
        } else {
          toast.error("Error", res.data.action);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  // Location Lat_Lng
  const handleLocation = () => {
    if (data.lat_lng || markerPosition) {
      setLocationModal(true);
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log({ latitude, longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        () => {}
      );
      setTimeout(() => {
        setLocationModal(true);
      }, 500);
    } else {
      toast.error(lang?.geolocation_not_suported);
    }
  };
  // Marker drag
  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };
  // Map click
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    reverseGeocode(lat, lng);
  };
  // To get city and country using lat lng
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        let cityName = "";
        let countryName = "";

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            cityName = component.long_name;
          } else if (component.types.includes("country")) {
            countryName = component.long_name;
          }
        }

        {
          cityName &&
            countryName &&
            setData({ ...data, location: `${cityName}, ${countryName}` });
        }

        setCity(cityName);
        setCountry(countryName);
      } else {
        setCity("");
        setCountry("");
      }
    });
  };

  useEffect(() => {
    setData(props.Eventdata);
    console.log(props.Eventdata);
  }, []);

  return (
    <>
      <Row>
        <Col lg={7} className="bg-white p-4 ms-5 br-12">
          <Row className="pb-3">
            <Col className="text-center">
              <h3 className="fs-20 fs-md-16 roboto-bold">{lang?.edit_event}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {croppedImage || data.cover ? (
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    minHeight: "15rem",
                    borderRadius: "6px",
                    backgroundColor: "rgba(0,0,0,0.70)",
                  }}
                >
                  <button
                    className="bg-white position-absolute end-0 me-3 mt-3 br-6 bg-white border-0 px-2 py-0 fs-16 roboto-bold"
                    style={{ zIndex: "1" }}
                    onClick={() => {
                      const update = { ...data, cover: "" };
                      setData(update);
                      setCroppedImage("");
                    }}
                  >
                    x
                  </button>
                  <img
                    src={croppedImage || BaseURL + data.cover}
                    className="w-100 br-16"
                    style={{ objectFit: "cover", height: "15rem" }}
                    onClick={() => setImagePreviewModal(true)}
                    alt="picture"
                  />
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "1px dashed #A2A2A2",
                    backgroundColor: "#F2FCFE",
                    minHeight: "12rem",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    document.getElementById("fileInput").click();
                  }}
                >
                  <div className="text-center mt-5">
                    <img src={UploadIcon} width={40} height={40} alt="picture" />
                    <h4
                      className="fs-14 roboto-medium pt-3"
                      style={{ color: "#4470FF" }}
                    >
                      {lang?.add_event_cover}
                    </h4>
                  </div>
                </div>
              )}
              <input
                type="file"
                id="fileInput"
                className="d-none"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  // Preview the image
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const update = { ...data, cover: reader.result };
                    setData(update);
                    setEventImagePreview(reader.result);
                    setImageModal(true);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </Col>
          </Row>
          <Form className="mt-3">
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.title}
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  required
                  defaultValue={data.title}
                  placeholder={lang?.event_title}
                  onChange={(e) => {
                    setData({ ...data, title: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.organizer_name}
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  required
                  defaultValue={data.organizer}
                  placeholder={lang?.organizer_name}
                  onChange={(e) => {
                    setData({ ...data, organizer: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.category}
                </label>
                <select
                  className="form-select py-2"
                  required
                  onChange={(e) => {
                    setData({ ...data, subcategory_id: e.target.value });
                  }}
                >
                  {SplashData.home.subcategories.map((value, index) => (
                    <option
                      value={value.id}
                      selected={value.id === data.subcategory_id}
                      key={index}
                    >
                      {value.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.start_date}
                </label>
                <input
                  type="date"
                  className="form-control py-2"
                  required
                  defaultValue={data.date}
                  onChange={(e) => {
                    setData({ ...data, date: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.start_time}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div className="d-flex">
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, hr_start: e.target.value });
                    }}
                  >
                    <option value="" disabled selected hidden>
                      --
                    </option>
                    {HH.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, min_start: e.target.value });
                    }}
                  >
                    <option value="" disabled selected hidden>
                      --
                    </option>
                    {MM.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({
                        ...timeInput,
                        median_start: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected hidden>
                      --
                    </option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.end_date}
                </label>
                <input
                  type="date"
                  required
                  defaultValue={data.end_date}
                  className="form-control py-2"
                  onChange={(e) => {
                    setData({ ...data, end_date: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.end_time}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div className="d-flex">
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, hr_end: e.target.value });
                    }}
                  >
                    <option value="" disabled selected hidden>
                      --
                    </option>
                    {HH.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({ ...timeInput, min_end: e.target.value });
                    }}
                  >
                    <option value="" disabled selected hidden>
                      --
                    </option>
                    {MM.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control rounded-0"
                    onChange={(e) => {
                      setTimeInput({
                        ...timeInput,
                        median_end: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected hidden>
                      --
                    </option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.location}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <div className="position-relative">
                  <input
                    onClick={handleLocation}
                    type="text"
                    className="py-2 form-control"
                    placeholder="City, Country"
                    value={data?.location}
                  />
                  {data?.location && (
                    <div
                      className="position-absolute end-0 top-0 me-2"
                      style={{ marginTop: "13px" }}
                    >
                      <h6
                        className="text-primary cursor"
                        onClick={() => {
                          setData({
                            ...data,
                            lat_lng: "",
                            location: "",
                          });
                        }}
                      >
                        <TiCancel size={18} />
                        {lang?.remove}
                      </h6>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.address}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="text"
                  className="py-2 form-control"
                  placeholder="short_location"
                  defaultValue={data?.short_location}
                  onChange={(e) => {
                    setData({ ...data, short_location: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.website}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="url"
                  className="py-2 form-control"
                  value={data?.website}
                  onChange={(e) => {
                    setData({ ...data, website: e.target.value });
                  }}
                  placeholder={lang?.website}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.registration_link}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="url"
                  className="py-2 form-control"
                  placeholder={lang?.registration_link}
                  defaultValue={data.link}
                  onChange={(e) => {
                    setData({ ...data, link: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.availablity}
                </label>
                <select
                  required
                  className="form-select py-2"
                  onChange={(e) => {
                    setData({ ...data, availability: e.target.value });
                  }}
                >
                  <option value="online">{lang?.online}</option>
                  <option value="offline">{lang?.offline}</option>
                  <option value="online-offline">{lang?.online_offline}</option>
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.audience_limit}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="number"
                  className="py-2 form-control"
                  placeholder={lang?.audience_limit}
                  autoComplete="off"
                  defaultValue={data.limit}
                  onChange={(e) => {
                    setData({ ...data, limit: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.set_password}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="password"
                  autoComplete="off"
                  defaultValue={data.password}
                  className="py-2 form-control"
                  placeholder={lang?.set_password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.valueu });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.event_price}{" "}
                  <span style={{ opacity: "0.3" }}>{"(Optional)"}</span>
                </label>
                <input
                  type="number"
                  className="py-2 form-control"
                  defaultValue={data.price}
                  placeholder={lang?.event_price}
                  onChange={(e) => {
                    setData({ ...data, price: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label className="pb-1 fs-15 roboto-medium">
                  {lang?.description}
                </label>
                <textarea
                  className="form-control resize"
                  required
                  rows="5"
                  defaultValue={data.description}
                  placeholder={lang?.description}
                  onChange={(e) => {
                    setData({ ...data, description: e.target.value });
                  }}
                ></textarea>
              </Col>
            </Row>

            <Row>
              <Col>
                <button
                  onClick={handleSubmit}
                  disabled={
                    isLoading ||
                    !data.title ||
                    !data.organizer ||
                    !data.description ||
                    !data.date ||
                    !data.end_date ||
                    !data.subcategory_id ||
                    !data.availability
                  }
                  type="button"
                  className="btn-blue border-0 w-100 my-4 py-3"
                >
                  {isLoading ? <div className="loader"></div> : lang?.save}
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {/* image cropping  */}
      <Modal isOpen={imageModal} centered zIndex={9}>
        <div className="bg-white px-3 br-16">
          <ModalHeader toggle={() => setImageModal(!imageModal)}>
            {lang?.image_croper}
          </ModalHeader>
          <div className="position-relative" style={{ minHeight: "40rem" }}>
            <Cropper
              image={
                eventImagePreview ? eventImagePreview : BaseURL + data.cover
              }
              crop={crop}
              zoom={zoom}
              aspect={1.91}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="d-none">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
          <ModalFooter className="gap-3">
            <button
              className="btn btn-secondary border-0 px-4 py-2"
              onClick={() => {
                setImageModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-blue border-0 px-4 py-2"
              onClick={() => {
                handleCropper();
                setImageModal(false);
              }}
            >
              {lang?.save}
            </button>
          </ModalFooter>
        </div>
      </Modal>

      {/* cropped image preview */}
      <Modal
        isOpen={imagePreviewModal}
        centered
        zIndex={9}
        toggle={() => {
          setImagePreviewModal(!imagePreviewModal);
        }}
      >
        <img
          src={croppedImage || BaseURL + data.cover}
          alt="picture"
          className="img-fluid"
        />
      </Modal>

      {/* Google map modal */}
      <Modal isOpen={locationModal} centered zIndex={9} size="lg">
        <ModalHeader toggle={() => setLocationModal(false)}></ModalHeader>
        {loadError && "Error loading maps"}
        {!isLoaded && "Loading Maps"}
        <div style={{ height: "50vh" }}>
          <GoogleMap
            mapContainerClassName="w-100 h-100"
            center={mapCenter}
            zoom={15}
            onClick={handleMapClick}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                title={JSON.stringify(markerPosition)}
                icon={{
                  url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
        <ModalFooter className="gap-4">
          <button
            className="btn btn-secondary py-2 px-4"
            onClick={() => {
              setLocationModal(false);
            }}
          >
            {lang?.cancel}
          </button>
          <button
            className="btn-blue border-0 px-4 py-2"
            onClick={() => {
              setData({ ...data, lat_lng: markerPosition });
              setLocationModal(false);
              const lat = markerPosition.lat;
              const lng = markerPosition.lng;
              reverseGeocode(lat, lng);
            }}
          >
            {lang?.save}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default EditEvent;
