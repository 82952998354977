import { Col, Container, Modal, ModalHeader, Row } from "reactstrap";
import { Language } from "../../App";
import { createContext, useContext, useState } from "react";
import GroupAvatar from "../../assets/images/icon/group_avatar.png";
import { HiPlus } from "react-icons/hi";
import AddParticipantsModal from "./AddParticipantsModal";

export const CreateGroupModalContext = createContext();

export default function CreateGroupModal({ isOpen, toggle }) {
  const { lang } = useContext(Language);
  const [groupImage, setGroupImage] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [isAddParticipantsModalOpen, setIsAddParticipantsModalOpen] =
    useState(false);
  return (
    <CreateGroupModalContext.Provider value={{ groupImage, groupName }}>
      <AddParticipantsModal
        isOpen={isAddParticipantsModalOpen}
        toggle={() => setIsAddParticipantsModalOpen(false)}
      />
      <Modal centered zIndex={9} isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3 className="mb-0 roboto-bold fs-20 fs-md-16">{lang?.new_group}</h3>
        </ModalHeader>
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="edit-profile br-8 bg-white p-3">
                <div className="d-flex justify-content-center mt-4">
                  <div className="profile_img position-relative">
                    <img
                      src={
                        groupImage instanceof Blob
                          ? URL.createObjectURL(groupImage)
                          : GroupAvatar
                      }
                      alt="picture"
                      id="profileimage"
                    />
                    <input
                      type="file"
                      id="media"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => setGroupImage(e.target.files[0])}
                    />
                    <label className="plus manue-hover br-8" htmlFor="media">
                      <HiPlus color="black" size={25} />
                    </label>
                  </div>
                </div>

                <div className="d-flex flex-column gap-4 mt-4">
                  <div>
                    <h6 className="fs-16 fw-bold opacity-75">
                      {lang?.group_name}
                    </h6>
                    <input
                      type="text"
                      placeholder={lang?.type_group_name_here}
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column gap-4 mt-3">
                  <button
                    className="btn-blue border-0 px-4 py-2"
                    disabled={!groupName}
                    onClick={() => {
                      toggle();
                      setIsAddParticipantsModalOpen(true);
                    }}
                  >
                    {lang?.next}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
    </CreateGroupModalContext.Provider>
  );
}
